import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSocket from "../../../contexts/socket";
import getInstance from "../AxiosRequest";
import {
  NewNotificationLogo,
  NotificationLogo,
  DisabledNotificationLogo,
} from "../SVG/NotificationSvg";
import NotificationSound from "../../../audio/notification-sound.mp3";
import { FullWidthNotificationPopup } from "./NotificationPopupComponent";

export const ReturntoWorkspace = (id) => {
  const nav = useNavigate();

  nav("/Workspaces", {
    state: {
      workspace_id: id,
    },
  });
};
export const NotificationComponent = () => {
  /**
   * Message Object structure
   * 1.message
   * 2.datereceived
   * 3.isread/unread
   * 4.active
   */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = getInstance(localStorage.getItem("token"));
        await axiosInstance
          .get("/notification/" + localStorage.getItem("user_id"), {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 300000,
          })
          .then((response) => {
            console.log(response);
            if (response?.status === 200 && response?.data?.length > 0) {
              setMsgs(response.data[0]);
              if (
                response?.data?.length > 1 &&
                response?.data[1]?.user_id ===
                  parseInt(localStorage.getItem("user_id"))
              ) {
                setIsDisabledNotifications(
                  response?.data[1].is_disabled_notifications
                );
              }
            }
          })
          .catch((error) => {
            console.log("From API: ", error.response.status);
          });
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const [socket] = useState(useSocket());
  const [msgs, setMsgs] = useState([]);
  const [isDisabledNotifications, setIsDisabledNotifications] = useState(false);
  const [isUnseenNotifications, setUnseenNotifications] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(false);
  const togglepopup = useCallback(() => {
    setDisplayPopup(!displayPopup);
  }, [displayPopup]);
  const audioPlayer = useRef(null);
  /*const playAudio = () => {
        setTimeout(() => {
            console.log(audioPlayer?.current);
            audioPlayer?.current?.play();
        }, 3000);
    };*/

  useEffect(() => {
    let prevmsgs = [...msgs];

    socket.on("joinUserRoom", (data) => {
      console.log(`you've been successfully added to user waiting hall `, data);
    });
    socket.on("notification", (data) => {
      console.log(`a new notification received.`, data);
      let messages = [data, ...prevmsgs];

      //console.log("messages new notification", messages, data, prevmsgs);
      //playAudio();
      setMsgs(messages);
      setUnseenNotifications(true);
      // CustomToaster.Toast("info", parse(data?.msg));
    });

    //in_app_notification
    socket.on("in_app_notification", (data) => {
      console.log(`a new notification received.`, data);
      //playAudio();
      let messages = [data, ...prevmsgs];
      //console.log("messages new notification", messages, data, prevmsgs);
      setMsgs(messages);

      setUnseenNotifications(true);
      //CustomToaster.Toast("info", data?.msg);
    });
    return () =>
      socket.off("notification").off("in_app_notification").off("joinUserRoom");
  }, [socket, msgs, isDisabledNotifications]);
  return (
    <>
      <div className="custom_dropdown_anr">
        <button
          className="favorites_button notifications_button"
          onClick={() => {
            if (msgs.length > 0) {
              setUnseenNotifications(false);
              togglepopup();
            }
          }}
        >
          <audio ref={audioPlayer} src={NotificationSound} />
          {msgs.length === 0 && (
            <>
              <DisabledNotificationLogo />
            </>
          )}

          {msgs.length > 0 && !isUnseenNotifications && <NotificationLogo />}

          {msgs.length > 0 && isUnseenNotifications && <NewNotificationLogo />}
        </button>

        {displayPopup && (
          <NoticationCardComponent
            closepopup={togglepopup}
            msgs={msgs}
            isDisabledNotifications={isDisabledNotifications}
          />
        )}
      </div>
    </>
  );
};

export const NoticationCardComponent = ({
  msgs,
  closepopup,
  isDisabledNotifications,
}) => {
  return (
    <>
      <FullWidthNotificationPopup
        closepopup={closepopup}
        title={"Notifications"}
        msgs={msgs}
        isDisabledNotifications={isDisabledNotifications}
      />
    </>
  );
};
