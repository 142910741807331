import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import "./App.css";
import LeftPane from "./Components/MainComponents/LeftPane.jsx";
import Header from "./Components/MainComponents/Registration/UserHeader";
import UserNavigation from "./Components/MainComponents/Registration/UserNavigation";
import useToken from "./Components/MainComponents/Registration/useToken";
import RightPane from "./Components/MainComponents/RightPane.jsx";
import ErrorBoundary from "./Components/SupportiveComponents/CustomErrorBoundary.jsx";
import { generateUniqueID } from "./Components/SupportiveComponents/UniqueIDGenerator.jsx";
import { ThemeProvider } from "./contexts/theme.js";
import { EngineProvider } from "./contexts/EngineContext.js";


ReactGA.initialize(process.env.REACT_APP_GTID);

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const { token, removeToken, setToken } = useToken();
    const[startWalkthrough, setStartWalkthrough] = useState(false)
    const [tabID, setTabID] = useState("");

    useEffect(() => {
        // Check if tabID exists in sessionStorage
        let storedTabID = sessionStorage.getItem("tabID");

        if (!storedTabID) {
            // If not, generate a new ID and store it
            storedTabID = generateUniqueID();
            sessionStorage.setItem("tabID", storedTabID);
        }

        // Set the tabID in state
        setTabID(storedTabID);
    }, []);

    //const [hasError, setHasError] = useState(false);
    // const [value, setValue] = useState({});
    /**
     * Theme Modes
     */
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") === null
            ? window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "dark"
                : "light"
            : localStorage.getItem("theme")
    );
    const setDarkTheme = () => {
        setTheme("dark");
    };
    const setLightTheme = () => {
        setTheme("light");
    };

    useEffect(() => {
        document.querySelector("body").setAttribute("data-theme", theme);
        localStorage.setItem("theme", theme);
    }, [theme]);

    const handleNavigation = (isOpen) => {
        setIsOpen(isOpen);
    };

    useEffect(() => {
        const onStorageChange = (event) => {
            if (event.key === "logout") {
                // Handle logout event, e.g., redirect to login page
                window.location.href = "/Login"; // Redirect to login page
            }
        };

        window.addEventListener("storage", onStorageChange);

        return () => {
            window.removeEventListener("storage", onStorageChange);
        };
    }, []);

    const error = useSelector((state) => state.error?.errorState?.hasError);
    return (
        <ThemeProvider
            value={{
                themeMode: theme,
                darktheme: setDarkTheme,
                lighttheme: setLightTheme,
            }}
        >
            
                {console.log("token", token, tabID)}

                {error || (!token && token !== "" && token !== undefined) ? (
                    <ErrorBoundary>
                        <UserNavigation setToken={setToken} />
                    </ErrorBoundary>
                ) : (
                    <>
                        <EngineProvider>
                            <ErrorBoundary>
                                <Header
                                    removeToken={removeToken}
                                    token={token}
                                    isLeftPanelOpen={isOpen}
                                    setSidePaneStatus={setIsOpen}
                                    setStartWalkthrough={setStartWalkthrough}
                                />
                            </ErrorBoundary>
                            <div className="bodysec">
                                <ErrorBoundary>
                                    <LeftPane
                                        navigateTo={handleNavigation}
                                        token={token}
                                        isLeftPanelOpen={isOpen}
                                        setSidePaneStatus={setIsOpen}
                                        setToken={setToken}
                                        startWalkthrough={startWalkthrough}
                                        setStartWalkthrough={setStartWalkthrough}
                                    ></LeftPane>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <RightPane
                                        isLeftPanelOpen={isOpen}
                                        token={token}
                                        setToken={setToken}
                                        removeToken={removeToken}
                                    ></RightPane>
                                </ErrorBoundary>
                            </div>
                                {/* <script src="js/sidenav-push.js"></script> */}
                        </EngineProvider>
                    </>
                )}

        </ThemeProvider>
    );
}

export default App;
