import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { socket } from "../../../contexts/socket";
import useTheme from "../../../contexts/theme";
import "../../../css/CaseAnalysis.css";
import "../../../css/Login.css";
import { setTitle } from "../../../js/setTitle";
import { updateLogInState } from "../../../redux/actions";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest";
import { HidePassword, ShowPassword } from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart";

const ValidationSchemaCompanySignUp = Yup.object({
  username: Yup.string()
    .required("User name is required")
    .matches(/^\S*$/, "User name should not contain spaces"),
  password: Yup.string().required("Password is required"),
});

export default function LogIn(props) {
  const [userLoginState, setUserLoginState] = useState({
    userFullName: "",
    organization: "",
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const { themeMode } = useTheme();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const recaptchaRef = useRef(null);
  useEffect(() => {
    setTitle("Login");
  }, []);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: ValidationSchemaCompanySignUp,
    onSubmit: (values) => {
      handleLogIn();
    },
  });

  const handleLogIn = () => {
    try {
      setBtnLoading(true);
      const formData = new FormData();
      // const encryptPassword = this.encryptPassword();
      formData.append("username", formik.values.username);
      formData.append("password", formik.values.password);
      formData.append("token", recaptchaToken);
      const axiosInstance = getAxiosInstance(null, false, nav);

            axiosInstance
                .post("/login_user", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    timeout: 300000,
                })
                .then((response) => {
                    let userLoginState_temp = { ...userLoginState };
                    userLoginState_temp = {
                        userFullName: response?.data?.full_name,
                        organization: response?.data?.orgName,
                        userType: response?.data?.userType,
                        user_name: response?.data?.user_name,
                        user_id: response?.data?.user_id,
                        usertypeid: response?.data?.usertypeid,
                        org_id: response?.data?.org_id,
                        job_title: response?.data?.userDesignation,
                    };

          setUserLoginState(userLoginState_temp);
          dispatch(updateLogInState(userLoginState_temp));

                    let data = {
                        access_token: response.data.access_token,
                        refresh_token: response?.data?.refresh_token,
                        name: response.data.full_name,
                        orgName: response.data.orgName,
                        userType: response.data.userType,
                        user_name: response.data.user_name,
                        user_id: response.data.user_id,
                        usertypeid: response.data.usertypeid,
                        org_id: response?.data?.org_id,
                        job_title: response?.data?.userDesignation,
                    };

          props.setToken(data);
          setBtnLoading(false);
          socket.emit("auth", {
            test: "auth",
            token: response.data.access_token,
          });

          if (response.data !== undefined) {
            nav("/DashBoard");
          }
        })
        .catch((error) => {
          setBtnLoading(false);
          console.log("From API: ", error?.response);
          recaptchaRef?.current?.reset();
          let status = error?.response?.status;
          let error_text =
            "Error while Logging you in, please try after some time.";
          if (error?.response?.data?.error) {
            error_text = error?.response?.data?.msg;
          }
          if (status === 401 || status === 422)
            error_text = error?.response?.data?.msg;
          if (status === 415) {
            error_text = error?.response?.data?.msg;
          }
          Swal.fire({
            text: error_text,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } catch (error) {
      setBtnLoading(false);
      console.log("From Try Catch: ", error);
      Swal.fire({
        text: "Error while Logging you in, please try after some time.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const recaptchaVerify = (e) => {
    setRecaptchaToken(e);
    setIsCaptchaVerified(true);
    // handleRecaptchaToken(e, setIsCaptchaVerified);
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
      <div className="regBody">
        <div className="header">
          <h3>Login</h3>
          <a title="Trial Account Signup" href="/lawbotica-free-trial">
            <button className="continue logIn bg-dodger">
              Get a Trial account
            </button>
          </a>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="registrationsec login_form">
            <div className="logInfields">
              <label htmlFor="user_name">Username</label>
              <input
                type="text"
                id="user_name"
                name="username"
                placeholder="aUser"
                className="inputfield"
                autoComplete="off"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              <span className="validationError">
                {formik?.errors?.username &&
                (formik?.touched?.username || formik.isSubmitting)
                  ? formik.errors.username
                  : null}
              </span>
            </div>
            <div className="logInfields" >
              <label htmlFor="password-login">Password</label>
              <div style={{position:'relative',display:'flex',alignItems:'center'}}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password-login"
                placeholder="*******"
                className="inputfield"
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {showPassword ?<HidePassword  onClick={togglePasswordVisibility}/> : <ShowPassword onClick={togglePasswordVisibility}/>}
              </div>
             
              <span className="validationError">
                {formik?.errors?.password &&
                (formik?.touched?.password || formik.isSubmitting)
                  ? formik.errors.password
                  : null}
              </span>
            </div>

            <div className="captcha">
              {
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={recaptchaVerify}
                  theme={themeMode}
                  onExpired={() => setIsCaptchaVerified(false)}
                  size="normal"
                  ref={recaptchaRef}
                />
              }
            </div>
            <div className="captcha">
              <button
                className="continue"
                type="submit"
                disabled={!isCaptchaVerified || btnLoading}
              >
                Login
              </button>{" "}
            </div>
            <div className="signup">
              <NavLink to="/forgotpassword">Forgot your password?</NavLink>
              <br />
              <div>
                Not a member? &nbsp;
                <NavLink to="/SignUp">Signup</NavLink>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
