import { useEffect, useState, useRef } from "react";
import { GridExampleComponent } from "../Placeholders/PlaceholderGridComponent";
import { ActiveLogo, ArchivedLogo, DisplayLogo } from "../SVG/NotificationSvg";
import Switch from "react-switch";
import getInstance from "../AxiosRequest";
import { getUserDetails } from "../../MainComponents/Registration/useToken";

export const FullWidthNotificationPopup = ({
  closepopup,
  title,
  content,
  msgs,
  isDisabledNotifications,
}) => {
  const gridComponentref = useRef();
  const [Title, setTitle] = useState(title);

  const [messages, setMessages] = useState([]);
  const [isEnabledNotifications, setIsEnabledNotifications] = useState(
    !isDisabledNotifications
  );
  const [itemfiltervalue, setFilterValue] = useState("a");
  const { user_id } = getUserDetails();
  const [filtersread, setFiltersread] = useState("a");
  useEffect(() => {
    setTitle(title);
  }, [title]);
  useEffect(() => {
    setMessages(msgs);
  }, [msgs]);

  const handleDisableSwitchChange = async (isChecked) => {
    const axiosInstance = getInstance(localStorage.getItem("token"));
    let data = JSON.stringify({ user_id: user_id, is_disable: !isChecked });
    await axiosInstance
      .put("/disablenotifications", data, {
        headers: { "Content-Type": "application/json" },
        timeout: 300000,
      })
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setIsEnabledNotifications(isChecked);
        }
      })
      .catch((error) => {
        console.log("From API: ", error.response.status);
      });
  };

  return (
    <div
      id="popup-overlay"
      className={`custom-popup-overlay full-width notifications`}
      //   onClick={() => closepopup()}
    >
      <div
        className={`custom-popup-content `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="custom-popup-header">
          <span>
            <h2>{Title}</h2>
            {/**/}
          </span>
          <h2>
            <span
              className="close-btn"
              onClick={() => closepopup()}
              title="Close"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                alt="close"
              />
            </span>
          </h2>
        </div>
        <div className="custom-popup-body">
          <div className="popup_filter_area">
            <div className="popup_filter_item">
              {" "}
              <div className="popup_select_group">
                <ul>
                  <li>
                    <input
                      type="radio"
                      id="active_"
                      name="active_or_archive"
                      value={"a"}
                      className="custom_checkbox "
                      checked={itemfiltervalue === "a"}
                      onChange={(val) => {
                        console.log(val);
                        setFilterValue("a");
                      }}
                    />
                    <label
                      htmlFor="active_"
                      className="custom_checkbox cursor_pointer"
                      title="Active"
                    >
                      <ActiveLogo /> Active
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="archived_"
                      name="active_or_archive"
                      value={"h"}
                      className="custom_checkbox "
                      onChange={(val) => {
                        console.log(val);
                        setFilterValue("h");
                      }}
                      checked={itemfiltervalue === "h"}
                    />
                    <label
                      htmlFor="archived_"
                      className="custom_checkbox cursor_pointer"
                      title="Archived"
                    >
                      <ArchivedLogo /> Archived
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="popup_filter_item">
              {" "}
              <label className="custom_checkbox">
                <DisplayLogo /> Display
              </label>
              <div className="popup_filter_group">
                <ul>
                  <li>
                    <input
                      type="radio"
                      id="all_notifications"
                      name="display_filter"
                      value="a"
                      onChange={() => {
                        setFiltersread("a");
                      }}
                      checked={filtersread === "a"}
                    />
                    <label
                      htmlFor="all_notifications"
                      className="cursor_pointer"
                      title="All"
                    >
                      All
                    </label>
                  </li>

                  <li>
                    <input
                      type="radio"
                      id="unread_notifications"
                      name="display_filter"
                      value="u"
                      onChange={() => {
                        setFiltersread("u");
                      }}
                      checked={filtersread === "u"}
                    />
                    <label
                      htmlFor="unread_notifications"
                      className="cursor_pointer"
                      title="Unread"
                    >
                      Unread
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="read_notifications"
                      name="display_filter"
                      value="r"
                      onChange={() => {
                        setFiltersread("r");
                      }}
                      checked={filtersread === "r"}
                    />
                    <label
                      htmlFor="read_notifications"
                      className="cursor_pointer"
                      title="Read"
                    >
                      Read
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="popup_filter_item notification_padd">
              <label className="notification_label">Enable Notifications</label>
              <Switch
                className={isEnabledNotifications ? "switchYes" : "switch"}
                onChange={() =>
                  handleDisableSwitchChange(!isEnabledNotifications)
                }
                checked={isEnabledNotifications}
              />
            </div>
          </div>
          <div className="popup_content_area">
            {messages && messages.length > 0 ? (
              <GridExampleComponent
                ref={gridComponentref}
                msgs={msgs}
                itemfiltervalue={itemfiltervalue}
                filtersread={filtersread}
                itemfiltervalueCallback={setFilterValue}
              />
            ) : (
              <>No Notifications</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
