import React from "react";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
export const FormStatusMessage = (props) => {
    return (
        <div className="regBody">
            <div className="header">
                <h3>{props.heading}</h3>
            </div>
            <div className="registrationsec">
                <>
                    <div className="logInfields">
                        <label>{props.message}</label>
                    </div>
                    <div className="captcha">
                        <NavLink to="/LogIn">
                            <button className="continue logIn">Login</button>
                        </NavLink>
                    </div>
                </>
            </div>
        </div>
    );
};

export const LoggedInFormStatusMessage = (props) => {
    return (
        <div className="regBody">
            <div className="header">
                <h3>{props.heading}</h3>
            </div>
            <div className="registrationsec">
                <>
                    <div className="logInfields">
                        <label>{props.message}</label>
                    </div>
                </>
            </div>
        </div>
    );
};

export const TrialFormStatusMessage = ({ heading, message }) => {
    return (
        <div className="regBody">
            <div className="header">
                <h3>{heading}</h3>
            </div>
            <div className="registrationsec">
                <>
                    <div className="logInfields">
                        <p>{message}</p>

                        <a href="https://lawbotica.ai">Visit LawBotica.ai</a>
                    </div>
                </>
            </div>
        </div>
    );
};

export const TrialFormStatusMessageJSX = ({ heading, message }) => {
    return (
        <div className="regBody">
            <div className="header">
                <h3>{heading}</h3>
            </div>
            <div className="registrationsec">
                <>
                    <div className="logInfields">
                        {parse(message)}
                        <a href="https://lawbotica.ai">Visit LawBotica.ai</a>
                    </div>
                </>
            </div>
        </div>
    );
};