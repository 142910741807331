import { AgGridReact } from "ag-grid-react";
import {
    useCallback,
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { NewNotificationIndicationLogo } from "../SVG/NotificationSvg";
import getAxiosInstance from "../AxiosRequest";
import parse from "html-react-parser";
import { convertUtcToLocal } from "../CustomAlerts/ToastAlert";
import useTheme from "../../../contexts/theme";
//import useToken from "../../MainComponents/Registration/useToken";
export const GridExampleComponent = forwardRef(
    ({ msgs, itemfiltervalue, filtersread, itemfiltervalueCallback }, ref) => {
        const gridRef = useRef();
        const [messages, setMessages] = useState(msgs);
        const { themeMode } = useTheme();

        const [itemfiltervalue_, setItemFilterValue] =
            useState(itemfiltervalue);
        const [filtersread_, setFiltersread] = useState(filtersread);
        const [selectedData, setSelectedData] = useState([]);

        const onSelectionChanged = () => {
            const selectedData = gridRef?.current.api.getSelectedRows();
            console.log("Selection Changed", selectedData, filtersread_);
            setSelectedData(selectedData);
        };

        const deselectAll = () => {
            gridRef?.current?.api?.deselectAll();
        };
        const onGridReady = () => {
            const selectedData = gridRef?.current?.api?.getSelectedRows();
            console.log("Selection Changed", selectedData);
            setSelectedData(selectedData);
        };
        useEffect(() => {
            updateReadStatus();
        }, []);
        useEffect(() => {
            setMessages(msgs);
        }, [msgs]);
        useEffect(() => {
            setItemFilterValue(itemfiltervalue);
            deselectAll();
        }, [itemfiltervalue]);
        useEffect(() => {
            setFiltersread(filtersread);
        }, [filtersread]);
        const updateStatus = (fieldval) => {
            const token = localStorage.getItem("token");
            const axiosInstance = getAxiosInstance(token);
            const updatedselecteddata = selectedData.map((val) => {
                val.displaystatus = fieldval;
                return val;
            });
            axiosInstance
                .post("notifications/update", updatedselecteddata, {
                    headers: { "Content-Type": "application/json" },
                    timeout: 180000,
                })
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        deselectAll();
                        itemfiltervalueCallback(fieldval);
                    }
                });
        };

        const updateReadStatus = () => {
            const token = localStorage.getItem("token");
            const axiosInstance = getAxiosInstance(token);

            axiosInstance
                .post(
                    "notifications/readstatusupdate",
                    {},
                    {
                        headers: { "Content-Type": "application/json" },
                        timeout: 180000,
                    }
                )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                    }
                });
        };
        const externalFilterChanged = useCallback((newValue, readfilter) => {
            setItemFilterValue(newValue);
            setFiltersread(readfilter);
            gridRef.current?.api?.onFilterChanged();
        }, []);
        useEffect(() => {
            externalFilterChanged(itemfiltervalue_, filtersread_);
        }, [itemfiltervalue_, filtersread_, externalFilterChanged]);
        const isExternalFilterPresent = useCallback(() => {
            // if ageType is not everyone, then we are filtering
            console.log(filtersread_);
            return true;
        }, [filtersread_]);

        const doesExternalFilterPass = useCallback(
            (node) => {
                if (node.data) {
                    let boolval = false;
                    switch (itemfiltervalue_) {
                        case "a":
                            boolval = node.data.displaystatus === "a";
                            break;
                        case "h":
                            boolval = node.data.displaystatus === "h";
                            break;
                        default:
                            boolval = true;
                            break;
                    }
                    if (boolval) {
                        switch (filtersread_) {
                            case "r":
                                boolval = node.data.timeread !== null;
                                break;
                            case "u":
                                boolval = node.data.timeread === null;
                                break;
                            case "a":
                            default:
                                boolval = true;
                                break;
                        }
                    }

                    return boolval;
                }
                return true;
            },
            [itemfiltervalue_, filtersread_]
        );

        useImperativeHandle(ref, () => ({
            externalFilterChanged,
        }));

        // Column Definitions: Defines & controls grid columns.
        const [colDefs] = useState([
            {
                field: "created_at",
                headerName: "",
                cellRenderer: (params) => {
                    return (
                        <span
                            style={{
                                display: "inline-flex",
                                alignItems: "center",
                                verticalAlign: "middle",
                            }}
                        >
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {params?.data?.timeread === null && (
                                    <NewNotificationIndicationLogo />
                                )}
                            </span>
                        </span>
                    );
                },
                filter: true,
                resizable: true,
                sortable: true,
                width: 50,
                maxWidth: 75,
            },
            {
                field: "",
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                width: 50,
                maxWidth: 75,
            },

            {
                field: "msg",
                headerName: "Message",
                filter: true,
                resizable: true,
                tooltipValueGetter: (p) => {
                    const regex = /(<([^>]+)>)/gi;
                    const newString = p?.value.replace(regex, "");
                    return newString;
                },
                sortable: true,
                cellRenderer: (params) => {
                    return parse(params?.value);
                },
            },
            {
                field: "created_at",
                headerName: "Date Received",
                filter: true,
                tooltipValueGetter: (p) => {
                    return convertUtcToLocal(p?.value);
                },
                maxWidth: 200,
                resizable: true,
                sortable: true,
                sort: "desc",
                cellRenderer: (params) => {
                    return convertUtcToLocal(params?.value);
                },
            },
        ]);

        const [defaultcoldefs] = useState({
            flex: 1,
            resizable: true,
            editable: false,
            autoHeight: true,
        });
        // Container: Defines the grid's theme & dimensions.
        return (
            <div
                className={
                    themeMode === "light"
                        ? "ag-theme-quartz"
                        : "ag-theme-quartz-dark"
                }
                style={{ width: "100%", height: "100%", position: "relative" }}
            >
                {selectedData.length > 0 && (
                    <div className="notifications_selected_list">
                        <span
                            className="selected_items_bg"
                            onClick={() => {
                                deselectAll();
                            }}
                        >
                            <span className="selected_items">{" X"}</span>
                            {`${selectedData.length} selected`}
                        </span>

                        {itemfiltervalue_ === "h" && (
                            <button
                                className="btn"
                                onClick={() => {
                                    updateStatus("a");
                                }}
                            >
                                Move to Active Box
                            </button>
                        )}
                        {itemfiltervalue_ === "a" && (
                            <button
                                className="btn"
                                onClick={() => {
                                    updateStatus("h");
                                }}
                            >
                                Move to Archive Box
                            </button>
                        )}
                    </div>
                )}
                <AgGridReact
                    ref={gridRef}
                    rowData={messages}
                    defaultColDef={defaultcoldefs}
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection={"multiple"}
                    tooltipShowDelay={500}
                    pagination={true}
                    suppressMaxRenderedRowRestriction={true}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    suppressRowClickSelection={true}
                />
            </div>
        );
    }
);
