import { isEqual } from "lodash";
import React, { Component, Fragment, createRef } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { socket } from "../../contexts/socket.js";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import summarizeDocTypes from "../../dropDowns/summarizeDocTypes.json";
import { setTitle } from "../../js/setTitle.js";
import { updateDashBoardState, updateSummaryState } from "../../redux/actions";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx";
import ChatComponent from "../SupportiveComponents/ChatComponent.jsx";
import {
    ToastAlert,
    convertUtcToLocal,
} from "../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import {
    ContentReviewSection,
    ContentReviewSectionComponent,
    TitleOfComponent,
} from "../SupportiveComponents/CustomContentOperations/CustomContentViewer.jsx";
import ErrorBoundary from "../SupportiveComponents/CustomErrorBoundary.jsx";
import {
    CustomDocViewer,
    CustomExpandableDocComponent,
} from "../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import FavoritesComponent, {
    handleWarnRemoveFavorite,
} from "../SupportiveComponents/FavoriteComponent";
import CustomWindowPopupComponent from "../SupportiveComponents/FavouritesPopupContent.jsx";
import FileProcessing from "../SupportiveComponents/FileProcessing";
import { HistoryComponent } from "../SupportiveComponents/HistoryComponent";
import { CustomHistoryFavoriteLinks } from "../SupportiveComponents/HistoryandFavoritesLinks.jsx";
import { LoadingSpinnerSticky } from "../SupportiveComponents/LoadingSpinner";
import {
    HandleDocTypeChangeComponent,
    HandleEngineComponent,
    HandleFileNameComponent,
    HandleNewButtonComponent,
    HandleRefreshButtonComponent,
    HandwrittenComponent,
} from "../SupportiveComponents/ModuleHelpers.jsx";
import ShowAllWorkspacePopUp from "../SupportiveComponents/PopUps/ShowAllSelectedWorkspacePopUp.js";
import WorkSpaceListPopUp from "../SupportiveComponents/PopUps/WorkspaceListPopUp.jsx";
import { dataURLtoFile } from "../SupportiveComponents/PureHelpers.jsx";
import store from "../../redux/store.js";

class SummarizeComponent extends Component {
    module_id = docTypes_ddl.Modules["SummarizeDocType"].moduleId;
    module_name = docTypes_ddl.Modules["SummarizeDocType"].moduleName;
    HistoryComponentRef = React.createRef();
    FavoriteComponentRef = React.createRef();

    state = {
        isBottomPanelOpen: false, // For the Chat Component Box
        selectedDocType: "", // uploaded file Doc text Type
        selectedEngine: 1, // type of ai engine
        selectedFile: "", // selected File
        prevSelectedFile: "",
        summaryResult: "", // this property contains response returned from summarize
        isUploadFileOpen: true,
        selectedPrompt: {},
        activeTab: 0,
        favoriteFiles: [],
        isLoading: false,
        isSocketRequestProgress: false,
        loaderText: "",
        fileName: "",
        summaryTimer: "",
        isHandWritten: false,
        historyList: [],
        fromDashBoard: false,
        fromDashBoardLinkType: "",
        reqStartTime: new Date().getTime(),
        blobFilesList: [], // List used to open doc in the bottom bar
        docActiveTab: "", // state used for maintaining doc active tab
        isDocumentOpen: false, // state used to expand or collapse document tab
        favdisplayval: false,
        hisdisplayval: false,
        openCreateWorkSpacePopUp: false,
        baseContent: "",
        uploadFileId: undefined,
        workspace_id: 0,
        workspace_item_id: 0,
        module_id: 2,
        selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by: 0,
        selectedCanned_Ques: [],
        index_path: "",
        isIndexCreated: false,
        isContentExpanded: true,
        ws_shared_users: [],
        doc_help_questions: [],
        moveToWorkspace: false,
    };

    componentDidMount() {
        let dashBoardState = this.props.dashBoardState;
        if (dashBoardState && dashBoardState.source) {
            this.removeWorkspaceDetails();
            this.handleLinkFromDashBoard(dashBoardState);
        } else {
            this.setState({ fromDashBoard: false }, () => {
                this.props.updateSummaryState(this.state);
            });
            this.getMessages();
        }
        setTitle("Summarize");
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.summarizeState, this.props.summarizeState)) {
            // State has changed, trigger re-render

            this.setState({ ...this.props.summarizeState });
        }
    }
    resetState = (fireSwal, swalMsg, status = 200) => {
        this.setState(
            {
                summaryResult: "",
                isLoading: false,
                isUploadFileOpen: true,
                selectedFile: "",
                fileName: "",
                isHandWritten: false,
                selectedDocType: "",
                selectedEngine: 1,
                fromDashBoard: false,
                uploadFileId: undefined,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
        if (fireSwal) {
            if (status === 401 || status === 422) {
                this.props.removeToken();
            }
            Swal.fire({
                text: swalMsg,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    handleToggleDocumentViewer = (val) => {
        this.setState({ isDocumentOpen: val }, () => {
            this.props.updateSummaryState(this.state);
        });
    };
    handleBottomPanel = (isOpen) => {
        this.setState(
            {
                isBottomPanelOpen: isOpen,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
    };
    handleToggleHistory = () => {
        this.setState(
            {
                hisdisplayval: !this.state.hisdisplayval,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateSummaryState(this.state);
            }
        );
    };
    updateHistoryFilesState = (historyFiles) => {
        this.setState(
            {
                historyFiles: historyFiles,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
    };
    setReqStartTime = (time) => {
        try {
            this.setState({ reqStartTime: time }, () => {
                this.props.updateSummaryState(this.state);
            });
        } catch (error) {
            console.log(error);
        }
    };

    setLoaderStatus = (flag, fireSwal) => {
        try {
            this.setState({ isLoading: flag }, () => {
                this.props.updateSummaryState(this.state);
            });

            if (fireSwal) {
                Swal.fire({
                    text: "We are unable to process this file at this time. May be try another file",

                    icon: "error",

                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.log("ErrorTriggerMsgSet:", error);
        }
    };

    constructor(props) {
        super(props);
        this.secondDivRef = createRef();
        this.chatComponentRef = createRef();
        this.state = this.props.summarizeState;
    }

    handleSummaryFile = (fileDetail) => {
        try {
            //  let startTime = new Date().getTime();
            if (
                this.state.selectedFile !== "" ||
                (fileDetail.file && fileDetail.error === null) ||
                (this.state.workspace_id === 0 &&
                    this.state.workspace_item_id === 0)
            ) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({ baseContent: reader.result }, () => {
                        this.props.updateSummaryState(this.state);
                    });
                };
                reader.readAsDataURL(fileDetail.file);

                this.setState(
                    {
                        selectedFile: fileDetail,
                        CannedQuestionsList: [],
                        summaryResult: "",
                        fileName: fileDetail.file.name,
                        reqStartTime: new Date().getTime(),
                    },
                    () => {
                        this.props.updateSummaryState(this.state);
                    }
                );
                if (this.state.selectedDocType !== "") {
                    this.setState(
                        {
                            isLoading: true,
                            loaderText: "Analysis in progress...",
                        },
                        () => {
                            this.props.updateSummaryState(this.state);
                        }
                    );
                    {
                        const formData = new FormData();
                        formData.append("file0", fileDetail.file);
                        formData.append("module", this.module_name);
                        const axiosInstance = getAxiosInstance(
                            this.props.token,
                            false,
                            this.props?.history
                        );

                        axiosInstance
                            .post("/upload", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                                timeout: 300000,
                            })
                            .then((response) => {
                                if (response.data.fid === undefined) {
                                    this.setLoaderStatus(false, true);
                                } else {
                                    console.log(response.data.fid, "fid");
                                    this.setState(
                                        {
                                            uploadFileId: response.data.fid,
                                        },
                                        () => {
                                            this.props.updateSummaryState(
                                                this.state
                                            );
                                        }
                                    );

                                    this.setReqStartTime(new Date().getTime());
                                    let data = {
                                        filepath: response?.data?.fid,
                                        fileName: fileDetail.file.name,
                                        documentType:
                                            this.state.selectedDocType,
                                        engineType: this.state.selectedEngine,
                                        hwFlag: this.state.isHandWritten
                                            ? 1
                                            : 0,
                                        uploadFileId: response?.data?.fid,
                                        reqStartTime: new Date().getTime(),
                                        index_path: this.state.index_path,
                                    };

                                    socket.emit("summarize_chat", data);
                                    this.HistoryComponentRef?.current?.handleSaveFileToHistory(
                                        {
                                            module_id: this.module_id,
                                            selectedFile:
                                                this.state.selectedFile,
                                            selectedDocType:
                                                this.state.selectedDocType,
                                            isHandWritten:
                                                this.state.isHandWritten,
                                            selected_engine_id:
                                                this.state.selectedEngine,
                                            isBatchFile: false, // false as there are no multi doc updloads in this
                                        },
                                        this.props.token
                                    );
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                let status = error.response?.status;
                                if (status === 401 || status === 422)
                                    this.resetState(
                                        true,
                                        "Session timedout",
                                        401
                                    );
                                else
                                    this.resetState(
                                        true,
                                        "We are unable to process this file at this time. May be try another file"
                                    );
                                console.log("Error=>", error);
                            });
                    }
                    const formData = new FormData();
                    formData.append("file0", fileDetail.file);
                    formData.append("documentType", this.state.selectedDocType);
                    formData.append("engineType", this.state.selectedEngine);
                    formData.append(
                        "isHandWritten",
                        this.state.isHandWritten ? 1 : 0
                    );
                } else {
                    Swal.fire({
                        text: "Please select a document type",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                    this.setState(
                        {
                            isUploadFileOpen: true,
                            isLoading: false,
                            selectedFile: "",
                            fileName: "",
                            isHandWritten: false,
                            summaryResult: "",
                        },
                        () => {
                            this.props.updateSummaryState(this.state);
                        }
                    );
                }
            } else {
                // toast.error(fileDetail.error);
                Swal.fire({
                    text: fileDetail.error,
                    icon: "error",
                    confirmButtonText: "OK",
                });
                this.setState(
                    {
                        isUploadFileOpen: true,
                        isLoading: false,
                        selectedFile: "",
                        fileName: "",
                        isHandWritten: false,
                        summaryResult: "",
                    },
                    () => {
                        this.props.updateSummaryState(this.state);
                    }
                );
            }
        } catch (error) {
            console.log("Error in handleSummarize", error);
            this.setState(
                {
                    isUploadFileOpen: true,
                    isLoading: false,
                    selectedFile: "",
                    fileName: "",
                    isHandWritten: false,
                    summaryResult: "",
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
        }
    };

    handleDocTypeChange = (event, docTypes) => {
        const { value } = event.target;

        if (docTypes && docTypes?.length > 0) {
            docTypes_ddl.SummarizeDocType = docTypes;
        }

        this.setState(
            {
                selectedDocType: value,
                selectedCanned_Ques: summarizeDocTypes[value + "_Canned_Ques"],
                summaryResult: "",
                isUploadFileOpen: true,
                fileName: "",
                isHandWritten: false,
                selectedFile: "",
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
        console.log(value);
    };

    handleEngineChange = (event) => {
        const { value } = event.target;
        console.log("Engine selected: ", value);
        this.setState(
            {
                selectedEngine: parseInt(value),
            },
            () => this.props.updateSummaryState(this.state)
        );
    };

    handleLinkFromDashBoard(dashBoardState) {
        let stateObj = {};

        if (dashBoardState.linkType.length > 0) {
            stateObj = {
                fromDashBoardLinkType: dashBoardState.linkType,
                workspace_id: 0,
                workspace_item_id: 0,
            };
        } else {
            stateObj = {
                fileName: dashBoardState.fileName,
                selectedDocType: dashBoardState.selectedDocType,
                workspace_id: 0,
                workspace_item_id: 0,
                selectedEngine: dashBoardState.selectedEngine
                    ? dashBoardState.selectedEngine
                    : 1,
            };
        }
        switch (dashBoardState.source) {
            case "favorites":
                this.setState(
                    {
                        ...stateObj,
                        favdisplayval:
                            Object.prototype.hasOwnProperty.call(
                                stateObj,
                                "fromDashBoardLinkType"
                            ) > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);

                        if (this.state.fileName) {
                            //call retrieve function from favoriteComponent
                            console.log(
                                this.state.fileName,
                                "favorites test test retreive inside"
                            );
                            this.FavoriteComponentRef?.current?.RetrieveFileFromFavorites(
                                this.state.fileName,
                                this.module_id,
                                null, //batchid assuming it as null as there are multi files upload here
                                0, // isBatch is 0
                                this.handleRetrieveFileState
                            );
                        }
                        this.props.updateSummaryState(this.state);
                    }
                );
                break;
            case "history":
                console.log("history");
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: true,
                        hisdisplayval:
                            stateObj.hasOwnProperty("fromDashBoardLinkType") > 0
                                ? stateObj.fromDashBoardLinkType.length > 0
                                    ? true
                                    : false
                                : false,
                        summaryResult: "",
                    },
                    () => {
                        console.log("fromDashBoard", this.state.fromDashBoard);
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateSummaryState(this.state);
                        if (this.state?.fileName) {
                            this.HistoryComponentRef?.current?.handleRetrieveHistoryFile(
                                this.state.fileName,
                                null, //batchid assuming it as null as there are multi files upload here
                                0,
                                this.handleFavoriteFileState
                            );
                        }
                    }
                );
                break;
            default:
                this.setState(
                    {
                        ...stateObj,
                        fromDashBoard: false,
                    },
                    () => {
                        let dashBoardState = {
                            source: "",
                            moduleId: "",
                            fileName: "",
                            activeTab: 0,
                            selectedDocType: "",
                            selectedEngine: 1,
                        };
                        this.props.updateDashBoardState(dashBoardState);
                        this.props.updateSummaryState(this.state);
                    }
                );
                break;
        }
    }
    handleUpdateHistoryList = (hlist) => {
        this.setState({ historyList: hlist }, () => {
            this.props.updateSummaryState(this.state);
        });
    };
    handleUploadBar = (value) => {
        this.removeWorkspaceDetails();
        this.setState(
            {
                isUploadFileOpen: value,
                fileName: "",
                selectedDocType: "",
                summaryResult: "",
                selectedFile: "",
                isHandWritten: false,
                hisdisplayval: false,
                isLoading: false,
                workspace_created_by: "",
                workspace_id: 0,
                workspace_item_id: 0,
                workspace_name: "",
                disableAddToWorkspace: false,
                uploadFileId: undefined,
                index_path: "",
                isIndexCreated: false,
                isSharedWorkspace: false,
                inputValue: "",
                doc_help_questions: [],
                isSocketRequestProgress: false,
            },
            () => {
                this.removeWorkspaceDetails();
                this.props.updateSummaryState(this.state);
            }
        );
    };

    toggleTab = (tabId) => {
        this.setState({ activeTab: tabId }, () => {
            this.props.updateSummaryState(this.state);
        });
        this.handleBottomPanel(true);
    };

    handleActiveRightLink = (value) => {
        this.setState({ activeTab: value }, () => {
            this.props.updateSummaryState(this.state);
        });
    };

    handleHistoryFile = (file, docType, is_hand_written, engine_id) => {
        try {
            this.setState(
                {
                    isUploadFileOpen: false,
                    isLoading: false,
                    selectedFile: file,
                    selectedEngine: engine_id,
                    selectedDocType: docType,
                    isHandWritten: is_hand_written,
                    fromDashBoard: false,
                    selectedCanned_Ques:
                        summarizeDocTypes[docType + "_Canned_Ques"],
                },
                () => {
                    this.props.updateSummaryState(this.state);
                    let fileDetail = {};
                    fileDetail.file = file;
                    fileDetail.error = null;
                    console.log(fileDetail);
                    this.handleSummaryFile(fileDetail);
                }
            );

            // toast.success("File Retrieved from favorites");
        } catch (e) {
            //empty
        }
    };

    handleLoadingFromHistory = (value, text, error) => {
        console.log(`From History to Summary ${value} and ${text}`);
        if (error) {
            this.setState(
                {
                    isLoading: false,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
            Swal.fire({
                text: "Not able to fetch file from history, please try after some time",
                icon: "error",
                confirmButtonText: "OK",
            });
        } else
            this.setState(
                {
                    isLoading: value,
                    loaderText: text,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
    };

    handleHWSwitchChange = (isHandWritten) => {
        this.setState(
            {
                isHandWritten,
            },
            () => {
                this.props.updateSummaryState(this.state);
                if (this.state.selectedFile !== "") {
                    this.handleSummaryFile(this.state.selectedFile);
                }
            }
        );
    };

    /*Favorites Component State Update things Done Done Done*/
    handleFetchFavoriteFilesState = (stateobj, error) => {
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateSummaryState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateSummaryState(this.state);
            });
        }
    };

    handleFavoriteFileState = (stateobj, error) => {
        if (error) {
            this.setState(stateobj, () => {
                this.props.updateSummaryState(this.state);
            });
        } else {
            this.setState(
                {
                    ...stateobj,
                    selectedCanned_Ques:
                        summarizeDocTypes[
                            this.state.selectedDocType + "_Canned_Ques"
                        ],
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
        }
    };

    // move to favorite supportive component
    handleRetrieveFileState = (stateobj, error) => {
        try {
            let docType = stateobj?.selectedDocType
                ? stateobj?.selectedDocType
                : null;
            let selectedCanned_Ques = [];
            if (docType) {
                selectedCanned_Ques =
                    summarizeDocTypes[docType + "_Canned_Ques"];
            }
            if (error) {
                this.setState({ ...stateobj }, () => {
                    this.props.updateSummaryState(this.state);
                });
            } else {
                this.setState(
                    { ...stateobj, selectedCanned_Ques: selectedCanned_Ques },
                    () => {
                        this.removeWorkspaceDetails();
                        this.props.updateSummaryState(this.state);
                        if (this.state.selectedFile !== "") {
                            this.handleSummaryFile(this.state.selectedFile);
                        }
                    }
                );
            }
        } catch (error) {
            //empty
        }
    };

    // move to favoritesupportive component
    handleRemoveFavoriteState = (stateobj, error) => {
        console.log("Review Remove Favourites", stateobj);
        if (error) {
            this.setState({ ...stateobj }, () => {
                this.props.updateSummaryState(this.state);
            });
        } else {
            this.setState({ ...stateobj }, () => {
                this.props.updateSummaryState(this.state);
            });
        }
    };
    /*Favorites Component State Update things*/

    handleUpdateBlobFilesState = (blobFilesList, activeTab) => {
        this.setState(
            {
                blobFilesList: blobFilesList,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateSummaryState(this.state);
            }
        );
    };

    handleToggleFavourites = () => {
        this.setState(
            {
                favdisplayval: !this.state.favdisplayval,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateSummaryState(this.state);
            }
        );
    };

    removeWorkspaceDetails = () => {
        this.setState(
            {
                ...this.state,
                workspace_id: 0,
                workspace_item_id: 0,
                module_id: 2,
                // isAddDoc: false,
                workspace_name: "",
                disableAddToWorkspace: false,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
    };
    getMessages = () => {
        try {
            if (
                this.state?.module_id === 2 &&
                this.state?.workspace_id > 0 &&
                this.state?.workspace_item_id > 0
            ) {
                // reviewReducer(this.state, { type: 'RESET_REVIEW_STATE' })
                this.setState(
                    {
                        ...this.state,
                        isLoading: true,
                        disableAddToWorkspace: true,
                        workspace_id: this.state.workspace_id,
                        workspace_item_id: this.state.workspace_item_id,
                        isUploadFileOpen: false,
                        summaryResult: {},
                        fileName: "",
                        uploadFileId: "",
                        selectedFile: {},
                        summaryTimer: 0 + "s",
                        openCreateWorkSpacePopUp: false,
                        // CannedQuestionsList: [],
                        selectedDocType: "",
                    },
                    () => {
                        this.props.updateSummaryState(this.state);
                    }
                );
                const axiosInstance = getAxiosInstance(
                    this.props.token,
                    false,
                    this.props?.history
                );
                axiosInstance
                    .get(
                        `/workspace/item?ws_id=${this.state.workspace_id}&ws_item_id=${this.state.workspace_item_id}`,
                        { timeout: 180000 }
                    )
                    .then((res) => {
                        if (res?.data) {
                            const base64String =
                                res.data.documents[0]?.docresponse;
                            const filename =
                                res.data?.documents?.length > 0 &&
                                res.data?.documents[0]?.name; // Specify desired file name
                            let sortedData = this.sortByIdAsc(
                                res.data?.messages
                            );
                            let queryContent = [];
                            sortedData.forEach((element, index) => {
                                if (index > 0) {
                                    queryContent.push({
                                        question: element?.query,
                                        response: element?.messages,
                                        time: convertUtcToLocal(
                                            element?.created_at
                                        ),
                                        timetaken: element?.timeTaken + "s",
                                        review_by_name:
                                            res.data?.shared_users?.length > 1
                                                ? res.data?.shared_users?.find(
                                                      (x) =>
                                                          x.user_id ===
                                                          element.created_by
                                                  )?.full_name
                                                : "",
                                    });
                                }
                            });

                            const file = dataURLtoFile(base64String, filename);

                            let msgs =
                                sortedData?.length > 0
                                    ? sortedData[0]?.messages.split(
                                          "Intermediate_Summary:"
                                      )
                                    : []; //Intermediate_Summary

                            let data = {
                                ...this.state,
                                isUploadFileOpen: false,
                                summaryResult:
                                    sortedData?.length > 0
                                        ? {
                                              Concise_Summary: msgs[0],
                                              Intermediate_Summary: msgs[1],
                                          }
                                        : {},
                                summaryTime:
                                    sortedData?.length > 0
                                        ? convertUtcToLocal(
                                              sortedData[0]?.created_at
                                          )
                                        : "",
                                created_by_name:
                                    sortedData?.length > 0
                                        ? res.data?.shared_users?.find(
                                              (x) =>
                                                  x.user_id ===
                                                  sortedData[0]?.created_by
                                          )?.full_name
                                        : "",
                                fileName:
                                    res.data?.documents?.length > 0 &&
                                    res.data?.documents[0]?.name,
                                selectedFile: { file: file, error: "" },
                                ws_shared_users: res.data?.shared_users,
                                isSharedWorkspace:
                                    res.data?.shared_users?.length > 1
                                        ? true
                                        : false,
                                isLoading: false,
                                summaryTimer: sortedData[0]?.timeTaken + "s",
                                oldMessages: queryContent,
                                CannedQuestionsList: queryContent,
                                selectedDocType:
                                    res.data?.documents?.length > 0 &&
                                    res.data.documents[0].documentType,
                                workspace_created_by:
                                    res.data?.shared_users?.find(
                                        (x) => x.permissions === "owner"
                                    )?.user_id,
                                selectedEngine: res.data?.engine_type,
                                isIndexCreated: true,
                                index_path:
                                    res.data?.documents?.length > 0 &&
                                    res.data?.documents[0]?.index_path,
                                selectedCanned_Ques:
                                    summarizeDocTypes[
                                        (res.data?.documents?.length > 0 &&
                                            res.data.documents[0]
                                                ?.documentType) + "_Canned_Ques"
                                    ],
                                baseContent: base64String,
                            };

                            this.setState({ ...data }, () => {
                                this.props.updateSummaryState(this.state);

                                let ob = {
                                    msg: {
                                        map: this.state.inputValue,
                                        reduce: this.state.inputValue,
                                    },
                                    doctype: this.state.selectedDocType,
                                    engineType: this.state.selectedEngine,
                                    srcname: undefined,
                                    topk: undefined,
                                    token: this.props.token,
                                    uploadFileId: undefined,
                                    filepath: undefined,
                                    hwFlag: this.state.isHandWritten
                                        ? "1"
                                        : "0",
                                    fileName: this.state.fileName,
                                    isBatchFile: false,
                                    workspace_item_id:
                                        this.state.workspace_item_id,
                                    workspace_id: this.state.workspace_id,
                                    index_path: this.state.index_path,
                                };

                                socket.emit("summarize_chat", ob);
                                // this.handleRetrieveFileState(this.state, null);
                            });
                        }
                    });
            } else {
                this.setState(
                    {
                        ...this.state,
                        workspace_id: 0,
                        workspace_item_id: 0,
                    },
                    () => {
                        this.props.updateSummaryState(this.state);
                    }
                );
            }
        } catch (exception) {
            this.setState(
                {
                    ...this.state,
                    isLoading: false,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
        }
    };

    handleAddWorkSpace = (res, reviewTimer) => {
        const axiosInstance = getAxiosInstance(
            this.props.token,
            false,
            this.props?.history
        );
        var msgs = [];
        msgs.push({
            response:
                res.data.Concise_Summary +
                "Intermediate_Summary: " +
                res.data.Intermediate_Summary,
            query: "",
            response_build_time: reviewTimer,
        });
        var ws_item = {
            module_id: 2,
            messages: msgs,
            documents: [
                {
                    name: this.state.fileName,
                    content: this.state.baseContent,
                    uri: "",
                    handwritten_text_flag: this.state.isHandWritten,
                    index_path: this.state.uploadFileId,
                    mime_type: this.state.selectedFile.file.type,
                },
            ],
            document_type: this.state.selectedDocType,
        };
        var obj = {
            workspace_id: this.state.workspace_id,
            ws_item: ws_item,
            id: 1,
        };

        axiosInstance
            .post("/workspace/item", obj, {
                headers: { "Content-Type": "application/json" },
                timeout: 180000,
            })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({ ...this.state, isAddDoc: false }, () => {
                        this.props.updateSummaryState(this.state);
                    });
                    ToastAlert("added successfully");
                }
            });
    };

    closeShowAllWorkspacesPopup = (data) => {
        if (data?.length > 0) {
            let ob = {
                rid: this.state.uploadFileId,
                workspace_item_id: data[0].workspace_item_id,
                workspace_id: data[0].id,
            };
            socket.emit("set_summarize_chat_session", ob);

            this.setState(
                {
                    ...this.state,
                    showAllWorkspaces: false,
                    workspace_name: data[0].name,
                    workspace_id: data[0].id,
                    workspace_item_id: data[0].workspace_item_id,
                    openCreateWorkSpacePopUp: false,
                    disableAddToWorkspace: true,
                    workspace_created_by: parseInt(
                        store?.getState()?.login?.logInState?.user_id
                    ),
                    isSharedWorkspace:
                        parseInt(data[0]?.shared_users) > 1 ? true : false,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
        }
    };

    closeAllWorkspacesPopup = () => {
        this.setState(
            {
                ...this.state,
                showAllWorkspaces: false,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
    };

    CloseWorkspaceListPopUp = (data, res) => {
        if (this.state.moveToWorkspace) {
            this.setState(
                {
                    moveToWorkspace: false,
                    openCreateWorkSpacePopUp: false,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                }
            );
        } else {
            this.setState(
                {
                    openCreateWorkSpacePopUp:
                        !this.state.openCreateWorkSpacePopUp,
                    disableAddToWorkspace: false,
                    selectedWorkSpaces: data,
                    showAllWorkspaces: true,
                },
                () => {
                    this.props.updateSummaryState(this.state);
                    if (data?.length === 1 && res?.added?.length === 1) {
                        this.closeShowAllWorkspacesPopup(data);
                    }
                }
            );
        }
    };

    sortByIdAsc = (items) => {
        const sortedItems = [...items].sort((a, b) => {
            return a.id - b.id;
        });
        return sortedItems;
    };

    handleToggleExpndableBox = () => {
        this.setState(
            {
                isContentExpanded: !this.state.isContentExpanded,
            },
            () => {
                //activeTab: activeTab,
                this.props.updateSummaryState(this.state);
            }
        );
    };

    RedirectToWorkspaceItems = () => {
        const { history } = this.props;
        let state = {
            workspace_id: this.state.workspace_id,
            workspace_name: this.state.workspace_name,
            data: {
                created_by: this.state.workspace_created_by,
            },
        };
        history("/workspaces", { state: state });
    };

    getPath = (path) => {};
    addToAnotherWorkspace = () => {
        this.setState(
            {
                openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
                moveToWorkspace: true,
            },
            () => {
                this.props.updateSummaryState(this.state);
            }
        );
    };
    render() {
        let summaryResult = this.state.summaryResult;
        return (
            <Fragment>
                {this.state.showAllWorkspaces && (
                    <ShowAllWorkspacePopUp
                        selectedWorkspaces={this.state.selectedWorkSpaces}
                        closeShowAllWorkspacesPopup={
                            this.closeShowAllWorkspacesPopup
                        }
                        closepopup={this.closeAllWorkspacesPopup}
                        document_name={this.state.selectedFile?.file?.name}
                    />
                )}
                {this.state.openCreateWorkSpacePopUp && (
                    <WorkSpaceListPopUp
                        title={"Create new WorkSpace"}
                        workSpace={""}
                        buttonName={"Add"}
                        workSpaceDisabled={false}
                        state={this.state}
                        file={this.state.selectedFile.file}
                        content={
                            this.state.summaryResult?.Concise_Summary +
                            "Intermediate_Summary: " +
                            this.state.summaryResult?.Intermediate_Summary
                        }
                        baseContent={this.state.baseContent}
                        docsContent={[
                            {
                                content: this.state.baseContent,
                                fileName: this.state.selectedFile.file.name,
                                fileType: this.state.selectedFile.file.type,
                                index_path: this.state.index_path,
                            },
                        ]}
                        document_type={this.state.selectedDocType}
                        document_name={this.state.selectedFile.file?.name}
                        handwritten_text_flag={this.state.isHandWritten}
                        timeTaken={this.state.summaryTimer?.replace("s", "")}
                        CannedQuestionsList={this.state?.CannedQuestionsList}
                        index_path={this.state.index_path}
                        closepopup={(data, res) =>
                            this.CloseWorkspaceListPopUp(data, res)
                        }
                        closeWsListpopup={() => {
                            this.setState({
                                ...this.state,
                                openCreateWorkSpacePopUp:
                                    !this.state.openCreateWorkSpacePopUp,
                                disableAddToWorkspace: this.state
                                    .moveToWorkspace
                                    ? this.state.disableAddToWorkspace
                                    : false,
                            });
                        }}
                        module_id={2}
                        engine_type={this.state.selectedEngine}
                        workspace_id={this.state.workspace_id}
                    />
                )}
                <CustomWindowPopupComponent
                    window_name={"Favorites"}
                    module_name={this.module_name}
                    togglepopup={this.handleToggleFavourites}
                    favdisplayval={this.state.favdisplayval}
                    content={
                        <FavoritesComponent
                            ref={this.FavoriteComponentRef}
                            module={this.module_id}
                            aciveTab={this.state.activeTab}
                            favdisplayval={this.state.favdisplayval}
                            module_name="SummarizeDocType"
                            handleToggleFavoritesCallback={
                                this.handleToggleFavourites
                            }
                            handleRemoveFavoriteState={
                                this.handleRemoveFavoriteState
                            }
                            handleFavoriteFile={this.handleFavoriteFileState}
                            handleFetchFavoriteFilesState={
                                this.handleFetchFavoriteFilesState
                            }
                            handleRetrieveFileState={
                                this.handleRetrieveFileState
                            }
                            favoriteFiles={this.state.favoriteFiles}
                            token={this.props.token}
                            removetoken={this.props.removetoken}
                            selectedEngine={this.state.selectedEngine}
                            isBatchFile={this.state.isBatchFile}
                            blobFilesList={this.state.blobFilesList} //passing blob files list to push data on select
                            handleUpdateBlobFilesState={
                                this.state.handleUpdateBlobFilesState
                            }
                        />
                    }
                ></CustomWindowPopupComponent>
                <CustomWindowPopupComponent
                    module_name={this.module_name}
                    window_name={"History"}
                    togglepopup={this.handleToggleHistory}
                    favdisplayval={this.state.hisdisplayval}
                    content={
                        <>
                            {" "}
                            {/**History Component */}
                            <HistoryComponent
                                module={this.module_id}
                                module_name={"SummarizeDocType"}
                                hisdisplayval={this.state.hisdisplayval}
                                isProcessed={
                                    summaryResult !== "" ? true : false
                                }
                                ref={this.HistoryComponentRef}
                                fileName={this.state.fileName}
                                fileDetails={this.state.selectedFile}
                                docType={this.state.selectedDocType}
                                isHandWritten={this.state.isHandWritten}
                                historyList={this.state.historyList}
                                isLoading={this.handleLoadingFromHistory}
                                handleHistoryFile={this.handleHistoryFile}
                                handleFileResetCallback={this.handleUploadBar}
                                fromDashBoard={this.state.fromDashBoard}
                                token={this.props.token}
                                removeToken={this.props.removeToken}
                                selectedEngine={this.state.selectedEngine}
                                updateStateCallback={
                                    this.handleUpdateHistoryList
                                }
                                handleRetrieveFileStateCallback={
                                    this.handleRetrieveFileState
                                }
                                handleFavoriteFileStateCallback={
                                    this.handleFavoriteFileState
                                }
                                toggleFavorites={this.handleToggleFavourites}
                            />
                        </>
                    }
                ></CustomWindowPopupComponent>
                <div className="rytpaneltoppart  flex-flow-wrap">
                    <div className="reviewfirstdiv">
                        <h2>Summarize document</h2>
                        {this.state.fileName !== "" && (
                            <HandleNewButtonComponent
                                title="Summarize a new document"
                                selectedDocType={this.state.selectedDocType}
                                handleUploadBarCallback={this.handleUploadBar}
                            />
                        )}
                    </div>
                    <div className="reviewdescriptionsec">
                        <div className="reviewdescription">
                            <HandleDocTypeChangeComponent
                                selectedDocType={this.state.selectedDocType}
                                handleDocTypeChangeCallback={
                                    this.handleDocTypeChange
                                }
                                documentTypes={docTypes_ddl.SummarizeDocType}
                                fileName={this.state.fileName}
                            />
                            {/**
                                HandleEngine Component is the dropdown for Engine Selection
                             */}
                            <HandleEngineComponent
                                selectedEngine={this.state.selectedEngine}
                                handleEngineChangeCallback={
                                    this.handleEngineChange
                                }
                                fileName={this.state.fileName}
                            />

                            {this.state.selectedDocType !== "" && (
                                <HandwrittenComponent
                                    isHandWritten={this.state.isHandWritten}
                                    handleHWSwitchChangeCallback={
                                        this.handleHWSwitchChange
                                    }
                                />
                            )}

                            {this.state.fileName !== "" && (
                                <>
                                    <HandleFileNameComponent
                                        fileName={this.state.fileName}
                                        selectedFile={this.state.selectedFile}
                                        maxLengthOfFileName={15}
                                        blobFilesList={this.state.blobFilesList}
                                        favoriteFiles={this.state.favoriteFiles}
                                        module_id={this.module_id}
                                        handleUpdateBlobFilesStateCallback={
                                            this.handleUpdateBlobFilesState
                                        }
                                        handleWarnRemoveFavoriteCallback={
                                            handleWarnRemoveFavorite
                                        }
                                        handleRemoveFavoriteStateCallback={
                                            this.handleRemoveFavoriteState
                                        }
                                        selectedDocType={
                                            this.state.selectedDocType
                                        }
                                        isHandWritten={this.state.isHandWritten}
                                        selectedEngine={
                                            this.state.selectedEngine
                                        }
                                        RemoveFavouriteCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.RemoveFavorite
                                        }
                                        handleFavoriteFileCallback={
                                            this.FavoriteComponentRef?.current
                                                ?.handleFavoriteFile
                                        }
                                        handleFavoriteFileStateCallback={
                                            this.handleFavoriteFileState
                                        }
                                    />
                                </>
                            )}
                            {this.state?.fileName?.length > 0 && (
                                <>
                                    <HandleRefreshButtonComponent
                                        handleRefreshFunc={() => {
                                            this.setState(
                                                {
                                                    summaryResult: "",
                                                    uploadFileId: undefined,
                                                    index_path: "",
                                                },
                                                () => {
                                                    this.props.updateSummaryState(
                                                        this.state
                                                    );
                                                }
                                            );
                                            this.getMessages();
                                            this.handleSummaryFile(
                                                this.state.selectedFile
                                            );
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <CustomHistoryFavoriteLinks
                        toggleFavoritescallback={this.handleToggleFavourites}
                        toggleHistoryCallback={this.handleToggleHistory}
                    />
                </div>

                <div
                    ref={this.secondDivRef}
                    id="midPanel"
                    className={
                        !this.state.isBottomPanelOpen
                            ? "rytpanelmidpart"
                            : "rytpanelmidpartWithBottom"
                    }
                    onClick={() => this.handleActiveRightLink("")}
                >
                    <div className="midPanel_Container">
                        <div className="midPanel_Item1">
                            {this.state.selectedDocType !== "" &&
                                this.state.isUploadFileOpen && (
                                    <FileProcessing
                                        handleFile={this.handleSummaryFile}
                                        inProgress={
                                            summaryResult !== "" ? false : true
                                        }
                                    ></FileProcessing>
                                )}
                            {summaryResult &&
                            Object.keys(summaryResult)?.length > 0 ? (
                                <div className="reviewdatasec">
                                    <div className="sglereviewsec">
                                        <TitleOfComponent
                                            buttonTitle={
                                                this.state
                                                    ?.disableAddToWorkspace
                                                    ? "Document auto saved in workspace "
                                                    : " Add to workspace"
                                            }
                                            title={""}
                                            description={null}
                                            isExpanded={
                                                this.state?.isContentExpanded
                                            }
                                            exapandfncallback={
                                                this.handleToggleExpndableBox
                                            }
                                            fileName={this.state?.fileName}
                                            CannedQuestionsList={[
                                                {
                                                    // question: response.q.msg,
                                                    question: "Summary Notes",
                                                    response:
                                                        this.state
                                                            ?.summaryResult &&
                                                        "\n\nConcise Summary\n\n" +
                                                            this.state
                                                                ?.summaryResult
                                                                ?.Concise_Summary,
                                                    time: new Date().toLocaleString(),
                                                    timetaken:
                                                        this.state
                                                            ?.summaryTimer +
                                                        "s",
                                                },
                                                ...this.state
                                                    ?.CannedQuestionsList,
                                            ]}
                                            componenttitle="SummarizeDocs"
                                            setStateCallback={() => {
                                                this.setState(
                                                    {
                                                        openCreateWorkSpacePopUp: true,
                                                    },
                                                    () =>
                                                        this.props.updateSummaryState(
                                                            this.state
                                                        )
                                                );
                                            }}
                                            disableAddToWorkspace={
                                                this.state
                                                    ?.disableAddToWorkspace
                                            }
                                            workspace_name={
                                                this.state?.workspace_name
                                            }
                                            workspace_id={
                                                this.state?.workspace_id
                                            }
                                            workspace_created_by={
                                                this.state?.workspace_created_by
                                            }
                                            users={this.state.ws_shared_users}
                                            AddToAnotherWorkspace={
                                                this.addToAnotherWorkspace
                                            }
                                        />

                                        <div className="reviewdatasec">
                                            <ContentReviewSection
                                                title="Notes"
                                                timer={this.state?.summaryTimer}
                                                response={
                                                    this.state.summaryResult
                                                        ? this.state
                                                              ?.summaryResult
                                                        : ""
                                                }
                                                filename={this.state?.fileName}
                                                CannedQuestionsList={
                                                    this.state
                                                        ?.CannedQuestionsList
                                                }
                                                question={null}
                                                componenttitle="SummarizeDocs"
                                                isExpanded={
                                                    this.state
                                                        ?.isContentExpanded
                                                }
                                                reviewTime={
                                                    this.state?.summaryTime
                                                }
                                                review_by_name={
                                                    this.state
                                                        ?.isSharedWorkspace
                                                        ? this.state
                                                              ?.created_by_name
                                                        : ""
                                                }
                                                isSummary={true}
                                                engine_id={
                                                    +this.state.selectedEngine
                                                }
                                            />
                                            {this.state?.CannedQuestionsList
                                                ?.length !== 0 &&
                                                this.state.CannedQuestionsList?.map(
                                                    (item, i) => (
                                                        <>
                                                            {this.state
                                                                ?.CannedQuestionsList
                                                                ?.length -
                                                                1 ===
                                                                i && (
                                                                <div
                                                                    style={{
                                                                        float: "left",
                                                                        clear: "both",
                                                                    }}
                                                                    ref={(
                                                                        el
                                                                    ) => {
                                                                        this.messagesEnd =
                                                                            el;
                                                                    }}
                                                                ></div>
                                                            )}
                                                            <div className="readmoredata">
                                                                <ContentReviewSectionComponent
                                                                    key={i}
                                                                    question={
                                                                        item?.question
                                                                    }
                                                                    time={
                                                                        item?.time
                                                                    }
                                                                    timetaken={
                                                                        item?.timetaken
                                                                    }
                                                                    response={
                                                                        item?.response
                                                                    }
                                                                    fileName={
                                                                        this
                                                                            .state
                                                                            ?.fileName
                                                                    }
                                                                    componenttitle="SummarizeDocs"
                                                                    isExpanded={
                                                                        this
                                                                            .state
                                                                            ?.isContentExpanded
                                                                    }
                                                                    created_by_name={
                                                                        item?.review_by_name
                                                                    }
                                                                    embeddings={
                                                                        item?.embeddings
                                                                    }
                                                                    engine_id={
                                                                        +this
                                                                            .state
                                                                            .selectedEngine
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            {this.state.isLoading ? (
                                <LoadingSpinnerSticky
                                    text={this.state.loaderText}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        {this.state.fileName?.length > 0 &&
                        Object.keys(this.state.selectedFile).length !== 0 ? (
                            <>
                                <CustomExpandableDocComponent
                                    isDocumentOpen={this.state.isDocumentOpen}
                                    toggleDocumentVisibleStateCallback={
                                        this.handleToggleDocumentViewer
                                    }
                                    customclass="midPanel_Item2"
                                    childComponent={
                                        <>
                                            <div
                                                className="media_viewer_container"
                                                key={this.state.fileName}
                                            >
                                                <CustomDocViewer
                                                    name={
                                                        this.state.selectedFile
                                                            ?.file?.name
                                                    }
                                                    opendocdate=""
                                                    mimetype={
                                                        this.state.selectedFile
                                                            ?.file?.type
                                                    }
                                                    file={
                                                        this.state.selectedFile
                                                            ?.file
                                                    }
                                                ></CustomDocViewer>
                                            </div>
                                        </>
                                    }
                                    activedocname={this.state.fileName}
                                ></CustomExpandableDocComponent>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {this.state.isIndexCreated && (
                    <ErrorBoundary>
                        <ChatComponent
                            ref={this.chatComponentRef}
                            module={this.module_id}
                            module_name="SummarizeDocType"
                            activeTab={0}
                            toggleTab={this.toggleTab}
                            selectedDocType={this.state.selectedDocType}
                            handleCannedQuestion={this.handleCannedQuestion}
                            handleBottomPanel={this.handleBottomPanel}
                            isBottomPanelOpen={this.state.isBottomPanelOpen}
                            selectedCanned_Ques={this.state.selectedCanned_Ques}
                            isReview={true}
                            promptDisplayed={this.state.promptDisplayed}
                            isShowFavorites={true}
                            uploadFileId={this.state.uploadFileId}
                            chatType="summarize"
                            hwFlag={this.state.isHandWritten}
                            handleResponse={this.handleResponse}
                            setLoaderStatus={this.setLoaderStatus}
                            isLoading={this.state.isLoading}
                            token={this.props.token}
                            removeToken={this.props.removeToken}
                            selectedEngine={this.state.selectedEngine}
                            selectedFile={this.state.selectedFile}
                            setReqStartTime={this.setReqStartTime}
                            blobFilesList={this.state.blobFilesList}
                            handleUpdateBlobFilesState={
                                this.handleUpdateBlobFilesState
                            }
                            inputValue={this.state.selectedPrompt.id}
                            fileName={this.state.selectedFile.file?.name}
                            workspace_item_id={this.state.workspace_item_id}
                            workspace_id={this.state.workspace_id}
                            index_path={this.state.index_path}
                            reqStartTime={this.state.reqStartTime}
                            doc_help_questions={this.state.doc_help_questions}
                        />
                    </ErrorBoundary>
                )}
                <Toaster />
            </Fragment>
        );
    }
}

// SummarizeComponent.contextType = WorkspaceContext

const mapStateToProps = (state) => ({
    summarizeState: state.summary.summarizeState, // Use the appropriate reducer key
    dashBoardState: state.dashBoard.dashBoardState,
});

const mapDispatchToProps = {
    updateSummaryState, // This maps your action directly to props
    updateDashBoardState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummarizeComponent);
