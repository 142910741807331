import React, { Component, Fragment } from "react";
import { isEqual } from "lodash";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import prompts_ddl from "../../dropDowns/ChatWithDocTypes.json";
import LoadingSpinner from "../SupportiveComponents/LoadingSpinner";
import { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { setTitle } from "../../js/setTitle.js";
import { updateChatWithInternalDocsState } from "../../redux/actions";
import { connect } from "react-redux";
import ChatComponent from "../SupportiveComponents/ChatComponent.jsx";
import getAxiosInstance, {
    Createaccesslog,
} from "../SupportiveComponents/AxiosRequest.jsx";
import ErrorBoundary from "../SupportiveComponents/CustomErrorBoundary.jsx";
import {
    HandleDocTypeChangeComponent,
    HandleEngineComponent,
} from "../SupportiveComponents/ModuleHelpers.jsx";
import {
    ContentReviewSectionComponent,
    TitleOfComponent,
} from "../SupportiveComponents/CustomContentOperations/CustomContentViewer.jsx";
import HelperMessages from "../../Helpers/HelperMessages.js";

class ChatWithInternalDocComponent extends Component {
    module_id = docTypes_ddl.Modules["ChatWithInternalDocType"].moduleId;
    state = {
        isBottomPanelOpen: true,
        selectedDocType: "",
        selectedEngine: 1,
        chatResult: "",
        promptDisplayed: HelperMessages.PROMPT_PLACEHOLDER,
        selectedPrompt: {},
        activeTab: 1,
        isLoading: false,
        loaderText: "Analysis in progress...",
        chatTimer: "",
        Chats: [],
        PrevChats: [],
        inputValue: "",
        selectedCanned_Ques: [],
        docSourceMap: {},
        fileDDL: [],
        matchingOptions: [],
        inputSource: "",
        source: "",
        focusedOptionIndex: -1,
        isFileddlOpen: false,
        reqStartTime: new Date().getTime(),
        topk: 5,
        isContentExpanded: true,
        tabID: sessionStorage.getItem("tabID"),
    };

    handleTopkChange = (event) => {
        this.setState({ topk: event.target.value.replace(/\D/g, "") }, () => {
            this.props.updateChatWithInternalDocsState(this.state);
        });
    };

    handleChange = (event) => {
        this.setState({ inputValue: event.target.value }, () => {
            this.props.updateChatWithInternalDocsState(this.state);
        });
    };
    handleCannedQuestion = (question) => {
        try {
            if (this.state.selectedDocType !== "") {
                let selectedPrompt = question.id
                    ? question
                    : { id: question, value: question };

                this.setState(
                    {
                        promptDisplayed: question.id ? question.id : question,
                        selectedPrompt,
                        isLoading: true,
                        isBottomPanelOpen: true,
                    },
                    () => {
                        this.props.updateChatWithInternalDocsState(this.state);
                    }
                );
            }
        } catch (error) {
            this.setLoaderStatus(false);
            console.error("Error:", error);
        }
    };
    setReqStartTime = (time) => {
        try {
            this.setState({ reqStartTime: time }, () => {
                this.props.updateChatWithInternalDocsState(this.state);
            });
        } catch (error) {
            console.log(error);
        }
    };
    getDocSourceMap = () => {
        return new Promise((resolve, reject) => {
            const axiosInstance = getAxiosInstance(
                this.props.token,
                false,
                this.props?.history
            );
            axiosInstance
                .get("/docsourcemap")
                .then((response) => {
                    if (response?.data) {
                        this.setState({ docSourceMap: response?.data }, () => {
                            this.props.updateChatWithInternalDocsState(
                                this.state
                            );
                        });
                        console.log("DocSourceMap", response?.data);
                        resolve(response?.data);
                    } else {
                        resolve(response?.data);
                    }
                })
                .catch((error) => {
                    resolve(error);
                    let status = error.response ? error.response.status : 200;
                    if (status === 401 || status === 422) {
                        this.props.removeToken();
                    }
                });
        });
    };

    async componentDidMount() {
        setTitle("Repository Chat");
        this.scrollToBottom();
        document.addEventListener("mousedown", this.handleClickOutside);

        await this.getDocSourceMap();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        //chatWithInternalDocsState
        if (
            !isEqual(
                prevProps.chatWithInternalDocsState,
                this.props.chatWithInternalDocsState
            )
        ) {
            // State has changed, trigger re-render

            this.setState({ ...this.props.chatWithInternalDocsState });
        }
    }

    handleClickOutside = (event) => {
        if (
            this.selectRef.current &&
            !this.selectRef.current.contains(event.target)
        ) {
            // Click is outside the input/select area, clear filtered options
            this.setState({ isFileddlOpen: false }, () => {
                this.props.updateChatWithInternalDocsState(this.state);
            });
            if (this.state.source === "") {
                this.setState({ inputSource: "" }, () => {
                    this.props.updateChatWithInternalDocsState(this.state);
                });
            }
        }
    };

    scrollToBottom() {
        // this.el.scrollIntoView({ behavior: "smooth" });
        const secondDivElement = this.secondDivRef.current;
        secondDivElement.scrollTop = secondDivElement.scrollHeight;
        this.setState(
            {
                PrevChats: this.state.Chats,
            },
            () => {
                this.props.updateChatWithInternalDocsState(this.state);
            }
        );
    }

    handleBottomPanel = (isOpen) => {
        this.setState(
            {
                isBottomPanelOpen: isOpen,
            },
            () => {
                this.props.updateChatWithInternalDocsState(this.state);
            }
        );
    };

    toggleTab = (tabId) => {
        this.setState({ activeTab: tabId }, () => {
            this.props.updateChatWithInternalDocsState(this.state);
        });
        this.handleBottomPanel(true);
    };

    constructor(props) {
        super(props);
        this.secondDivRef = React.createRef();

        this.selectRef = React.createRef();
        this.state = this.props.chatWithInternalDocsState;
    }

    setLoaderStatus = (flag) => {
        try {
            this.setState({ isLoading: flag }, () => {
                this.props.updateChatWithInternalDocsState(this.state);
            });
        } catch (error) {
            console.log("ErrorTriggerMsgSet:", error);
        }
    };

    handleResponse = (response) => {
        try {
            if (response === undefined) {
                Swal.fire({
                    text: "We are unable to process the chat questions. Maybe try another file.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                this.setState(
                    {
                        isLoading: false,
                        selectedDocType: "",
                        inputValue: "",
                        Chats: [],
                        inputSource: "",
                        source: "",
                    },
                    () => {
                        this.props.updateChatWithInternalDocsState(this.state);
                    }
                );
            } else {
                let now = new Date().getTime();
                let startTime = this.state.reqStartTime;
                let processtimeinsec = ((now - startTime) / 1000).toFixed(2);
                let formDataForAccessLog = new FormData();
                formDataForAccessLog.append("module_id", this.module_id);
                formDataForAccessLog.append(
                    "engine_id",
                    this.state.selectedEngine
                );
                formDataForAccessLog.append(
                    "document_type",
                    this.state.selectedDocType
                );
                formDataForAccessLog.append(
                    "is_hand_written",
                    this.state.isHandWritten
                );
                formDataForAccessLog.append("doc_name", this.state.inputSource);
                Createaccesslog(formDataForAccessLog, processtimeinsec);
                let para = "";
                if (Array.isArray(response.msg)) {
                    response.msg.forEach((element) => {
                        para += element + " \n ";
                    });
                } else {
                    para = response.msg;
                }
                let links = "";
                let metas = response.metas.slice(0, 2);
                metas.map(
                    (link) =>
                        (links += link.link + " - Pages " + link.pages + " \n ")
                );
                metas.forEach((element) => {
                    const lastDotIndex = element.link.lastIndexOf("/");
                    if (lastDotIndex !== -1) {
                        element.label = element.link.substring(
                            lastDotIndex + 1
                        );
                    } else {
                        element.label = element.link;
                    }
                });
                let obj = {
                    response: para + " \n Sources \n " + links,
                    question: this.state.selectedPrompt.id,
                    message: para,
                    sources: metas,
                    time: new Date().toLocaleString(),
                    timetaken: processtimeinsec + "s",
                };
                const { Chats } = this.state;

                this.setState(
                    {
                        Chats: [...Chats, obj],
                        isLoading: false,
                        inputValue: "",
                    },
                    () => {
                        this.props.updateChatWithInternalDocsState(this.state);
                    }
                );
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
            this.setState(
                {
                    chatResult: "",
                    isLoading: false,
                },
                () => {
                    this.props.updateChatWithInternalDocsState(this.state);
                }
            );
            Swal.fire({
                text: "We are unable to process the chat questions. Maybe try another file.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    handleFileInputChange = (event) => {
        const value = event.target.value;
        const matchingOptions = this.state.fileDDL.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        this.setState(
            {
                matchingOptions,
                inputSource: value,
                isFileddlOpen: value !== "" ? true : false,
                focusedOptionIndex: -1,
                source: "",
            },
            () => {
                this.props.updateChatWithInternalDocsState(this.state);
            }
        );
    };

    handleFileSelect = (option) => {
        console.log("value", option.value);
        console.log("label", option.label);
        this.setState(
            {
                source: option.value,
                inputSource: option.label,
                isFileddlOpen: false,
                focusedOptionIndex: -1,
            },
            () => {
                this.props.updateChatWithInternalDocsState(this.state);
            }
        );
    };

    handleDocTypeChange = (event) => {
        const { value } = event.target;
        let type = value;
        let temp_type = type.replace("_f", "");
        type = temp_type.replace("_v2", "");

        let docSourceMap = this.state.docSourceMap;

        let docType_File_DDL = [];
        if (docSourceMap[temp_type])
            docSourceMap[temp_type].forEach((item) => {
                let option = {
                    value: item.url,
                    label: item.doc_id + " - " + item.name,
                };
                docType_File_DDL.push(option);
            });

        this.setState(
            {
                selectedDocType: value,
                selectedCanned_Ques: prompts_ddl[type + "_Canned_Ques"],
                CannedQuestionsList: [],
                reviewResult: "",
                fileDDL: docType_File_DDL,
                Chats: [],
                source: "",
                inputSource: "",
            },
            () => {
                this.props.updateChatWithInternalDocsState(this.state);
            }
        );
        console.log(value);
        console.log(prompts_ddl[type + "_Canned_Ques"]);
    };

    handleEngineChange = (event) => {
        const { value } = event.target;
        console.log("Engine selected: ", value);
        this.setState(
            {
                selectedEngine: value,
            },
            () => this.props.updateChatWithInternalDocsState(this.state)
        );
    };

    handleKeyDown = (event) => {
        const { focusedOptionIndex, matchingOptions } = this.state;
        console.log("Key", event.key, focusedOptionIndex, matchingOptions);
        if (event.key === "ArrowDown") {
            event.preventDefault();

            if (focusedOptionIndex < matchingOptions.length - 1) {
                this.setState(
                    {
                        focusedOptionIndex: focusedOptionIndex + 1,
                    },
                    () => {
                        this.props.updateChatWithInternalDocsState(this.state);
                    }
                );
            }
        } else if (event.key === "ArrowUp") {
            event.preventDefault();

            if (focusedOptionIndex > 0) {
                this.setState(
                    {
                        focusedOptionIndex: focusedOptionIndex - 1,
                    },
                    () => {
                        this.props.updateChatWithInternalDocsState(this.state);
                    }
                );
            }
        } else if (event.key === "Enter") {
            event.preventDefault();

            if (
                focusedOptionIndex >= 0 &&
                focusedOptionIndex < matchingOptions.length
            ) {
                this.handleFileSelect(matchingOptions[focusedOptionIndex]);
            }
        }
    };
    handleExpandCollapse = () => {
        this.setState(
            {
                isContentExpanded: !this.state.isContentExpanded,
            },
            () => this.props.updateChatWithInternalDocsState(this.state)
        );
    };

    getPath = (path) => {};

    render() {
        const {
            inputSource,
            isFileddlOpen,
            matchingOptions,
            focusedOptionIndex,
        } = this.state;

        return (
            <Fragment>
                <div className="rytpaneltoppart">
                    <div className="reviewfirstdiv">
                        <h2>Repository Chat</h2>
                    </div>
                    <div className="reviewdescriptionsec">
                        {/* <p>Add review description here. Limit to 2 lines</p> */}
                        <div className="reviewdescription">
                            <HandleDocTypeChangeComponent
                                handleDocTypeChangeCallback={
                                    this.handleDocTypeChange
                                }
                                selectedDocType={this.state.selectedDocType}
                                documentTypes={docTypes_ddl.ChatWithInternalDocType.sort(
                                    (a, b) =>
                                        a.documentName.localeCompare(
                                            b.documentName
                                        )
                                )}
                                fileName={this.state.fileName}
                            />

                            <HandleEngineComponent
                                selectedEngine={this.state.selectedEngine}
                                handleEngineChangeCallback={
                                    this.handleEngineChange
                                }
                            />

                            <div className="fullfieldwidth" data-order="3">
                                <div
                                    className="fieldsec"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                >
                                    <input
                                        id="topk"
                                        type="number"
                                        className="SearchBar"
                                        defaultValue="5"
                                        min="5"
                                        max="100"
                                        onChange={this.handleTopkChange}
                                    />
                                </div>
                            </div>

                            <div className="fullfieldwidth" data-order="4">
                                <div
                                    className="fieldsec search"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Choose a file"
                                >
                                    <input
                                        className="SearchBar"
                                        type="text"
                                        placeholder="Type to select an option"
                                        value={inputSource}
                                        onChange={this.handleFileInputChange}
                                        disabled={
                                            this.state.selectedDocType === ""
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={inputSource}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    <div
                                        className="searchBarDiv"
                                        ref={this.selectRef}
                                    >
                                        {isFileddlOpen &&
                                            matchingOptions.length > 0 &&
                                            inputSource !== "" && (
                                                <ul>
                                                    {matchingOptions.map(
                                                        (option, index) => (
                                                            <li
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={
                                                                    option.label
                                                                }
                                                                key={index}
                                                                className={
                                                                    focusedOptionIndex ===
                                                                    index
                                                                        ? "focused"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.handleFileSelect(
                                                                        option
                                                                    )
                                                                }
                                                            >
                                                                {option.label}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    ref={this.secondDivRef}
                    id="midPanel"
                    className={
                        !this.state.isBottomPanelOpen
                            ? "rytpanelmidpart"
                            : "rytpanelmidpartWithBottom"
                    }
                >
                    <div className="midPanel_Container">
                        <div className="midPanel_Item1">
                            {this.state.Chats.length !== 0 ? (
                                <div className="reviewdatasec">
                                    <TitleOfComponent
                                        title={""}
                                        description={null}
                                        isExpanded={
                                            this.state.isContentExpanded
                                        }
                                        exapandfncallback={
                                            this.handleExpandCollapse
                                        }
                                        fileName={this.state.fileName}
                                        CannedQuestionsList={this.state.Chats}
                                        componenttitle="ChatInternalDocs"
                                    />
                                    {this.state.Chats.map((item, i) => (
                                        <>
                                            <ContentReviewSectionComponent
                                                key={i}
                                                question={item.question}
                                                time={item.time}
                                                timetaken={item.timetaken}
                                                response={item?.message}
                                                fileName={
                                                    item?.sources[0]?.label
                                                }
                                                componenttitle="ChatInternalDocs"
                                                isExpanded={
                                                    this.state.isContentExpanded
                                                }
                                                sources={item?.sources}
                                                engine_id={
                                                    this.state.selectedEngine
                                                }
                                            ></ContentReviewSectionComponent>
                                        </>
                                    ))}
                                </div>
                            ) : (
                                this.state.selectedDocType !== "" && (
                                    <div className="parent">
                                        <div className="rectangle-box_CID">
                                            <div className="content">
                                                Use the chat box below to chat
                                                by asking questions
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <ErrorBoundary>
                    <ChatComponent
                        activeTab={this.state.activeTab}
                        favoriteFiles={[]}
                        module={this.module_id}
                        toggleTab={this.toggleTab}
                        selectedDocType={this.state.selectedDocType}
                        handleBottomPanel={this.handleBottomPanel}
                        isBottomPanelOpen={this.state.isBottomPanelOpen}
                        selectedCanned_Ques={this.state.selectedCanned_Ques}
                        handleCannedQuestion={this.handleCannedQuestion}
                        isReview={false}
                        isSummary={false}
                        inputValue={this.state.inputValue}
                        promptDisplayed={this.state.promptDisplayed}
                        source={this.state.source}
                        setLoaderStatus={this.setLoaderStatus}
                        handleResponse={this.handleResponse}
                        chatType="internal"
                        isLoading={this.state.isLoading}
                        token={this.props.token}
                        removeToken={this.props.removeToken}
                        selectedEngine={this.state.selectedEngine}
                        setReqStartTime={this.setReqStartTime}
                        topk={this.state.topk}
                        getPathBack={this.getPath}
                        tabID={this.state.tabID}
                    />
                </ErrorBoundary>
                {this.state.isLoading ? (
                    <LoadingSpinner text={this.state.loaderText} />
                ) : (
                    ""
                )}
                <Toaster />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    chatWithInternalDocsState:
        state.chatWithInternalDocs.chatWithInternalDocsState, // Use the appropriate reducer key
});

const mapDispatchToProps = {
    updateChatWithInternalDocsState, // This maps your action directly to props
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatWithInternalDocComponent);
