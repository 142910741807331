import * as Yup from "yup";
import getInstance from "../Components/SupportiveComponents/AxiosRequest";

export const fullNameSchema = Yup.string()
    .required("Please enter both first and last names")
    .test("two-words", "Please enter both first and last names", (value) => {
        const words = value?.trim().split(/\s+/);
        return words.length >= 2;
    })
    .test(
        "word-length",
        "Please enter names in full, not just initials",
        (value) => {
            const words = value?.trim().split(/\s+/);
            return words?.every((word) => word?.length > 1);
        }
    )
    .test(
        "no-numbers",
        "Please enter both first and last names without numbers",
        (value) => {
            const words = value?.trim().split(/\s+/);
            return words?.every((word) => !/\d/.test(word));
        }
    )
    .test(
        "special-characters",
        "_, -, ' are the only special characters allowed. Please correct.",
        (value) => {
            const words = value.trim().split(/\s+/);
            return words?.every((word) => /^[A-Za-z-_']+$/i.test(word));
        }
    );

export const TrailAccountSchema = Yup.object({
    userFullName: fullNameSchema,
    password_new: Yup.string()
        .required("Password is required")
        .min(8, "Pasword must be 8 or more characters")
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])\w+/,
            "Password ahould contain at least one uppercase and lowercase character"
        )
        .matches(/\d/, "Password should contain at least one number")
        .matches(
            /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
            "Password should contain at least one special character"
        ),
    confirmpassword: Yup.string().when("password", (password_new, field) => {
        if (password_new) {
            return field
                .required("The passwords do not match")
                .oneOf([Yup.ref("password_new")], "The passwords do not match");
        }
    }),
    bEmail: Yup.string()
        .required("Please enter your business email")
        .email("Invalid email address")
        .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
            "Invalid email address"
        )
        .test(
            "check-existence-email",
            "An account with that email address already exists. Please verify the email address used",
            async function (value) {
                // Check user existence asynchronously

                if (value) {
                    let resp = UserOrEmailValidation(value);
                    return resp;
                } else {
                    return true; // Skip check if no value provided
                }
            }
        )
        .test(
            "free-domain-email-test",
            "Please provide a business email",
            async function (value) {
                // Check user existence asynchronously

                if (value) {
                    let testdomainval = value.split("@")[1];
                    const free_email_domains = [
                        "gmail.com",
                        "yahoo.com",
                        "hotmail.com",
                        "outlook.com",
                    ];
                    return !free_email_domains.includes(testdomainval);
                } else {
                    return true; // Skip check if no value provided
                }
            }
        ),
    phoneNumber: "",
    jobTitle: Yup.string()
        .test(
            "special-characters",
            "_, -, ' are the only special characters allowed. Please correct.",
            (value) => {
                if (!value) {
                    return true;
                }
                const words = value?.trim().split(/\s+/);
                return words?.every((word) => /^[A-Za-z-_']+$/i.test(word));
            }
        )
        .test(
            "word-length",
            "Please enter jobTitle in full, not just initials",
            (value) => {
                if (!value) {
                    return true;
                }
                const words = value?.trim().split(/\s+/);
                return words?.every((word) => word.length > 1);
            }
        )
        .test(
            "no-numbers",
            "Please enter jobTitle without numbers",
            (value) => {
                if (!value) {
                    return true;
                }
                const words = value?.trim().split(/\s+/);
                return words?.every((word) => !/\d/.test(word));
            }
        ),

    companyName: Yup.string()
        .required("Company name is required")
        .test(
            "special-characters",
            "_, -, ' are the only special characters allowed. Please correct.",
            (value) => {
                const words = value?.trim().split(/\s+/);
                return words?.every((word) => /^[A-Za-z-_']+$/i.test(word));
            }
        ),
    userName: Yup.string()
        .min(4, "Please choose a username that is at least 4 chars long")
        .max(50, "username is too Long!")
        .matches(
            /^.*\s*.*$/,
            "This field cannot contain white space and special character"
        )
        .test(
            "special-characters",
            "_, -, ' are the only special characters allowed. Please correct.",
            (value) => {
                const words = value?.trim().split(/\s+/);
                return words?.every((word) => /^[A-Za-z-_\d']+$/i.test(word));
            }
        )
        .test(
            "check-existence-username",
            "Username is invalid or already taken. Please choose another.",
            function (value) {
                // Check user existence asynchronously
                console.log(value);
                if (value) {
                    let resp = UserOrEmailValidation(value);
                    return resp;
                } else {
                    return true; // Skip check if no value provided
                }
            }
        )
        .required("Username is required"),
    termsAndConditions: Yup.boolean().oneOf(
        [true],
        "please check terms and conditions"
    ),
    captchaValue: "",
});

const UserOrEmailValidation = async (value) => {
    const customAxios = getInstance();
    let resp = await customAxios
        .get("/user/" + value, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 300000,
        })
        .then(async (response) => {
            console.log(
                response?.data?.length,
                "test data",
                response.data,
                response?.data?.length <= 0
            );
            let respbool = (await response?.data?.length) <= 0;
            return respbool;
        })
        .catch((error) => {
            console.log("Error occurred ", error);
            return false;
        });
    console.log("final response,", resp);
    return resp;
};