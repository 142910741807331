import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import ErrorBoundary from "./Components/SupportiveComponents/CustomErrorBoundary";
import { socketContext, socket } from "./contexts/socket";
import { StrictMode } from "react";
import { ModuleProvider } from "./contexts/GlobalModuleContext";

// console.log("store",store)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <socketContext.Provider value={socket}>
                <Provider store={store}>
                    <ErrorBoundary>
                        <StrictMode>
                            <ModuleProvider>
                                <App />
                            </ModuleProvider>
                        </StrictMode>
                    </ErrorBoundary>
                </Provider>
            </socketContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
