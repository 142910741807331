import React, { useEffect, useState } from "react";
import InitialsAvatar from "react-initials-avatar";
import Swal from "sweetalert2";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import hamburger from "../../../images/hamburger.png";
import close from "../../../images/close.png";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import userTypes from "../../../dropDowns/userTypes.json";
import kavayahlogo from "../../../images/lawbotica-mixed-v3.webp";
import kavayahlogolight from "../../../images/Lawbotica-Logo-Color-v1.webp";
import { ThemeComponent } from "../ThemeComponent.jsx";
import { NotificationComponent } from "../../SupportiveComponents/Notifications/NotificationComponent.jsx";
import useTheme from "../../../contexts/theme.js";

function Header(props) {
    const myReduxState = useSelector((state) => state.login.logInState);
    const nav = useNavigate();
    const toggleOpen = () => {
        props.setSidePaneStatus(!props.isLeftPanelOpen);
    };

    function logMeOut() {
        const axiosInstance = getAxiosInstance(props.token);
        axiosInstance
            .delete("/logout")
            .then((response) => {
                console.log("LogOut Response: ", response);
                props.removeToken();
                nav("/Login");
            })
            .catch((error) => {
                console.log("From API: ", error);
                let status = error.response.status;
                let error_text =
                    "Error while Logging you out, please try after some time.";
                if (status === 401 || status === 422) {
                    props.removeToken();
                    nav("/Login");
                } else
                    Swal.fire({
                        text: error_text,
                        icon: "error",
                        confirmButtonText: "OK",
                    });
            });
    }

    return (
        <header className="headersec">
            <div className="headeralignpad">
                <div
                    className={
                        !props.isLeftPanelOpen
                            ? "logo  "
                            : "logo   hidevisiblearea"
                    }
                >
                    <a href="/" title="LawBotica">
                        <KavayahLogo />
                    </a>
                </div>
                <div className="headerrightside">
                    <NotificationComponent />
                    <ThemeComponent></ThemeComponent>
                    <div className="rytheadinfo">
                        <div
                            className={
                                !props.isLeftPanelOpen
                                    ? "filter filterdropdown "
                                    : "filter filterdropdown  hidevisiblearea"
                            }
                        >
                            <div className="userprofile">
                                {" "}
                                <span className="dropdownicon"></span>
                                <div className="userthumb">
                                    <InitialsAvatar
                                        name={myReduxState.userFullName}
                                    />
                                </div>
                                <div className="userdetail">
                                    <div className="username">
                                        {myReduxState.userFullName}
                                    </div>
                                    {myReduxState?.job_title !== "N/A" && (
                                        <div className="userdesignation">
                                            {myReduxState?.job_title}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="dropdowncontent">
                                <ul>
                                    <li>
                                        <p>{myReduxState.organization}</p>
                                    </li>
                                    {myReduxState.userType ===
                                    userTypes.UserTypes.KavayahAdmin.Name ? (
                                        <>
                                            <li className="subsequent-dropdown">
                                                <p>
                                                    Admin{" "}
                                                    <span className="icon">
                                                        {" "}
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Dashboard"
                                                        key="1"
                                                    >
                                                        <NavLink
                                                            exact="true"
                                                            to="ksadmin/dashboard"
                                                            className="dashboard"
                                                            activeclassname="active"
                                                        >
                                                            <span>
                                                                Dashboard
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Users"
                                                        key="2"
                                                    >
                                                        <NavLink
                                                            exact="true"
                                                            to="ksadmin/useroperations"
                                                            className="dashboard"
                                                            activeclassname="active"
                                                        >
                                                            <span>Users</span>
                                                        </NavLink>
                                                    </li>
                                                    <li
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Raw Logs"
                                                        key="3"
                                                    >
                                                        <NavLink
                                                            exact="true"
                                                            to="ksadmin/response_logs"
                                                            className="logs"
                                                            activeclassname="active"
                                                        >
                                                            <span>Raw Logs</span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {myReduxState.userType ===
                                    userTypes.UserTypes.OrganizationAdmin
                                        .Name ? (
                                        <>
                                            <li className="subsequent-dropdown">
                                                <p>
                                                    Organization Admin{" "}
                                                    <span className="icon">
                                                        {" "}
                                                    </span>
                                                </p>
                                                <ul>
                                                    <li
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Add User"
                                                        key="1"
                                                    >
                                                        <NavLink
                                                            exact="true"
                                                            to="adduser"
                                                            className="dashboard"
                                                            activeclassname="active"
                                                        >
                                                            <span>
                                                                Add User
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Remove User"
                                                        key="2"
                                                    >
                                                        <NavLink
                                                            exact="true"
                                                            to="removeuser"
                                                            className="dashboard"
                                                            activeclassname="active"
                                                        >
                                                            <span>
                                                                Remove User
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                   
                                                </ul>
                                            </li>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <li>
                                        <p>Preferences</p>
                                    </li>
                                    <li>
                                        <p onClick={()=>{props?.setStartWalkthrough(true)}}>Run Walkthrough Again</p>
                                    </li>
                                    <li>
                                        <p onClick={logMeOut}>Sign Out</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button className="menuBtn" onClick={toggleOpen}>
                            {!props.isLeftPanelOpen ? (
                                <div className="hamburgermenu">
                                    <div className="closebtn">
                                        <img
                                            src={hamburger}
                                            alt=""
                                            className="hamburger"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="hamburgermenu">
                                    <div className="closebtn">
                                        <img src={close} alt="" />
                                    </div>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}

/**
 *
 * @returns image logo of website
 */
export function KavayahLogo() {
    const { themeMode } = useTheme();
    const [theme, setTheme] = useState(themeMode);
    useEffect(() => {
        setTheme(themeMode);
    }, [themeMode]);

    return theme === "light" ? (
        <img src={kavayahlogolight} alt="LawBotica" />
    ) : (
        <img src={kavayahlogo} alt="LawBotica" />
    );
}

export default Header;
