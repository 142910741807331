import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moduleTypes from "../../dropDowns/docTypes.json";
import { setTitle } from "../../js/setTitle.js";
import ReviewComponent from "../MainComponents/ReviewComponent.jsx";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx";
import DashBoardCards from "../SupportiveComponents/DashboardComponents/DashBoardCards.jsx";
import { UsageComponent } from "../SupportiveComponents/DashboardComponents/DashBoardUsage.jsx";
import NoContentText from "../SupportiveComponents/DashboardComponents/DashboardNoContent.jsx";
import ShareWorkSpacePopUp from "../SupportiveComponents/PopUps/ShareWorkSpacePopUp.jsx";
import store from "../../redux/store.js";


export default function DashBoard(props) {
    const [module, setModule] = useState(0);
    const [favoriteFiles, SetFavFiles] = useState([]);
    const [caseAnalysisFavorites, SetCaseAnalysisFavorites] = useState([]);
    const [historyFiles, SetHistoryFiles] = useState([]);
    const [isFavoritesLoading, SetIsFavLoading] = useState(false);
    const [openSharePopUp, setOpenSharePopUp] = useState(false);
    const [workSpaces, setWorkSpaces] = useState([]);
    const [sharedWorkSpaces, setSharedWorkSpaces] = useState([]);
    const [favoritedWorkSpaces, setFavoritedWorkSpaces] = useState([]);

    useEffect(() => {
        const axiosInstance = getAxiosInstance(props.token, false, props?.history);
        let userId = localStorage.getItem("user_id");
        setFavoritedWorkSpaces([]);
        axiosInstance.get("/workspace/all", { timeout: 180000 }).then((res) => {
            if (res?.data?.length > 0) {
                const sortedItems = [...res.data].sort(
                    (a, b) =>
                        new Date(b.last_modified_at) -
                        new Date(a.last_modified_at)
                );
                if (parseInt(userId)) {
                    setWorkSpaces(
                        sortedItems.filter(
                            (x) => x.created_by === parseInt(userId)
                        )
                    );
                    setSharedWorkSpaces(
                        sortedItems.filter(
                            (x) =>
                                x.created_by !== parseInt(userId) &&
                                x.users.filter(
                                    (x) => x.user_id === parseInt(userId)
                                ).length > 0
                        )
                    );
                    setFavoritedWorkSpaces(
                        sortedItems.filter(
                            (x) =>
                                x.favorites.filter(
                                    (y) => y.user_id === parseInt(userId)
                                ).length > 0
                        )
                    );
                }
            }
        }).catch((error) => {
            console.error("Error:", error);
            let status = error.response ? error.response.status : 200;
            if (status === 401 || status === 422) {
                props.removeToken();
            }
        })
    }, [props]);
    // console.log(value);
    const [isHistoryFilesLoading, SetIsHistoryLoading] = useState(false); //SetIsHistoryLoading

    useEffect(() => {
        setTitle("Dashboard");
        FetchAllFavoritesFile();
        FetchAllHistoryFiles();
        // eslint-disable-next-line
    }, []);

    function FetchAllFavoritesFile() {
        SetIsFavLoading(true);
        const axiosInstance = getAxiosInstance(props.token, false, props?.history);
        axiosInstance
        .get("/FetchCaseAnalysisFavorites", { timeout: 180000 })
        .then((res) => {
            let favorites =[];
            // let previousFavorites=[]
            if(res?.data?.length>0){
                res?.data.forEach(element => {
                    if(favorites.filter(x=>x.module_id === 6 && x.file_name === element?.case_name).length === 0){
                        favorites.push({file_name:element?.case_name,
                            favorite_id:element?.json_favorite_id,
                            batch_id: null,
                            datereccreate:element?.datereccreate ,
                            document_type: element?.document_type,
                            engine_id: 2,
                            is_batch:0,
                            is_hand_written: "0",
                            module_display_name:  "Case Analysis",
                            module_id: 6})
                    }
                });

            SetCaseAnalysisFavorites(favorites);
            // SetFavFiles(favorites)
            // if(favoriteFiles.filter(x=>x.case_name === ))
            }
        });
        axiosInstance
            .get("/FetchAllFavoritesFile")
            .then(async (response) => {
                if (response.data.error_Msg) {
                    SetFavFiles([]);
                } else {
                    SetFavFiles(response.data.result);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                let status = error.response ? error.response.status : 200;
                if (status === 401 || status === 422) {
                    props.removeToken();
                }
            })
            .finally(() => {
                SetIsFavLoading(false);
            });
    }


    function FetchAllHistoryFiles() {
        SetIsHistoryLoading(true);
        const axiosInstance = getAxiosInstance(props.token, false, props?.history);
        axiosInstance
            .get("/GetHistoryData")
            .then(async (response) => {
                // let endTime = (new Date().getTime());
                if (response.data.error_Msg) {
                    SetHistoryFiles([]);
                } else {
                    const sortedItems = [...response.data.result].sort(
                        (a, b) =>
                            new Date(b.daterecupdate) -
                            new Date(a.daterecupdate)
                    );
                    SetHistoryFiles(sortedItems);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                let status = error.response ? error.response.status : 200;
                if (status === 401 || status === 422) {
                    props.removeToken();
                }
            })
            .finally(() => {
                SetIsHistoryLoading(false);
            });
    }

    const handleItemClick = (moduleid, filename, from) => {
        setModule(moduleid);
    };
    const ClosePopUp = () => {
        setOpenSharePopUp(false);
    };

    const nav = useNavigate();
    const RedirectToWorkspace = (data) => {
        nav("/Workspaces", {
            state: {
                workspace_id: data.id,
                workspace_name: data.name,
                data: data,
                isSharedWorkspace: data.users.length > 1 ? true : false,
            },
        });
    };

    const RedirectToWorkspaceList = (activeTab) => {
        nav("/WorkspaceList", { state: { activeTab: activeTab } });
    };

    return module === 1 ? (
        <Link to="/Review"></Link> && (
            <ReviewComponent from="favorite"></ReviewComponent>
        )
    ) : (
        <Fragment>
            {openSharePopUp && (
                <ShareWorkSpacePopUp
                    closepopup={ClosePopUp}
                    title={"Share Workspace"}
                    workSpaceDisabled={true}
                    buttonName={"Share"}
                />
            )}
            <div style={{ height: "100vh", overflow: "auto" }}>
                <div style={{ marginBottom: "20%" }}>
                    <div className="rytpaneltoppart">
                        <h2>DASHBOARD</h2>
                        <div className="reviewdescriptionsec"></div>
                    </div>
                    <div className="dashboard_body_wrapper">
                        {store.getState().login.logInState.usertypeid !==
                        "4" ? (
                            ""
                        ) : (
                            <>
                                <div className="dashboard_row_1 usage_info">
                                    <h3>USAGE</h3>
                                    <div className="wrapper">
                                        <UsageComponent />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="dashboard_body_wrapper">
                        <div className="dashboard_row_1">
                            <h3>Your recent favorites</h3>
                            <ul className="ul__cards">
                                {Object.keys(moduleTypes.Modules).map(
                                    (key, i) => (
                                        
                                        <DashBoardCards
                                            key={i}
                                            title={
                                                moduleTypes.Modules[key]
                                                    .moduleName
                                            }
                                            text="text"
                                            module_id={
                                                moduleTypes.Modules[key]
                                                    .moduleId
                                            }
                                            FilesInfo={[...favoriteFiles,...caseAnalysisFavorites].filter(
                                                (x) =>
                                                    x.module_id ===
                                                    moduleTypes.Modules[key]
                                                        .moduleId
                                            )}
                                            handleItemClick={handleItemClick}
                                            source="favorites"
                                            isLoading={isFavoritesLoading}
                                        ></DashBoardCards>
                                    )
                                )}
                            </ul>
                            {favoriteFiles.length <= 0 &&
                                !isFavoritesLoading && (
                                    <NoContentText
                                        title="No Favorites Yet"
                                        description="You haven't added any favorites yet. Start by favoriting the files you're currently working on to see them here."
                                    ></NoContentText>
                                )}
                        </div>

                        <div className="dashboard_row_2">
                            <h3>Pickup from last time</h3>
                            <ul className="ul__cards">
                                {Object.keys(moduleTypes.Modules).map(
                                    (key, i) => (
                                        <DashBoardCards
                                            key={i}
                                            title={
                                                moduleTypes.Modules[key]
                                                    .moduleName
                                            }
                                            text="text"
                                            module_id={
                                                moduleTypes.Modules[key]
                                                    .moduleId
                                            }
                                            FilesInfo={
                                                historyFiles &&
                                                historyFiles.filter(
                                                    (x) =>
                                                        x.module_id ===
                                                        moduleTypes.Modules[key]
                                                            .moduleId
                                                )
                                            }
                                            handleItemClick={handleItemClick}
                                            source="history"
                                            isLoading={isHistoryFilesLoading}
                                        ></DashBoardCards>
                                    )
                                )}
                            </ul>

                        </div>
                        {store.getState().login.logInState.usertypeid !==
                        "4" ? (
                            <div className="dashboard_row_2">
                                <h3>Your Workspaces</h3>
                                <ul className="ul__cards">
                                    {Object.keys(moduleTypes.Modules).map(
                                        (key, i) =>
                                            i === 0 && (
                                                <li className="li__cards__item">
                                                    <div className="div__card">
                                                        <div className="div__card__content">
                                                            <div className="div__card__title review-title">
                                                                <span className="title-width">
                                                                    {" "}
                                                                    YOURS
                                                                    <div
                                                                        className={`bg-grey title-width-mob`}
                                                                    >
                                                                        Most
                                                                        Recent
                                                                    </div>
                                                                </span>
                                                                <span>
                                                                    {workSpaces &&
                                                                        workSpaces.length >
                                                                            3 && (
                                                                            <div
                                                                                className="text-link "
                                                                                onClick={() =>
                                                                                    RedirectToWorkspaceList(
                                                                                        0
                                                                                    )
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                see
                                                                                all{" "}
                                                                            </div>
                                                                        )}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <ul>
                                                                    {workSpaces
                                                                        .slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            (
                                                                                x
                                                                            ) => (
                                                                                <li>
                                                                                    <div
                                                                                        className="text-link "
                                                                                        onClick={() =>
                                                                                            RedirectToWorkspace(
                                                                                                x,
                                                                                                0
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            x.name
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>
                                <ul className="ul__cards">
                                    {Object.keys(moduleTypes.Modules).map(
                                        (key, i) =>
                                            i === 0 && (
                                                <li className="li__cards__item">
                                                    <div className="div__card">
                                                        <div className="div__card__content">
                                                            <div className="div__card__title  review-title">
                                                                {/* <span> {moduleTypes.Modules[key].moduleName}</span> */}

                                                                <span className="title-width">
                                                                    {" "}
                                                                    Shared With
                                                                    You
                                                                    <div
                                                                        className={`bg-grey title-width-mob`}
                                                                    >
                                                                        Most
                                                                        Recent
                                                                    </div>
                                                                </span>
                                                                <span>
                                                                    {sharedWorkSpaces &&
                                                                        sharedWorkSpaces.length >
                                                                            3 && (
                                                                            <div
                                                                                className="text-link "
                                                                                onClick={() =>
                                                                                    RedirectToWorkspaceList(
                                                                                        1
                                                                                    )
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                see
                                                                                all{" "}
                                                                            </div>
                                                                        )}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <ul>
                                                                    {sharedWorkSpaces
                                                                        .slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            (
                                                                                x
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        x.favorite_id
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className="text-link "
                                                                                        onClick={() =>
                                                                                            RedirectToWorkspace(
                                                                                                x,
                                                                                                1
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            x.name
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>
                                <ul className="ul__cards">
                                    {Object.keys(moduleTypes.Modules).map(
                                        (key, i) =>
                                            i === 0 && (
                                                <li className="li__cards__item">
                                                    <div className="div__card">
                                                        <div className="div__card__content">
                                                            <div className="div__card__title">
                                                                <span
                                                                    className="title-width  review-title"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    Your
                                                                    Favorites
                                                                    <div
                                                                        className={`bg-grey title-width-mob`}
                                                                    >
                                                                        A-Z
                                                                    </div>
                                                                </span>
                                                                <span>
                                                                    {favoritedWorkSpaces &&
                                                                        favoritedWorkSpaces.length >
                                                                            3 && (
                                                                            <div
                                                                                className="text-link "
                                                                                onClick={() =>
                                                                                    RedirectToWorkspaceList(
                                                                                        2
                                                                                    )
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                see
                                                                                all{" "}
                                                                            </div>
                                                                        )}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <ul>
                                                                    {favoritedWorkSpaces
                                                                        .slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            (
                                                                                x
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        x.favorite_id
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className="text-link "
                                                                                        onClick={() =>
                                                                                            RedirectToWorkspace(
                                                                                                x,
                                                                                                2
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            x.name
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>

                                {workSpaces.length <= 0 &&
                                    !isFavoritesLoading && (
                                        <NoContentText
                                            title="No Favorites Yet"
                                            description="You haven't added any favorites yet. Start by favoriting the files you're currently working on to see them here."
                                        ></NoContentText>
                                    )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
