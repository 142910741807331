import { createContext, useContext } from "react";
import { Manager } from "socket.io-client";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { Createaccesslog } from "../Components/SupportiveComponents/AxiosRequest";
import { getCurrentTime } from "../Components/SupportiveComponents/CustomAlerts/ToastAlert";
import {
  updateChatWithDocState,
  updateChatWithInternalDocsState,
  updateReviewState,
  updateSummaryState,
} from "../redux/actions";
import store from "../redux/store";
import { SIOEVENTS } from "../Enums/EventNames";
/*
const LAWBOTICA_ENGINE_HELP_QUESTION_PROMPT = 'please return list of questions in dictionary format with question and values  which can be prompted to the model when reviewing the document?   Extract house pricing data from the following text. \n Desired format: \n ```json { questions:{ "question": "what is this document about?"} }``` ';
const OPENAI_ENGINE_HELP_QUESTION_PROMPT =
    "please return  series of questions in Markdown JSON format which can be prompted to the model";
*/
const token = localStorage.getItem("token");

const manager = new Manager(window.location.origin);
//using localhost url when we are in debug mode i.e running react on a seperate port

//Main Namespace
export const socket = manager.socket("/", { auth: { token: token } });

//admin Namespace
export const adminSocket = manager.socket("/workspace", {
    auth: { token: token },
});

//subscribing connection event from server
socket.on("connection", () => {
    console.log(`websockets `, socket);
    if (token) socket.emit("auth", { token: token });

    //emit message event to server
});
socket.on("auth", (data) => {
    if (data["authenticated"]) {
        let accessToken = localStorage.getItem("token");
        socket.emit("joinUserRoom", accessToken);
    } else {
        console.log("Inside remove Token");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("organization");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("usertypeid");
        localStorage.removeItem("userType");
        localStorage.removeItem("org_id");

        // Broadcast logout event to other tabs
        localStorage.setItem("logout", Date.now().toString());
    }
});

socket.on("message", (obj) => {
    console.log(`a test message emitted to detect server connectivity`, obj);
});

socket.on(SIOEVENTS.SUMMARIZE.RESPONSE, (data) => {
    let currentState = store.getState().summary.summarizeState;
    if (data?.error) {
        store.dispatch(
            updateSummaryState({
                ...currentState,
                isLoading: false,
            })
        );
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
    if (
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        let end_time = new Date().getTime();
        let startTime = currentState?.reqStartTime;
        let processtimeinsec = (
            (end_time - (data?.workspace_id ? data.reqStartTime : startTime)) /
            1000
        ).toFixed(2);
        if (data?.error_Msg) {
            store.dispatch(
                updateSummaryState({
                    ...currentState,
                    isLoading: false,
                })
            );
        } else {
            let currentState = store.getState().summary.summarizeState;
            if (data?.Concise_Summary || data?.Intermediate_Summary) {
                store.dispatch(
                    updateSummaryState({
                        ...currentState,
                        summaryResult: data,
                        isUploadFileOpen: false,
                        isLoading: false,
                        prevSelectedFile: currentState?.selectedFile,
                        summaryTimer: processtimeinsec + "s",
                        summaryTime: getCurrentTime(),
                        index_path: data?.index_path,
                        isIndexCreated: data?.index_path ? true : false,
                    })
                );
              
                const formData = new FormData();

                formData.append("file", currentState?.selectedFile.file);
                formData.append("document_type", currentState?.selectedDocType);
                formData.append("is_hand_written", currentState?.isHandWritten);
                formData.append("module_id", 2);
                formData.append("engine_id", currentState?.selectedEngine);
                const formDataForaccesslogs = formData;
                formDataForaccesslogs.append(
                    "doc_name",
                    currentState?.selectedFile.file.name
                );
                Createaccesslog(formDataForaccesslogs, processtimeinsec);
            }

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;
                if (data?.q) {
                    let obj = {
                        // question: response.q.msg,
                        question: data?.q?.msg?.short,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                        review_by_name: currentState?.isSharedWorkspace
                            ? data?.review_by_name
                            : "",
                    };
                    currentState = store.getState().summary.summarizeState;
                    store.dispatch(
                        updateSummaryState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                }
            }
        }
    }
});

//part of multi module processing implementation
socket.on(SIOEVENTS.REPO_CHAT.RESPONSE, (data) => {
    console.log(
        `bind the event repo_chat_response listeners called in Middle Ware`,
        store.getState(),
        data
    );

    const currentState =
        store.getState().chatWithInternalDocs.chatWithInternalDocsState;
    let now = new Date().getTime();
    let startTime = currentState.reqStartTime;
    let processtimeinsec = ((now - startTime) / 1000).toFixed(2);
    let para = "";
    if (Array.isArray(data.msg)) {
        data.msg.forEach((element) => {
            para += element + " \n ";
        });
    } else {
        para = data.msg;
    }
    let links = "";
    let metas = data?.metas?.slice(0, 2);
    metas?.map(
        (link) => (links += link.link + " - Pages " + link.pages + " \n ")
    );
    metas?.forEach((element) => {
        const lastDotIndex = element.link.lastIndexOf("/");
        if (lastDotIndex !== -1) {
            element.label = element.link.substring(lastDotIndex + 1);
        } else {
            element.label = element.link;
        }
    });
    let obj = {
        response: para + " \n Sources \n " + links,
        question: currentState.selectedPrompt.id,
        message: para,
        sources: metas,
        time: new Date().toLocaleString(),
        timetaken: processtimeinsec + "s",
    };
    const { Chats } = currentState;
    // Log current state (for example)
    console.log("Current State:", currentState, store);
    store.dispatch(
        updateChatWithInternalDocsState({
            ...currentState,
            Chats: [...Chats, obj],
            isLoading: false,
            inputValue: "",
        })
    );
});

//Chat WITH DOCS
socket.on(SIOEVENTS.CHAT_WITH_DOCS.RESPONSE, (data) => {
    if (data?.error) {
        let currentState = store.getState().chatWithDoc.chatWithDocState;
        store.dispatch(
            updateChatWithDocState({
                ...currentState,
                isLoading: false,
            })
        );
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
    let currentState = store.getState().chatWithDoc.chatWithDocState;

    if (
        data?.loader_off ||
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        const userState = store.getState().login.logInState;
        console.log(userState);
        if (data.loader_off) {
            let docs = [];
            if (data?.index_list?.length > 0) {
                data?.index_list?.length > 0 &&
                    data.index_list.forEach((e, i) => {
                        currentState?.docsContent?.length > 0 &&
                            currentState.docsContent.forEach(
                                (element, index) => {
                                    if (
                                        element.fileName ===
                                        e.name.substring(
                                            e.name.indexOf("_") + 1
                                        )
                                    ) {
                                        element.index_path = e.index_path;
                                        docs.push(element);
                                    }
                                }
                            );
                    });
            } else {
                docs = currentState.docsContent;
            }
            currentState = store.getState().chatWithDoc.chatWithDocState;
            store.dispatch(
                updateChatWithDocState({
                    ...currentState,
                    isLoading: false,
                    isIndexCreated: true,
                    index_list: data?.index_list,
                    docsContent: docs,
                })
            );
            let message =
                currentState?.batchFiles?.length > 1
                    ? `Your batch of files, <b>${currentState?.fileName}</b> is now ready for chat.`
                    : `Your document, <b>${currentState?.fileName}</b> is now ready for chat.`;
            let msg = message;
            let msgObj = {
                id: uuidv4(),
                message: message,
                msg: msg,
                fromuserid: userState?.user_id,
                touserid: userState?.user_id,
                timeread: null,
                displaystatus: "a",
                created_by: "self",
                created_at: new Date(),
                updated_by: null,
                updated_at: null,
            };
            socket.emit("in_app_notification", msgObj);
            return;
        }

        try {
            // console.log("Response:", data);

            if (data === undefined) {
                currentState = store.getState().chatWithDoc.chatWithDocState;
                store.dispatch(
                    updateChatWithDocState({
                        ...currentState,
                        isLoading: false,
                        isUploadFileOpen: true,
                        selectedFile: "",
                        fileName: "",
                        selectedDocType: "",
                        showMsgBoxAfterUpload: false,
                    })
                );
            } else {
                let now = new Date().getTime();
                let processtimeinsec = (
                    (now - data?.reqStartTime) /
                    1000
                ).toFixed(2);
                let formDataForAccessLog = new FormData();
                formDataForAccessLog.append("module_id", 3);
                formDataForAccessLog.append(
                    "engine_id",
                    currentState.selectedEngine
                );
                formDataForAccessLog.append(
                    "document_type",
                    currentState.selectedDocType
                );
                formDataForAccessLog.append(
                    "is_hand_written",
                    currentState.isHandWritten
                );
                formDataForAccessLog.append("doc_name", currentState.fileName);
                Createaccesslog(formDataForAccessLog, processtimeinsec);
                let res = "";

                if (Array.isArray(data.msg) && data.msg.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                let obj = {
                    question: currentState.selectedPrompt.id,
                    response: res,
                    time: getCurrentTime(),
                    timetaken: processtimeinsec + "s",
                    qid: data?.qid,
                    fname: data["fname"],
                    tresp: data?.tresp,
                    embeddings: data?.embeddings,
                };

                if (currentState.workspace_id > 0) {
                    obj.question = data?.q?.msg?.short;
                    obj.fname = data?.index_name;
                    obj.chat_user_name = currentState?.isSharedWorkspace
                        ? data?.chat_user_name
                        : "";
                }
                const { Chats } = currentState;
                Chats[obj.qid] = Chats.hasOwnProperty(obj.qid)
                    ? [...Chats[obj.qid], obj]
                    : [obj];

                let userId = parseInt(localStorage.getItem("user_id"));
                if (data?.workspace_id && data?.workspace_id > 0) {
                    if (
                        data?.user_id === userId ||
                        (data?.user_id !== userId &&
                            Chats[
                                Object.keys(Chats)[
                                    Object.keys(Chats)?.length - 1
                                ]
                            ][0]?.tresp ===
                                Chats[
                                    Object.keys(Chats)[
                                        Object.keys(Chats)?.length - 1
                                    ]
                                ]?.length)
                    ) {
                        currentState =
                            store.getState().chatWithDoc.chatWithDocState;
                        store.dispatch(
                            updateChatWithDocState({
                                ...currentState,
                                Chats: { ...Chats },
                                isUploadFileOpen: false,
                                isLoading: false,
                                inputValue: "",
                                showMsgBoxAfterUpload: false,
                            })
                        );
                    }
                } else {
                    currentState =
                        store.getState().chatWithDoc.chatWithDocState;
                    store.dispatch(
                        updateChatWithDocState({
                            ...currentState,
                            Chats: { ...Chats },
                            isUploadFileOpen: false,
                            isLoading: false,
                            inputValue: "",
                            showMsgBoxAfterUpload: false,
                        })
                    );
                }
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
            currentState = store.getState().chatWithDoc.chatWithDocState;
            store.dispatch(
                updateChatWithDocState({
                    ...currentState,
                    isUploadFileOpen: true,
                    isLoading: false,
                    selectedFile: "",
                    fileName: "",
                    selectedDocType: "",
                    showMsgBoxAfterUpload: false,
                })
            );
        }
    }
});

//Review Component
socket.on(SIOEVENTS.REVIEW.RESPONSE, (data) => {
    if (data?.cancel) {
        let currentState = store.getState().review.reviewState;
        store.dispatch(
            updateReviewState({
                ...currentState,
                isLoading: false,
                isSocketRequestProgress: false,
            })
        );
    }
    if (data?.error) {
        let currentState = store.getState().review.reviewState;
        store.dispatch(
            updateReviewState({
                ...currentState,
                isLoading: false,
            })
        );
        Swal.fire({
            text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
    console.log(`bind the event review chat response received `, data);
    let currentState = store.getState().review.reviewState;
    if (
        data?.workspace_id === null ||
        (currentState.workspace_id === data?.workspace_id &&
            currentState.workspace_item_id === data?.workspace_item_id)
    ) {
        if (data === undefined) {
            return;
        }
        if (data?.index_path) {
            store.dispatch(
                updateReviewState({
                    ...currentState,
                    index_path: data?.index_path,
                    isIndexCreated: true,
                })
            );
        }
        currentState = store.getState().review.reviewState;
        /**
         * handling response
         */

        try {
            let now = new Date().getTime();
            let processtimeinsec = (
                (now - currentState.reqStartTime) /
                1000
            ).toFixed(2);

            let formDataForAccessLog = new FormData();
            formDataForAccessLog.append("module_id", 1);
            formDataForAccessLog.append(
                "engine_id",
                currentState.selectedEngine
            );
            formDataForAccessLog.append(
                "document_type",
                currentState.selectedDocType
            );
            formDataForAccessLog.append(
                "is_hand_written",
                currentState.isHandWritten
            );
            formDataForAccessLog.append(
                "doc_name",
                currentState.selectedFile.name
            );
            Createaccesslog(formDataForAccessLog, processtimeinsec);

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;

                if (data?.q) {
                    let obj = {
                        question: currentState.selectedPrompt.id,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                    };
                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                } else {
                    let obj = {};

                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            ...obj,
                            reviewTime: getCurrentTime(),
                            reviewResult: res,
                            isUploadFileOpen: false,
                            isLoading: false,
                            prevSelectedFile: currentState.selectedFile,
                            reviewTimer: processtimeinsec + "s",
                            isIndexCreated: true,
                        })
                    );
                }
            }
        } catch (error) {
            console.log("ErrorHandleResponse:", error);
        }
        if (
            data.q?.workspace_id > 0 &&
            data.q?.workspace_id === currentState.workspace_id &&
            data.q?.workspace_item_id === currentState.workspace_item_id
        ) {
            let now = new Date().getTime();
            let processtimeinsec = ((now - data.reqStartTime) / 1000).toFixed(
                2
            );

            if (data) {
                let res = "";
                if (Array.isArray(data.msg) && data.msg?.length > 1) {
                    data.msg.forEach((item) => {
                        res = res + item + "\n";
                    });
                } else {
                    res = data.msg;
                }

                const { CannedQuestionsList } = currentState;

                if (data.q) {
                    let obj = {
                        question: data.q.msg.short,
                        response: res,
                        time: getCurrentTime(),
                        timetaken: processtimeinsec + "s",
                        embeddings: data?.embeddings,
                        review_by_name: currentState?.isSharedWorkspace
                            ? data?.review_by_name
                            : "",
                    };
                    currentState = store.getState().review.reviewState;
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            isUploadFileOpen: false,
                            CannedQuestionsList: [...CannedQuestionsList, obj],
                            isLoading: false,
                            isIndexCreated: true,
                        })
                    );
                }
            }
        }
    }
});

socket.on("summary_chat_questions", (data) => {
    if (data?.msg) {
        const markdownText = data?.msg;

        // Extract the JSON part from the markdown text
        const jsonText = markdownText.match(/```json([\s\S]*?)```/)[1].trim();

        // Parse the JSON text to a JavaScript object
        const jsonObject = JSON.parse(jsonText);
        let currentState = store.getState().summary.summarizeState;
        store.dispatch(
            updateSummaryState({
                ...currentState,
                doc_help_questions: jsonObject?.questions,
            })
        );
    }
});

socket.on("review_doc_chat_questions", (data) => {
    if (data?.msg) {
        const markdownText = data?.msg;

        // Extract the JSON part from the markdown text
        const jsonText = markdownText.match(/```json([\s\S]*?)```/)[1].trim();

        // Parse the JSON text to a JavaScript object
        const jsonObject = JSON.parse(jsonText);
        let currentState = store.getState().review.reviewState;
        if (typeof jsonObject === 'object') {
                    store.dispatch(
                        updateReviewState({
                            ...currentState,
                            doc_help_questions: jsonObject?.questions,
                        })
                    );            
        } else {
                store.dispatch(
                    updateReviewState({
                        ...currentState,
                        doc_help_questions:
                            jsonObject,
                    })
                );            
        }


    }
});

export const socketContext = createContext(socket);
export default function useSocket() {
  return useContext(socketContext);
}
