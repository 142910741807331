import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import userTypes from "../../dropDowns/userTypes.json";
import store from "../../redux/store";
import ComingSoon from "../SupportiveComponents/ComingSoon";
import ErrorBoundary from "../SupportiveComponents/CustomErrorBoundary";
import InternalServerError from "../SupportiveComponents/ErrorPages/InternalServerError";
import NotFoundError from "../SupportiveComponents/ErrorPages/NotFoundError";
import ErrorPage from "../SupportiveComponents/ErrorPages/ServiceUnavailableError";
import UnknownPathHandler from "../SupportiveComponents/ErrorPages/UnknownPage";
import Feedback from "../SupportiveComponents/FeedBack/FeedBackWidget";
import { FeedBackIcon } from "../SupportiveComponents/FontAwesomeComponents/HelperIcons";
import { isMobile } from "../SupportiveComponents/PureHelpers";
import BasicTabs from "./Admin/AdminOperations";
import KavayahAdminOperations from "./Admin/KavayahAdminOperations";
import ResponseLogs from "./Admin/ResponseLogs";
import CaseAnalysisComponent from "./CaseAnalysisComponent";
import ChatWithDocComponent from "./ChatWithDocComponent";
import ChatWithInternalDocComponent from "./ChatWithInternalDocComponent";
import DashBoard from "./DashBoard";
import { AddUserForm } from "./Registration/Forms";
import { UserDeletion } from "./Registration/Org/UserDeletion";
import ReviewComponent from "./ReviewComponent";
import SummarizeComponent from "./SummarizeComponent";
import { HelpTrialPage } from "./Trial/HelpTrialPageComponent";
import { BoxesGroup } from "./Workspaces/DraggableBoxex";
import WorkspaceChat from "./Workspaces/WorkspaceChat";
import WorkSpaceComponent from "./Workspaces/WorkSpaceComponent";
import WorkspaceTabs from "./Workspaces/WorkspaceOperations";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx"


export default function RightPane(props) {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
  const myReduxState = useSelector((state) => state.login.logInState);
  const location = useLocation();
  const history = useNavigate();
  const[modules, setModules] = useState([])
  const token = localStorage.getItem("token")
  const org_id = localStorage.getItem("org_id")
  
  useEffect(() => {
    if (props.isLeftPanelOpen !== isSidePanelOpen) {
      setIsSidePanelOpen(props.isLeftPanelOpen);
      console.log("Page:", props.isLeftPanelOpen);
    }
  }, [props.isLeftPanelOpen, isSidePanelOpen]);

  const handleUnknownPath = () => {
    // store.dispatch(
    //     updateErrorState({
    //         hasError: true,
    //     })
    // );
    history("/not-found");
    console.log("User navigated to an unknown path");
    // Additional logic you want to execute
  };
  useEffect(()=>{
    const axiosInstance = getAxiosInstance(token,false, history);
  
  axiosInstance.get(`/org/modules?org_id=${org_id}`, {timeout: 300000,}).then((res)=>{
  setModules(res?.data?.modules)
  })
  },[org_id, history, token])
  return (
    <div
      id="rightpanel"
      className={isSidePanelOpen ? "leftpanelOpen" : "leftpanelClose"}
    >
 <FeedBackIcon onClick={() => {
      setOpenFeedbackForm(!openFeedbackForm);
    }}/>
      {openFeedbackForm && <Feedback  token={props.token}
                        history={history} closepopup={()=>setOpenFeedbackForm(!openFeedbackForm)}/>}
      <main>
        <Fragment>
          <ErrorBoundary key={location.pathname}>
            <Routes>
              {myReduxState.userType ===
              userTypes.UserTypes.KavayahAdmin.Name ? (
                <>
                  <Route
                    path="/ksadmin/dashboard"
                    element={
                      <KavayahAdminOperations
                        token={props.token}
                        history={history}
                      />
                    }
                  />
                  <Route
                    path="/ksadmin/useroperations"
                    element={
                      <BasicTabs token={props.token} history={history} />
                    }
                  />
                  <Route
                    path="/ksadmin/response_logs"
                    element={
                      <ResponseLogs token={props.token} history={history} />
                    }
                  />
                </>
              ) : (
                <></>
              )}
              {myReduxState.userType ===
              userTypes.UserTypes.OrganizationAdmin.Name ? (
                <>
                  <Route
                    path="/adduser"
                    element={
                      <AddUserForm
                        token={props.token}
                        userType={props.userType}
                        history={history}
                      />
                    }
                  />

                  <Route
                    path="/removeuser"
                    element={
                      <UserDeletion
                        token={props.token}
                        userType={props.userType}
                      />
                    }
                  />
                  <Route
                    path="/ksadmin/response_logs"
                    element={
                      <ResponseLogs token={props.token} history={history} />
                    }
                  />
                </>
              ) : (
                <></>
              )}

              <Route
                path="/"
                element={
                  <DashBoard
                    token={props.token}
                    removeToken={props.removeToken}
                    history={history}
                  />
                }
              />
              <Route path="/not-found" element={<NotFoundError />} />
              <Route
                path="/DashBoard"
                element={
                  <DashBoard
                    token={props.token}
                    removeToken={props.removeToken}
                    history={history}
                  />
                }
              />
              <Route
                path={modules.filter(x=>x.module_id === 2).length > 0?"/Summarize":""}
                element={
                  <SummarizeComponent
                    token={props.token}
                    removeToken={props.removeToken}
                    history={history}
                  />
                }
              />
              <Route
                path={modules.filter(x=>x.module_id === 1).length > 0?"/Review":""}
                element={
                  <ReviewComponent
                    token={props.token}
                    removeToken={props.removeToken}
                    location={location}
                    history={history}
                  />
                }
              />
              <Route
                path={modules.filter(x=>x.module_id === 3).length > 0?"/ChatWithDocument":""}
                element={
                  <ChatWithDocComponent
                    token={props.token}
                    removeToken={props.removeToken}
                    history={history}
                  />
                }
              />
              {store.getState().login.logInState.usertypeid !== "4" && (
                <Route
                  path={modules.filter(x=>x.module_id === 4).length > 0?"/RepositoryChat":""}
                  element={
                    <ChatWithInternalDocComponent
                      token={props.token}
                      removeToken={props.removeToken}
                      history={history}
                    />
                  }
                />
              )}
              <Route
                path={modules.filter(x=>x.module_id === 6).length > 0?"/Case_Analysis":""}
                element={
                  <CaseAnalysisComponent
                    token={props.token}
                    removeToken={props.removeToken}
                    history={history}
                  />
                }
              />
              <Route path="Repository/ComingSoon" element={<ComingSoon />} />
              <Route path="Workspaces/ComingSoon" element={<ComingSoon />} />

              <Route
                path="/error"
                element={<ErrorPage location={location} />}
              />

              <Route
                path="/internal-server-error"
                element={<InternalServerError location={location} />}
              />
              <Route path="/trial-help" element={<HelpTrialPage />} />
              <Route
                path="*"
                element={
                  <UnknownPathHandler onUnknownPath={handleUnknownPath} />
                }
              />
              {store.getState().login.logInState.usertypeid !== "4" && (
                <>
                  <Route
                    path="/WorkSpaces"
                    element={
                      <WorkSpaceComponent
                        token={props.token}
                        removeToken={props.removeToken}
                        nav={history}
                      />
                    }
                  />
                  <Route
                    path="/WorkSpace-Chat"
                    element={
                      isMobile() ? (
                        <WorkspaceChat
                          token={props.token}
                          removeToken={props.removeToken}
                          nav={history}
                        />
                      ) : (
                        <WorkSpaceComponent
                          token={props.token}
                          removeToken={props.removeToken}
                          nav={history}
                        />
                      )
                    }
                  />
                  <Route
                    path="/WorkSpaces/:workspace_id"
                    element={
                      <WorkSpaceComponent
                        token={props.token}
                        removeToken={props.removeToken}
                        nav={history}
                      />
                    }
                  />
                  <Route
                    path="/WorkSpaceList"
                    element={
                      <WorkspaceTabs
                        token={props.token}
                        removeToken={props.removeToken}
                        nav={history}
                      />
                    }
                  />

                  <Route
                    path="/TableSwapper"
                    element={
                      <BoxesGroup
                        token={props.token}
                        removeToken={props.removeToken}
                      />
                    }
                  />
                </>
              )}
            </Routes>
          </ErrorBoundary>
        </Fragment>
      </main>
    </div>
  );
}
