import {
    ReadMoreCollapseOpenAi,
    //ReadMoreCollapse
} from "../ReadMoreCollapsible";
import { CustomRenderer } from "../CustomDataRendering/CustomDataRenderer";
import {
  CustomContentOperationLinks,
  CustomgroupedContentOperationLinks,
} from "../CustomContentLinksComponent";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { isMobile } from "../PureHelpers";
import store from "../../../redux/store";
export const TitleOfComponent = ({
  title,
  buttonTitle,
  description,
  isExpanded,
  exapandfncallback,
  fileName,
  CannedQuestionsList,
  componenttitle,
  setStateCallback,
  disableAddToWorkspace,
  loadinghtml,
  workspace_name,
  workspace_id,
  hideAddToWorkspace,
  workspace_created_by,
  users,
  AddToAnotherWorkspace
}) => {
  const nav = useNavigate();
  const RedirectToWorkspaceItems = () => {
    nav("/workspaces", {
      state: {
        workspace_id: workspace_id,
        workspace_name: workspace_name,
        data: {
          id: workspace_id,
          name: workspace_name,
          users: users,
          created_by: workspace_created_by,
        },
      },
    });
  };
  return (
      <div className={`sglereviewsec titlesec`} style={{ zIndex: 990 }}>
          <div className="cannedtitlesec ">
              {store.getState().login.logInState.usertypeid !== "4" ? (
                  !hideAddToWorkspace &&
                  (disableAddToWorkspace ? (
                      <div className="d-flex">
                          Document auto saved in
                          <div
                              className="text-link dual-space"
                              onClick={() => RedirectToWorkspaceItems()}
                          >
                              {workspace_name}
                          </div>
                          Workspace{" "}
                          <span
                              className="plus-icon"
                              title="Add to another workspace"
                              onClick={AddToAnotherWorkspace}
                          ></span>
                      </div>
                  ) : (
                      <div
                          className={`newDoc btn-review plus-square-icon`}
                          onClick={setStateCallback}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Add to workspace"}

                      >
                          {buttonTitle}

                      </div>
                  ))
              ) : (
                  <div className=" btn-review  "></div>
              )}
              {loadinghtml ? loadinghtml : <></>}
            
              <CustomgroupedContentOperationLinks
                  isExpandeds={isExpanded}
                  expandCallback={exapandfncallback}
                  CannedQuestionsList={CannedQuestionsList}
                  fileName={fileName}
                  componenttitle={componenttitle}
              />
          </div>
      </div>
  );
};

export const ContentReviewSection = ({
  title,
  timer,
  response,
  filename,
  CannedQuestionsList,
  question,
  componenttitle,
  isExpanded,
  reviewTime,
  review_by_name,
  isSummary,
  engine_id,
}) => {
  const [isExpandeds, setIsExpanded] = useState(isExpanded);
  const [userDetials] = useState(store.getState().login.logInState);

  const [engine_id_state, setEngineId] = useState(+engine_id);
  useEffect(() => {
    setEngineId(+engine_id);
  }, [engine_id]);
  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [isExpanded]);

  return (
      <div className="sglereviewsec">
          <div className="cannedtitlesec">
              <div className="namenmetadiv">
                  <h4
                      className="m-p-c"
                      onClick={(e) => {
                          e.stopPropagation();
                          setIsExpanded(!isExpandeds);
                      }}
                  >
                      {title}
                  </h4>
                  <div className="metadata">
                      {reviewTime}
                      {review_by_name?.length > 0
                          ? ` | ${
                                review_by_name === userDetials.userFullName
                                    ? "You"
                                    : review_by_name
                            }`
                          : ""}{" "}
                      | {timer}
                  </div>
              </div>

              <CustomContentOperationLinks
                  content={
                      isSummary
                          ? "\n\nConcise Summary\n\n" +
                            response?.Concise_Summary
                          : //  +
                            // "\n\nIntermediate Summary\n\n" +
                            // response?.Intermediate_Summary
                            response
                  }
                  isSummary={false}
                  component={componenttitle}
                  filename={filename}
                  question={title}
                  isExpandeds={isExpandeds}
                  expandCallback={setIsExpanded}
              />
          </div>
          <div
              className={`reviewcontsec  ${
                  isExpandeds ? "expanded" : "collapse"
              }`}
          >
              <div className="readmoredata">
                  
                  {
                      <ReadMoreCollapseOpenAi
                          text={response}
                          isSummary={isSummary}
                          maxLength={500}
                          isPrompts={
                              CannedQuestionsList?.length !== 0 ? true : null
                          }
                          engine_id={engine_id_state}
                      />
                  }
              </div>
          </div>
      </div>
  );
};

export const ContentReviewSectionComponent = ({
  question,
  time,
  timetaken,
  response,
  fileName,
  componenttitle,
  isExpanded,
  sources,
  created_by_name,
  embeddings,
  engine_id,
}) => {
  const [isExpandeds, setIsExpanded] = useState(isExpanded);
  const [userDetials] = useState(store.getState().login.logInState);
  const [engine_id_state, setEngineId] = useState(+engine_id);
  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    setEngineId(+engine_id);
  }, [engine_id]);
  return (
    <div className={`sglereviewsec`}>
      <div className="cannedtitlesec">
        <div className="namenmetadiv">
          <h4
            className="m-p-c"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpandeds);
            }}
          >
            {question}
          </h4>
          <div className="metadata">
            {time}
            {created_by_name?.length > 0
              ? ` | ${
                  created_by_name === userDetials.userFullName
                    ? "You"
                    : created_by_name
                } `
              : ""}
            | {timetaken}
          </div>
        </div>

        <CustomContentOperationLinks
          content={
            response +
            (sources
              ? `\n\nSources: \n ${sources.map((link, idx) =>
                  decodeURI(link.label)
                )}`
              : "")
          }
          isSummary={false}
          component={componenttitle}
          filename={fileName}
          question={question}
          isExpandeds={isExpandeds}
          expandCallback={setIsExpanded}
        />
      </div>
      <div
        className={`reviewcontsec  ${isExpandeds ? "expanded" : "collapse"}`}
      >
        <div className="readmoredata">
          <div className={`note `}>
            <CustomRenderer
              content={response}
              embeddings={embeddings}
              isExpanded={isExpandeds}
              engine_id={engine_id_state}
            />{" "}
            {sources && (
              <ul>
                <strong>Sources</strong>
                {sources.map((link, i) => (
                  <li key={i}>
                    <a
                      href={link.link}
                      style={{
                        color: "#2eb1e3",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {decodeURI(link.label)}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
