import { Fragment, useCallback, useEffect, useState } from "react";
import '../../../css/workspace.css';
import getAxiosInstance from '../AxiosRequest.jsx';
import { CustomTable } from "../AgGridTemplates/CustomAgGrid.jsx";
import { ToastAlert } from "../CustomAlerts/ToastAlert.jsx";
import LoadingSpinner from "../LoadingSpinner.jsx";
import CreateWorkSpacePopUp from "./CreateWorkSpacePopUp.jsx";
import DocumentExistPopUp from "./DocumentExistPopUp.jsx";
import EmptyWorkspaces from "./EmptyWorkspaces.jsx";



const WorkSpaceListPopUp = ({ closepopup, state, content, document_type, document_name, docsContent,closeWsListpopup,engine_type,
    baseContent, file, handwritten_text_flag, timeTaken, CannedQuestionsList, index_path, module_id, query, ...items }) => {

    const [openCreateNewPopUp, setOpenCreateNewPopUp] = useState(false)
    const [workSpaces, setWorkSpaces] = useState([])
    const [allWorkSpaces, setAllWorkSpaces] = useState([])
    const [selectedWorkSpaces, setSelectedWorkSpaces] = useState([])
    const [token] = useState(localStorage.getItem('token'))
    const [userId] = useState(localStorage.getItem('user_id'))
    const [loading, SetLoading] = useState(false)
    const [isDocExist, SetIsDocExist] = useState(false)
    // const [dataObj, setDataObj] = useState({})
    const [overwriteData, setOverwriteData] = useState([])
    const[existWorkspaces, setExistWorkspaces]=useState([])


    const GetWorksSpaces = useCallback(() => {
        const axiosInstance = getAxiosInstance(token);
        SetLoading(true);
        axiosInstance.get("/workspace/all", { timeout: 180000 }).then((res) => {
            if (res.data.length > 0) {
                const sortedItems = [...res.data].sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                if (parseInt(userId)) {
                    setAllWorkSpaces(sortedItems);
                    setWorkSpaces(
                        sortedItems.filter((x) =>
                            x?.users?.find((x) => x.user_id === parseInt(userId))
                        )
                    );
                }
            }
            SetLoading(false);
        });
    }, [token, userId]);

    useEffect(() => {
        GetWorksSpaces();
    }, [GetWorksSpaces]);

    const getUniqueFileName = (workspaceItems, fileName, module_id) => {
        let newFileName = fileName;
        if (workspaceItems.filter(x => x.module_id === module_id && x.document_name === fileName).length > 0) {
            var names = fileName.split('.');
            for (var i = 1; i > 0; i++) {
                let newName = `${names[0]}__${i}.` + names[1];
                if (workspaceItems.filter(x => x.docs[0]?.docName === newName).length === 0) {
                    newFileName = newName;
                    return newFileName;
                } else {
                    newFileName = newName
                }
            }
        }

        return newFileName;
    };



    const handleSubmit = async () => {
        const axiosInstance = getAxiosInstance(token);
        try {
            SetLoading(true);
            selectedWorkSpaces.forEach((element, index) => {
                var dupName = getUniqueFileName(element.workspace_items, document_name, module_id)
                var msgs = []
                msgs.push({ response: content, embeddings: "", query: query ? query : "", response_build_time: timeTaken, chat_id: items.batchName ? items.batchName : "" })
                CannedQuestionsList.forEach(el => {
                    msgs.push({
                        response: el.response,
                        embeddings: JSON.stringify(el.embeddings),
                        query: el.question,
                        response_build_time: el.timetaken.replace('s', ''),
                        chat_id: items.batchName ? items.batchName : ""
                    })
                });

                let docs = []

                docsContent?.length > 0 && docsContent?.forEach((e, i) => {
                    docs.push({
                        // name: e.fileName,
                        name: module_id === 3 ? e.fileName : dupName,
                        uri: "",
                        content: e.content,
                        handwritten_text_flag: handwritten_text_flag,
                        index_path: e.index_path,
                        mime_type: e.fileType
                    })
                })

                var ws_item = {
                    module_id: module_id,
                    engine_type:engine_type,
                    messages: msgs,
                    documents: docs,
                    document_type: document_type
                }

                let obj = { workspace_id: element.id, ws_item: ws_item };
                let existWorkSpaces=existWorkspaces
                let overwriteWorkSpaces=overwriteData
                if (element.workspace_items.filter((x) => (x.document_type = document_type && x.module_id === module_id
                    && x.document_name === document_name)).length > 0) {
                    // setDataObj(obj);
                    SetLoading(false)
                    SetIsDocExist(true);
                    existWorkSpaces.push(obj)
                    setExistWorkspaces(existWorkSpaces)
                    let data = {
                        workspace_id: element.id,
                        ws_item: ws_item,
                        id: 1,
                        workspace_item_id: element.workspace_items.filter((x) => (x.document_type = document_type
                            && x.docs.filter((y) => y.docName === document_name).length > 0))[0].id,
                    };
                    overwriteWorkSpaces.push(data)
                    setOverwriteData(overwriteWorkSpaces);
                } else {
                    axiosInstance.post("/workspace/item", obj, {
                        headers: { "Content-Type": "application/json" },
                        timeout: 180000,
                    })
                        .then((res) => {
                            if (selectedWorkSpaces.length > 0 && res?.data?.added?.length>0) {
                                let wss = []
                                selectedWorkSpaces.forEach((e, i) => {
                                    if(e?.id===res.data?.added[0]['ws_id']){
                                        e.workspace_item_id = res.data?.added?.length > 0 ? res.data?.added[0]['ws_item_id'] : 0
                                        e.shared_users = res.data?.added?.length > 0 ? res.data?.added[0]['shared_users'] : 0
                                        wss.push(e)
                                    }
                                })
                            }
                            if (res.status === 200 && index === selectedWorkSpaces.length - 1 && existWorkspaces.length === 0 && overwriteData.length === 0) {
                                ToastAlert("added successfully");
                                SetLoading(false);
                                closepopup(selectedWorkSpaces, res.data);
                            }
                        });
                }
            });
            // });
        } catch (exception) {
            SetLoading(false);
        }
    };

    const handleNameCallBack = (name) => {
        var createdWorkspace = selectedWorkSpaces;
        createdWorkspace.push({ name: name.workSpace });
        setSelectedWorkSpaces(createdWorkspace);
    };
    const CloseCreateNewPopUp = () => {
        setOpenCreateNewPopUp(false);
        GetWorksSpaces();
    };
    const CloseWorkspaceListPopUp = (res) => {
        setOpenCreateNewPopUp(false);
        let wss=[]
        if(res?.added?.length===1){
            wss.push({id:res?.added[0]?.ws_id,workspace_item_id:res?.added[0]?.ws_item_id,shared_users:0,name: res?.added[0]?.ws_name})
        }
        closepopup(wss, res);
    };
    const closeDocExistpopup = (data, res) => {
        SetIsDocExist(false);
        closepopup(data, res);
    };
    const handleOpenCreateNew = () => {
        setOpenCreateNewPopUp(true);
    };

    return (
        <Fragment>
            {isDocExist && (
                <DocumentExistPopUp
                    closeDocExistpopup={closeDocExistpopup}
                    document_name={document_name}
                    // obj={dataObj}
                    overwriteData={overwriteData}
                    token={token}
                    selectedWorkSpaces={selectedWorkSpaces}
                    existWorkSpaces={existWorkspaces}
                />
            )}

            {openCreateNewPopUp ?
                <CreateWorkSpacePopUp
                    closepopup={CloseCreateNewPopUp}
                    content={content}
                    closeworkspacelist={CloseWorkspaceListPopUp}
                    baseContent={baseContent}
                    docsContent={docsContent}
                    document_type={document_type}
                    document_name={document_name}
                    mime_type={file.type}
                    timeTaken={timeTaken}
                    CannedQuestionsList={CannedQuestionsList}
                    handwritten_text_flag={handwritten_text_flag}
                    getNameBack={handleNameCallBack}
                    index_path={index_path}
                    workspaces={allWorkSpaces}
                    module_id={module_id}
                    engine_type={engine_type}
                    query={query}
                    batchName={items.batchName}
                /> : <>

                    <div id="popup-overlay"
                        className={`custom-popup-overlay view-fav-popup-overlay  show `} style={{ height: '100%', width: '100%',zIndex:8 }}>
                        <div style={{ height: 'max(80%, 350px)' }} className={`custom-popup-content workspace-list z-index-1`}
                            onClick={(e) => e.stopPropagation()} >
                            <div className="custom-popup-header">
                                <h2>{"Workspaces"}</h2>
                                <h2>  <span
                                    className="close-btn"
                                    onClick={() => closeWsListpopup()}
                                    title="Close"
                                >
                                    <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                        alt="close"
                                    />
                                </span>
                                </h2>
                            </div>
                            <div className="custom-popup-body">
                                {loading && <LoadingSpinner />}
                                {!loading && workSpaces.length === 0 ? (
                                    <EmptyWorkspaces
                                        handleOpenCreateNew={
                                            handleOpenCreateNew
                                        }
                                    />
                                ) : (
                                    <>
                                        <div
                                            className=" mb-2"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                width: "99%",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <button
                                                title={"Add to workspace"}
                                                className="continue"
                                                onClick={() => {
                                                    setOpenCreateNewPopUp(true);
                                                }}
                                            >
                                                Create and add to new workspace
                                            </button>
                                        </div>
                                        <div className="ag-theme-alpine" style={{ height: '69%' }}>
                                            <CustomTable
                                                defaultcoldefs={{
                                                    flex: 1,
                                                    resizable: true,
                                                    editable: false,
                                                    // autoHeight: true,
                                                }}
                                                columndefs={[
                                                    {
                                                        sortable: true,
                                                        filter: true,
                                                        suppressSizeToFit: true,
                                                        maxWidth: 80,
                                                        width: 80,
                                                        headerName: "",
                                                        cellRenderer: (
                                                            params
                                                        ) => {
                                                            return (
                                                                <p>
                                                                    <input type="checkbox"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                                setSelectedWorkSpaces(
                                                                                    [
                                                                                        ...selectedWorkSpaces,
                                                                                        params.data,
                                                                                    ]
                                                                                );
                                                                            }
                                                                            if (
                                                                                !e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                                let workspaces =
                                                                                    selectedWorkSpaces.filter(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.id !==
                                                                                            params
                                                                                                .data
                                                                                                .id
                                                                                    );
                                                                                setSelectedWorkSpaces(
                                                                                    workspaces
                                                                                );
                                                                            }
                                                                        }}
                                                                        checked={
                                                                            selectedWorkSpaces.filter(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    x.name ===
                                                                                    params
                                                                                        .data
                                                                                        .name
                                                                            )
                                                                                .length >
                                                                            0
                                                                        }
                                                                        disabled={items?.workspace_id === params?.data?.id}
                                                                    />
                                                                </p>
                                                            );
                                                        },
                                                        // tooltipField:"name",
                                                        // headerTooltip:"Workspace Name"
                                                    },
                                                    {
                                                        sortable: true,
                                                        filter: true,
                                                        field: "name",
                                                        // maxWidth: 350,
                                                        suppressSizeToFit: true,
                                                        headerName: "WORKSPACE NAME",
                                                        tooltipField: "name",
                                                        headerTooltip:
                                                            "WORKSPACE NAME",
                                                    },
                                                    {
                                                        sortable: true,
                                                        filter: true,
                                                        // width: 300,
                                                        maxWidth: 200,
                                                        suppressSizeToFit: true,
                                                        headerName: "OWNER",
                                                        field: "created_by_name",
                                                        cellRenderer: (
                                                            params
                                                        ) => {
                                                            return params.data?.users?.find(
                                                                (x) =>
                                                                    x.user_id ===
                                                                    params.data
                                                                        .created_by
                                                            )?.full_name;
                                                        },
                                                        tooltipField:
                                                            "created_by_name",
                                                        headerTooltip: "OWNER",
                                                    },
                                                ]}
                                                rowdata={workSpaces}
                                                activeTab={"01"}
                                                pageSize={20}
                                                fromCaseAnalysis={true}
                                                loading={loading}
                                            ></CustomTable>
                                        </div>
                                        <div className="btn-center">
                                            <button
                                                className="continue"
                                                onClick={() => handleSubmit()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* </div>
        </div>
 */}
                </>
            }
        </Fragment>
    );
};

export default WorkSpaceListPopUp;