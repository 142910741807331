import { createRef, Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../../contexts/socket.js";
import moduleNames from "../../../dropDowns/moduleNames.json";
import summarizeDocTypes from "../../../dropDowns/summarizeDocTypes.json";
import reviewCannedQues from "../../../dropDowns/documentReviewTypes.json";
import chatWithDocsCanned from "../../../dropDowns/ChatWithDocTypes.json";
import getAxiosInstance from "../../SupportiveComponents/AxiosRequest.jsx";
import ChatComponent from "../../SupportiveComponents/ChatComponent";
import { ContentReviewSectionComponent } from "../../SupportiveComponents/CustomContentOperations/CustomContentViewer";
import {
  CollapseAllIcon,
  ExpandIcon,
  Spinning,
} from "../../SupportiveComponents/FontAwesomeComponents/HelperIcons";
import {
  HandleDocTypeChangeComponent,
  HandleEngineComponent,
} from "../../SupportiveComponents/ModuleHelpers";
import ChatSidebar from "./ChatSideBar";

import { Sidenav } from "rsuite/";
// import "rsuite/dist/rsuite.min.css";
import {
  BackArrow,
  ExternalLinkIcon,
} from "../../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import LoadingSpinner from "../../SupportiveComponents/LoadingSpinner.jsx";
import { base64ToBlob } from "../../../Helpers/UtilMethods.js";
import { isMobile } from "../../SupportiveComponents/PureHelpers.jsx";

const WorkspaceChat = ({ WorkspaceItems, docTypes, locState }) => {
  const [state, setState] = useState({
    view: false,
    isLoading: false,
    selectedDocType: "",
    selectedEngine: 2,
    isBottomPanelOpen: true,
    totalDocs: 0,
    currentDocNo: 0,
    existDocTypes: docTypes,
    chat_key: uuidv4(),
    hasError:false
  });
  const [workspaceItems, setWorkspaceItems] = useState(
    docTypes ? docTypes : WorkspaceItems ?? []
  );
  const loc = useLocation();
  const history = useNavigate();
  if (!loc.state && workspaceItems) {
    history("/WorkSpace-Chat", {
      state: { workspaceItems: workspaceItems, ...locState },
    });
  }
  const [messges, setMessages] = useState([]);
  const [previousChats, setPreviousChats] = useState([]);
  const [token] = useState(localStorage.getItem("token"));
  const [loading, setLoding] = useState(false);
  const [isExpanded, setIsExpanded] = useState([{ status: true, index: 0 }]);
  const [isDocExpanded] = useState(true);
  const[workspace_id] = useState(loc?.state?.workspace_id)

  const ref = createRef();
  // const[docs, setDocs] = useState([])
  useEffect(() => {
    ref.current?.focus();
  }, [ref]);

  const handleNewchat = () => {
    setMessages([]);
    setExpand(!expand);
    getChats();
    setState((prevState) => {
      return {
        ...prevState,
        currentDocNo: 0,
        chat_key: uuidv4(),
      };
    });
  };

  const handleSelectChat = (data) => {
    ref.current?.focus();
    setExpand(!expand);
    Object.entries(data.response).map(([chat, questions]) => {
      setState({ ...state, chat_key: chat });
      return setMessages(questions);
    });
  };

  const handleQuestion = (data, chat, questinNo) => {
    setExpand(!expand);

    setState({ ...state, chat_key: chat });
    data["questionNo"] = questinNo;
    setMessages([data]);
  };

  const getChats = useCallback(() => {
    const axiosInstance = getAxiosInstance(token);
    setLoding(true);
    axiosInstance
      .get(`/workspace/chats/all?ws_id=${workspace_id}`)
      .then((res) => {
        let msgs = [];
        res?.data?.forEach((element) => {
          msgs.push({
            response: JSON.parse(element?.response),
            id: element?.id,
            display_name: element?.display_name,
          });
        });
        setLoding(false);
        setPreviousChats(msgs);
      });
  }, [token, workspace_id]);

  const mergeObjects = (obj1, obj2) => {
    const merged = { ...obj1 };
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (merged[key]) {
          merged[key] = [...merged[key], ...obj2[key]];
        } else {
          merged[key] = obj2[key];
        }
      }
    }
    return merged;
  };

  useEffect(() => {
    if (!loc?.state || loc.state?.workspace_id === 0) {
    } else if (loc?.state && loc.state?.workspace_id !== 0) {
      getChats();
    }

    
    if (WorkspaceItems) {
      setWorkspaceItems(WorkspaceItems);
    } else {
      setWorkspaceItems(loc?.state?.workspaceItems);
    }
    let existDocTypes = [];
    if (loc?.state?.docTypes) {
      existDocTypes = loc.state.docTypes;
    } else {
      existDocTypes = docTypes;
    }
    setState((prevState) => {
      return {
        ...prevState,
        existDocTypes: existDocTypes,
        selectedDocType:
          existDocTypes.length > 0 ? existDocTypes[0].documentID : "",
        selectedCanned_Ques:
          existDocTypes.length > 0
            ? {
                ...summarizeDocTypes,
                ...reviewCannedQues,
                ...chatWithDocsCanned,
              }[existDocTypes[0].documentID + "_Canned_Ques"]
            : "",
      };
    });
  }, [loc?.state, getChats, workspaceItems, docTypes, WorkspaceItems]);

  useEffect(() => {
    const chatListener = (responses) => {
      if(responses["workspace_id"] === loc?.state?.workspace_id){
      if (responses?.error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          hasError:true
        }));
        setLoding(false);
        Swal.fire({
          text: "Sorry, we are experiencing difficulties processing your request. Please try again in a little while.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        setLoding(false);
        
        let data = responses["message"];
        let previoudsChats = responses["previousChat"];
        const chat_session_key = Object.keys(data?.response)[0];
        const chat_session = data?.response[chat_session_key];

        const itemCount = Array.isArray(chat_session) ? chat_session.length : 0;
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          currentDocNo: data?.id === 0 ? itemCount : 0,
        }));
        if (messges.length === 0) {
          setMessages([...messges.filter((y) => y.id !== 0), data?.response]);
        } else {
          messges.forEach((element) => {
            if (
              Object.entries(element)[0][1][0]["chat_key"] ===
              Object.entries(data?.response)[0][1][0]["chat_key"]
            ) {
              setMessages([
                ...messges.filter((y) => y.id !== 0),
                data?.response,
              ]);
              return;
            }
          });
        }

        let exist = previousChats.some(
          (item) => item.response && item.response[data?.chat_key]
        );
        if (!exist && previoudsChats?.id !== 0) {
          setPreviousChats([previoudsChats, ...previousChats]);
        } else {
          let updatedChats = previousChats.map((e) => {
            if (
              Object.keys(e?.response)[0] ===
                Object.keys(previoudsChats?.response)[0] &&
              previoudsChats?.id !== 0
            ) {
              return {
                ...e,
                response: mergeObjects(e?.response, previoudsChats?.response),
              };
            }
            return e;
          });

          if (updatedChats.length === previousChats.length) {
            setPreviousChats(updatedChats);
          }
        }
      }
    }
    };

    socket.on("chat_within_workspace_response", chatListener);

    return () => {
      socket.off("chat_within_workspace_response", chatListener);
    };
  }, [messges, previousChats,loc?.state?.workspace_id]);

  const handleSend = async () => {
    let ws_items = [];
    let wsItemsOfSameDocType =
      workspaceItems?.length > 0 &&
      workspaceItems?.filter((x) => x.document_type === state.selectedDocType);

    wsItemsOfSameDocType?.length > 0 &&
      wsItemsOfSameDocType?.forEach((e) => {
        if (e?.engineType !== 5) {
          e?.docs?.length > 0 &&
            e?.docs?.forEach((element) => {
              ws_items.push({
                  id: e.id,
                  module_id: e.module_id,
                  engineType: e?.engine_type,
                  document_name: element?.docName,
                  doctype: e?.document_type,
                  index_path: element.index_path,
                  batchName: e?.docs?.length > 1 ? e.batchName : "",
                  doc_id: element?.doc_id,
                  chat_key: state.chat_key,
              });
            });
        }
      });

    if (ws_items?.length === 0) {
      let res = await Swal.fire({
        text: "No documents are with this document type",
        icon: "error",
        confirmButtonText: "OK",
      });

      if (res.isConfirmed) {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        return setLoding(false);
      }
    }
    setState((prevState) => ({
      ...prevState,
      totalDocs: ws_items.length,
      Docs: ws_items,
      isLoading: false, // Set isLoading to false after processing
    }));

    setLoding(true);
    return ws_items;
  };
  const handleDocTypeChange = (event, docTypes) => {
    const { value } = event.target;
    setState({
      ...state,
      selectedDocType: value,
      selectedCanned_Ques: {
        ...summarizeDocTypes,
        ...reviewCannedQues,
        ...chatWithDocsCanned,
      }[value + "_Canned_Ques"],
      view: state.view,
    });
    console.log(value);
  };
  const toggleTab = (tabId) => {
    setState({ ...state, activeTab: tabId, isBottomPanelOpen: true });
  };

  const handleBottomPanel = (isOpen) => {
    setState({ ...state, isBottomPanelOpen: isOpen });
  };

  const setLoaderStatus = (flag, fireSwal) => {
    try {
      setLoding(true);
      setState({ ...state, isLoading: flag });

      if (fireSwal) {
        Swal.fire({
          text: "We are unable to process this file at this time. May be try another file",

          icon: "error",

          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.log("ErrorTriggerMsgSet:", error);
    }
  };

  const setReqStartTime = (startTime) => {
    setState({ ...state, startTime: startTime });
  };

  const openDocument = (id) => {
    const allDocs =
      workspaceItems?.length > 0 &&
      workspaceItems?.flatMap((item) => item.docs);
    let base64String = allDocs?.find((x) => x.doc_id === id).content;
    // Extracting the Base64 part from the string (assuming the format is "data:<mimeType>;base64,<data>")
    const base64Data =
      base64String.split(",")?.length > 0 && base64String.split(",")[1];
    const mimeType = base64String.split(",")[0].split(":")[1].split(";")[0];

    const blob = base64ToBlob(base64Data, mimeType);
    const url = URL.createObjectURL(blob);

    // Open the new tab and write the document into an iframe
    const newTab = window.open(url, "_blank");
    if (newTab) {
      return newTab.document.write(
        `<iframe src="${url}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
      );
    }

    // Clean up the URL when the component unmounts
    return () => {
      // URL.revokeObjectURL(url);
      if (newTab) {
        newTab.open();
      }
    };
  };

  const [expand, setExpand] = useState(true);
  //   const [activeKey, setActiveKey] = useState("1");

  return (
    <Fragment>
      {loading && <LoadingSpinner />}
      <div className={`${!isMobile() ? "app-main" : ""}`}>
        {isMobile() ? (
          <Sidenav
            expanded={expand}
            defaultOpenKeys={["1", "4"]}
            style={{ height: "50%" }}
          >
            <Sidenav.Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <BackArrow
                style={{
                  color: "#2eb1e3",
                  padding: "10px",
                  VerticalAlign: "center",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
                title="BACK TO LIST"
                onClick={() => history(-1)}
              />
              <Sidenav.Toggle
                expanded={expand}
                onToggle={(expanded) => setExpand(!expand)}
                style={{ fontSize: "24px" }}
                // className="icon-container"
              />
            </Sidenav.Header>

            <Sidenav.Body style={{ height: "50%" }}>
              {expand && (
                <ChatSidebar
                  previousChat={previousChats}
                  onSelectChat={handleSelectChat}
                  getChats={handleNewchat}
                  messages={[...messges].reverse()}
                  chat_key={state.chat_key}
                  handleQuestion={handleQuestion}
                  workspace_id={loc?.state?.workspace_id}
                  workspace_name={loc.state.workspace_name}
                />
              )}
            </Sidenav.Body>
          </Sidenav>
        ) : (
          <ChatSidebar
            previousChat={previousChats}
            onSelectChat={handleSelectChat}
            getChats={handleNewchat}
            messages={[...messges].reverse()}
            chat_key={state.chat_key}
            handleQuestion={handleQuestion}
            workspace_id={loc?.state?.workspace_id}
            workspace_name={loc.state.workspace_name}
          />
        )}

        {((isMobile() && !expand) || !isMobile()) && (
          <div className="chat-window">
            <div
              style={{ height: "100%" }}
              id="botbar"
              className="chat-dropdowns"
            >
              <div style={{ display: "flex", margin: "20px" }}>
                <HandleDocTypeChangeComponent
                  selectedDocType={state.selectedDocType}
                  handleDocTypeChangeCallback={(e) => handleDocTypeChange(e)}
                  documentTypes={state.existDocTypes ?? []}
                  fileName={state.fileName}
                />
                <HandleEngineComponent
                  selectedEngine={state?.selectedEngine}
                  handleEngineChangeCallback={(e) => {
                    setState({
                      ...state,
                      selectedEngine: parseInt(e?.target?.value),
                    });
                  }}
                  fileName={state.fileName}
                />
              </div>
              <div>
                <div className="text-center">
                  {state.currentDocNo !== 0 &&
                  state.currentDocNo + 1 !==
                    (state.selectedDocType === "medical_Records"
                      ? workspaceItems
                          ?.filter(
                            (x) =>
                              (x.document_type === "medical_Records" ||
                                x.document_type ===
                                  "medical_Reports_Records") &&
                              x.engineType !== 5
                          )
                          .reduce((sum, item) => sum + item.docs.length + 1, 0)
                      : workspaceItems
                          ?.filter(
                            (x) =>
                              x.document_type === state.selectedDocType &&
                              x.engineType !== 5
                          )
                          .reduce(
                            (sum, item) => sum + item.docs.length + 1,
                            0
                          )) ? (
                    <>
                      {" "}
                      <Spinning />{" "}
                      {state.hasError === false ? `Processing ${state.currentDocNo + 1} of ${
                        state.selectedDocType === "medical_Records"
                          ? workspaceItems
                              ?.filter(
                                (x) =>
                                  (x.document_type === "medical_Records" ||
                                    x.document_type ===
                                      "medical_Reports_Records") &&
                                  x.engineType !== 5
                              )
                              .reduce((sum, item) => sum + item.docs.length, 0)
                          : workspaceItems
                              ?.filter(
                                (x) =>
                                  x.document_type === state.selectedDocType &&
                                  x.engineType !== 5
                              )
                              .reduce((sum, item) => sum + item.docs.length, 0)
                      } `:""}
                    </>
                  ) : (
                    ``
                  )}
                </div>
              </div>
              <div className="chat-container">
                <div
                  className={`${messges.length > 0 && "messages"}`}
                  style={{ padding: "10px !important" }}
                >
                  {messges?.length > 0 &&
                    messges?.map((msg, index) => {
                      return (
                        Object.entries(msg)?.length > 0 &&
                        [
                          Object.entries(
                            messges[messges.length - (index + 1)]
                          )[0],
                        ].map(([chat, value]) => {
                          return (
                            <div className="chat-item">
                              <div
                                className={`chat-title`}
                                style={{ fontWeight: "bold", display: "flex" }}
                              >
                                <div style={{ width: "90%" }}>
                                  {chat === undefined
                                    ? "NO question"
                                    : `${
                                        Object.entries(msg)?.length > 1 &&
                                        Object.keys(
                                          messges[messges.length - (index + 1)]
                                        )?.includes("questionNo")
                                          ? Object.entries(
                                              messges[
                                                messges.length - (index + 1)
                                              ]
                                            )[1][1]
                                          : messges.length - index
                                      }. ${chat}`}
                                </div>
                                <span
                                  className="vertical_align_m"
                                  style={{ float: "inline-end" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let arr = isExpanded.filter(
                                      (x) => x.index !== index
                                    );
                                    arr.push({
                                      status: !isExpanded?.find(
                                        (x) => x.index === index
                                      )?.status,
                                      index: index,
                                    });
                                    setIsExpanded(arr);
                                  }}
                                >
                                  {isExpanded?.find((x) => x.index === index)
                                    ?.status ? (
                                    <CollapseAllIcon />
                                  ) : (
                                    <ExpandIcon />
                                  )}
                                </span>
                              </div>
                              {value?.length > 0 &&
                                value?.map((e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={`chat-view ${
                                        isExpanded?.find(
                                          (x) => x.index === index
                                        )?.status
                                          ? "expanded"
                                          : "collapse"
                                      }`}
                                    >
                                      <ContentReviewSectionComponent
                                        key={i}
                                        question={
                                          <p className="doc-color d-flex">
                                            {e?.document_name +
                                              " (" +
                                              moduleNames?.find(
                                                (x) => x.Id === e?.module_id
                                              )?.loadingName +
                                              (e?.batchName?.length > 0
                                                ? "-" + e?.batchName
                                                : "") +
                                              ")"}
                                            <p
                                              onClick={() =>
                                                openDocument(e?.doc_id)
                                              }
                                            >
                                              <ExternalLinkIcon title="Document will open in a new tab"></ExternalLinkIcon>
                                            </p>
                                          </p>
                                        }
                                        time={e?.time}
                                        timetaken={parseInt(e?.timetaken) + "s"}
                                        response={e.response}
                                        fileName={
                                          moduleNames?.find(
                                            (x) => x.Id === e?.module_id
                                          )?.loadingName
                                        }
                                        componenttitle="chatwithworkspace"
                                        isExpanded={isDocExpanded}
                                        created_by_name={e?.created_by_name}
                                        embeddings={""}
                                        engine_id={state.selectedEngine}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {state.selectedDocType && (
        <div className="input-container">
          <ChatComponent
            module_name="chatWithWorkspace"
            activeTab={0}
            toggleTab={toggleTab}
            selectedDocType={state.selectedDocType}
            handleBottomPanel={handleBottomPanel}
            isBottomPanelOpen={true}
            selectedCanned_Ques={state.selectedCanned_Ques}
            promptDisplayed={state.promptDisplayed}
            isShowFavorites={true}
            chatType="chatWithWorkspace"
            setLoaderStatus={setLoaderStatus}
            isLoading={state.isLoading}
            token={token}
            selectedEngine={state.selectedEngine}
            setReqStartTime={setReqStartTime}
            inputValue={state.selectedPrompt?.id}
            workspace_item_id={state.workspace_item_id}
            workspace_id={loc.state.workspace_id}
            workspace_name={loc?.state?.workspace_name}
            get_ws_items={handleSend}
            chat_ref={ref}
          />
        </div>
      )}
    </Fragment>
  );
};
export default WorkspaceChat;
