import React, { useEffect, useState } from "react";
import { FormatStringInTabularFormat } from "./RenderDataInTabularFormat";
import { EmbeddingsPopup } from "../PopUps/EmbeddingsPopup";
import { jsonrepair } from "jsonrepair";

import ReactMarkdown from "react-markdown";
export const CustomRenderer = ({
    content,
    embeddings,
    isExpanded,
    engine_id,
}) => {
    const [isJson, setIsJson] = useState(false);
    const [isMdJson, setMdJson] = useState(false);
    const [componentContent, setComponentContent] = useState(
        content.replace('""', '"')
    );
    const [engine_id_state, setEngineId] = useState(engine_id);
    useEffect(() => {
        setEngineId(engine_id);
    }, [engine_id]);
    useEffect(() => {
        try {
           
            setMdJson(content.match(/```json([\s\S]*?)```/)?.length ? (content.match(/```json([\s\S]*?)```/)?.length > 0) : false);
            const repaired = jsonrepair(content.replace('""', '"'));
            
          
        //    repaired.length > 0 JSON.parse(repaired);
            console.log(`test `, JSON.parse(repaired))
            JSON.parse(repaired).map((val, idx) => {
                if (typeof val === 'string') {
                    throw new Error(`Found String Inside Json`)
                }
                return val;
            })
            setIsJson(true);
        
        }
        catch (error) {

            setIsJson(false);
        }
        setComponentContent(content.replace('""', '"'));
    }, [content]);
    const regexPattern = /--*\s*\|\s*--*/;
    
  
    return (
        <>
            {regexPattern.test(componentContent) || isJson || isMdJson ? (
                <>
                    <CustomContentViewer
                        componentContent={
                            isMdJson
                                ? componentContent
                                      ?.match(/```json([\s\S]*?)```/)[1]
                                      .trim()
                                : componentContent
                        }
                        regexPattern={regexPattern}
                        isExpanded={isExpanded}
                        engine_id={+engine_id_state}
                    />
                </>
            ) : (
                <>
                    <>
                        <ReactMarkdown>{componentContent}</ReactMarkdown>
                    </>
                </>
            )}
            <ContentReferences embeddings={embeddings} />
        </>
    );
};

export const ContentReferences = ({ embeddings }) => {
    const [displayPopup, setDisplayPopup] = useState(false);
    const [styles, setStyles] = useState({});
    return (
        <>
            {embeddings?.length > 0 && (
                <div className="references_link">
                    <button
                        className="hyperlink"
                        onClick={(e) => {
                            //logic to prevent negative top values
                            let top =
                                e?.currentTarget?.offsetTop +
                                    100 -
                                    window.innerHeight / 2 >
                                0
                                    ? e?.currentTarget?.offsetTop +
                                      100 -
                                      window.innerHeight / 2
                                    : 0;
                            setStyles({
                                left: e?.currentTarget?.getBoundingClientRect()
                                    ?.left,
                                top: top,
                            });
                            setDisplayPopup(!displayPopup);
                        }}
                    >
                        {" "}
                        References
                    </button>
                    {displayPopup && embeddings?.length && (
                        <EmbeddingsPopup
                            content={embeddings}
                            closeEmbedpopup={() => {
                                setDisplayPopup(!displayPopup);
                            }}
                            positionObject={{
                                ...styles,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export const CustomContentViewer = ({
    componentContent,
    regexPattern,
    isExpanded,
}) => {
    const [componentContentS, setComponentContent] = useState(componentContent);
    const [regexPatterns, setRegexPattern] = useState(regexPattern);
    useEffect(() => {
        setComponentContent(componentContent);
    }, [componentContent]);
    useEffect(() => {
        setRegexPattern(regexPattern);
    }, [regexPattern]);
    return (
        <>
          
            {isExpanded && (
                <FormatStringInTabularFormat
                    rawdatastr={
                        componentContentS
                    }
                    regpattern={regexPatterns}
                />
            )}
            <br />
            {/* {componentContentS.split("\n\n")[0].match(regexPatterns)
                ? componentContentS.split("\n\n")[1]
                : componentContentS.split("\n\n")[2]} */}
        </>
    );
};
