import { Formik } from 'formik'
import { Fragment, useEffect, useState } from "react"
import * as Yup from 'yup'
import '../../../css/workspace.css'
import getAxiosInstance from '../AxiosRequest.jsx'
import { ToastAlert } from "../CustomAlerts/ToastAlert.jsx"
import LoadingSpinner from "../LoadingSpinner.jsx"
import store from '../../../redux/store.js'

const CreateWorkSpacePopUp = (props) => {
    const [loading, setLoading] = useState(false);
    const [workSpaces, setWorkSpaces] = useState([])
    const [state, setState] = useState({ error: "" })
    

    const handleValidation = Yup.object().shape({
        workSpace: Yup.string().required('WorkSpace is required.')
    })

    useEffect(() => {
        let Wss = []
        props.workspaces?.length > 0 && props.workspaces.forEach((element) => {
            element["org_id"] = element.users?.find(x => x.user_id === element.created_by)?.organization_id
            Wss.push(element)
        })
        setWorkSpaces(Wss)
    }, [props.workspaces])

    const handleSubmit = (values) => {
        if (workSpaces.filter(x => x.name === values.workSpace && x.org_id === parseInt(store.getState().login.logInState?.org_id)).length === 0) {
            setLoading(true)
            var token = localStorage.getItem('token')
            const axiosInstance = getAxiosInstance(token);
            var msgs = []
            var docs = []
            if(props?.workSpace && props?.workspaceId){
                axiosInstance.post('/workspace/rename', JSON.stringify({"name":values.workSpace,"id":props?.workspaceId,"old_name":props?.workSpace}), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    timeout: 180000,
                }).then((res) => {
                    if (res.status === 200) {
                        ToastAlert("Updated the workspace name successfully");
                        setLoading(false)
                        props.getNameBack(values);
                        props.closeworkspacelist(res.data);
                      
    
                    }
                }) 
            }
            else{
            if (props.content !== "") {
                msgs.push({
                    response: props.content,
                    embeddings:"",
                    query: props.query ? props.query : "",
                    response_build_time: props.timeTaken ? props.timeTaken?.replace('s', '') : "0.0",
                    chat_id: props.batchName ? props.batchName : ""
                })
            }
            props.CannedQuestionsList.forEach(element => {
                msgs.push({
                    response: element.response,
                    embeddings:element?.embeddings.length>0 ?JSON.stringify(element?.embeddings):"",
                    query: element.question,
                    response_build_time: element.timetaken?.replace('s', ''),
                    chat_id: props.chat_id ? props.chat_id : null
                })
            });
            // var dupName = getUniqueFileName(element.workspace_items, document_name, module_id)
            

            props.docsContent?.length > 0 && props.docsContent?.forEach((e, i) => {
                docs.push({
                    name: e.fileName,
                    // name: props.document_name,
                    uri: "",
                    content: e.content,
                    handwritten_text_flag: props.handwritten_text_flag,
                    index_path: e.index_path,
                    mime_type: e.fileType
                })
            })
          
            var ws_item = {
                module_id: props.module_id,
                engine_type: props.engine_type,
                messages: msgs,
                documents: docs,
                document_type: props.document_type
            }

            var obj = { name: values.workSpace, ws_item: ws_item }
            axiosInstance.post('/workspace', JSON.stringify(obj), {
                headers: {
                    "Content-Type": "application/json",
                },
                timeout: 180000,
            }).then((res) => {
                if (res.status === 200) {
                    ToastAlert("Created and added to workspace successfully");
                    setLoading(false)
                    props.getNameBack(values);
                    props.closeworkspacelist(res.data);
                  

                }
            })
        }
        } else {
            setState({ ...state, error: "A workspace by that name already exists, please choose another name." })
        }

    }

    return (
        <Fragment>
            <div
                id="popup-overlay"
                className={`custom-popup-overlay view-fav-popup-overlay  show
            }`}
                onClick={() => props.closepopup()}
                style={{ height: '100%', width: '100%' }}
            >
                <div
                    className={`custom-popup-content create-new-popup`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="custom-popup-header">
                        <h2>{props?.workSpace?"Edit workspace":"Create A New workspace"}</h2>
                        <h2>
                            <span
                                className="close-btn"
                                onClick={() => props.closepopup()}
                                title="Close"
                            >
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgB7ZRBCsAgDARDX5L/f8qnbC8WeqmmugbBHfC27IiYmAkh/gCg1OOBrD95G6UWoCd9yTAr9J40kqFJ6bJW8TJZQ7pO9iEdkl22K6lPmvppUsciUkiVRotAXG0lemswlrcQZ3ID/WKPAIecKToAAAAASUVORK5CYII="
                                    alt="close"
                                />
                            </span>
                        </h2>
                    </div>
                    <div className="custom-popup-body">
                        {loading && <LoadingSpinner />}
                        <Formik initialValues={{ workSpace: props.workSpace ? props.workSpace : '' }} onSubmit={(values) => handleSubmit(values)} validationSchema={handleValidation}>
                            {({ errors, values, setFieldValue }) => {
                                const handleChange = (e) => {
                                    const { value } = e.target;
                                    
                                    if (workSpaces.filter(x => x.name?.toLowerCase().trim() === value?.toLowerCase().trim() && x.org_id === parseInt(store.getState().login.logInState?.org_id)).length === 0) {
                                        setState({ ...state, error: "" })
                                    }
                                    else {
                                        setState({ ...state, error: "A workspace by that name already exists, please choose another name." })
                                    }
                                    // Regular expression to allow only numbers, alphabets, and underscores
                                    const regex = /^[a-zA-Z0-9_ -]*$/;
                                    if (regex.test(value)) {
                                        setFieldValue(e.target.name, value)
                                    }
                                }
                                return <div>
                                    <div style={{ padding: '0 3% 0 3%', height: '100px' }} className='mt-3 mb-2' >
                                        <div className="align mt-3 fulldrapdropsec" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <h4> Name: </h4>
                                            <input id="workSpace" type="text" name="workSpace" value={values.workSpace}
                                                placeholder="Workspace name" className="inputfield" onChange={(e) => handleChange(e)} />
                                            {/* <input id="workSpace" name="workSpace" type="text" value={values.workSpace} onChange={(e) => handleChange(e)}></input> */}
                                        </div>
                                    </div>
                                    <div className={`mt-3 button-container`} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button title={"Save"} className="continue" onClick={() => handleSubmit(values)} disabled={(errors&& errors.workSpace) || (state.error)}>
                                        {props?.workSpace?"Update":"Save"}
                                        </button>
                                    </div>
                                    <p className="error-color mt-2 text-center mb-3">{errors && errors.workSpace ? errors.workSpace : state.error}</p>
                                </div>
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
            
        </Fragment>
    );
}
export default CreateWorkSpacePopUp;