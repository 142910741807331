import React, { useEffect, useState } from "react";
import hamburger from "../../images/hamburger.png";
import help from "../../images/HelpIcondark.svg";
import helplight from "../../images/HelpIcon.svg";
import close from "../../images/close.png";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobile } from "../SupportiveComponents/PureHelpers";
import useTheme from "../../contexts/theme";
import store from "../../redux/store";
import ReactJoyride, { STATUS } from "react-joyride";
import Walkthrough from "../SupportiveComponents/PopUps/Walkthrough";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx"

export default function LeftPane(props) {
  const { themeMode } = useTheme();
  const [isOpenWalkthrough, setIsOpenWalkthrough] = useState(false);
  const [startWalkthrough, setStartWalkthrough] = useState(props?.startWalkthrough?props?.startWalkthrough:false);
  const [closeWalkthrough, setCloseWalkthrough] = useState(false);
  const[modules, setModules] = useState([])
  const token = localStorage.getItem("token")
  const org_id = localStorage.getItem("org_id")
  const history = useNavigate()
  useEffect(()=>{
    setStartWalkthrough(props?.startWalkthrough)
  },[props?.startWalkthrough])

  const toggleOpen = () => {
    props.setSidePaneStatus(!props.isLeftPanelOpen);
  };
  const steps = [
    {
      content: (
        <div>
          <h3 style={{ textAlign: "left" }}>DASHBOARD</h3>
          <p className="mt-3">
            Here you can access your recently used documents, favorited
            documents & workspaces.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      disableBeacon: true,
      target: ".menusec ul li .dashboard",
    },
    {
      target: ".menusec ul li .review",
      content: (
        <div>
          <h3 style={{ textAlign: "left" }}>REVIEW</h3>
          <p className="mt-3">
            Analyze your document here and view results that highlight critical
            information and potential risks.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      disableBeacon: true,
    },
    {
      target: ".menusec ul li .summerize",
      content: (
        <div>
          <h3 style={{ textAlign: "left" }}>SUMMARIZATION</h3>
          <p className="mt-3">
            Generate a concise summary highlighting key points and essential
            information.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      disableBeacon: true,
    },
    {
      target: ".menusec ul li .chat",
      content: (
        <div>
          <h3 style={{ textAlign: "left" }}>DOCUMENT CHAT</h3>
          <p className="mt-3">
            Ask questions to gain deeper insights or clarification on specific
            document sections. Upload one or many documents.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      disableBeacon: true,
    },
    {
      target: ".menusec ul li .workspace",
      content: (
        <div>
          <h3 style={{ textAlign: "left" }}>WORKSPACES</h3>
          <p className="mt-3">
            Share insights, and collaborate on cases with colleagues.
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      disableBeacon: true,
    },
  ];
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setCloseWalkthrough(true);
      setStartWalkthrough(false);
      setIsOpenWalkthrough(true);
      props?.setStartWalkthrough(false)
    }
  };

  const startOrCloseWalkthrough = (isClose) => {
    if (closeWalkthrough || isClose) {
      setIsOpenWalkthrough(false);
      localStorage.setItem(`${store?.getState()?.login?.logInState?.user_id}-hasSeenTour`, 'true');
    } else if (!closeWalkthrough) {
      setIsOpenWalkthrough(false);
      setStartWalkthrough(true);
    }
  };

  useEffect(() => {
    const hasSeenTour = localStorage.getItem(`${store?.getState()?.login?.logInState?.user_id}-hasSeenTour`);
    if (!hasSeenTour) {
        setIsOpenWalkthrough(true);
    }
}, []);

useEffect(()=>{
  const axiosInstance = getAxiosInstance(token,false, history);

axiosInstance.get(`/org/modules?org_id=${org_id}`, {timeout: 300000,}).then((res)=>{
setModules(res?.data?.modules)
})
},[org_id, history, token])

  return (
    <div
      id="leftpanel"
      className={props.isLeftPanelOpen ? "sidenav" : "sidenavClosed"}
    >
      <button className="menuBtn" name="Menu" onClick={toggleOpen}>
        {!props.isLeftPanelOpen ? (
          <div className="hamburgermenu">
            <div className="closebtn" style={{overflowX:"visible !important"}}>
              <img src={hamburger} alt="" className="hamburger" />
              {/* <span>Menu</span> */}
            </div>
          </div>
        ) : (
          <div className="hamburgermenu">
            <div className="closebtn">
              <img src={close} alt="" />
              <span>Menu</span>
            </div>
          </div>
        )}
      </button>
      {isOpenWalkthrough && (
        <Walkthrough
          firstText={!closeWalkthrough}
          closepopup={() => setIsOpenWalkthrough(false)}
          startwalkthrough={(isClose) => startOrCloseWalkthrough(isClose)}
        />
      )}
      <ReactJoyride
        steps={steps}
        run={startWalkthrough}
        continuous
        callback={handleJoyrideCallback}
        showProgress
        showSkipButton
        styles={{
          options: {
            arrowColor: "#eee",
            backgroundColor: "#eee",
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "var(--clr-btn-background)",
            textColor: "#333",
            width: 500,
            zIndex: 1000,
            overflowX:"visible !important"
          },
          buttonNext: {fontWeight: 'bold',textTransform:'uppercase',fontSize:'12.8px'},
          buttonSkip:{fontWeight: 'bold',textTransform:'uppercase',fontSize:'12.8px'},
          buttonBack:{fontWeight: 'bold',textTransform:'uppercase',fontSize:'12.8px'}
          
        }}
      />
      <nav className="menusec">
        <ul>
          <>
            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Dashboard"
              key="1"
            >
              <NavLink
                exact="true"
                to="DashBoard"
                className="dashboard"
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Review"
              key="2"
              hidden={modules.filter(x=>x.module_id === 1).length === 0}
            >
              <NavLink
                to="Review"
                className="review"
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Review</span>
              </NavLink>
            </li>
            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Summarize"
              key="3"
              hidden={modules.filter(x=>x.module_id === 2).length === 0}
            >
              <NavLink
                to="Summarize"
                className="summerize"
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Summarize</span>
              </NavLink>
            </li>

            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Chat with documents"
              key="4"
              hidden={modules.filter(x=>x.module_id === 3).length === 0}
            >
              <NavLink
                to="ChatWithDocument"
                className="chat"
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Chat with Docs</span>
              </NavLink>
            </li>

            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Repository Chat"
              key="5"
              hidden={modules.filter(x=>x.module_id === 4).length === 0}
            >
              <NavLink
                to={`${
                  store.getState().login.logInState.usertypeid === "4"
                    ? "Repository/ComingSoon"
                    : "RepositoryChat"
                }`}
                state={{ path: "RepositoryChat" }}
                className={`InternalDocChat`}
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Repository Chat</span>
              </NavLink>
            </li>

            <li
              data-toggle="tooltip"
              data-placement="top"
              title="Case Assessment"
              key="7"
              hidden={modules.filter(x=>x.module_id === 6).length === 0}
            >
              <NavLink
                to={`Case_Analysis`}
                state={{ path: "Case_Analysis" }}
                className={`case-analysis`}
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>Case Assessment</span>
              </NavLink>
            </li>

            <li
              data-toggle="tooltip"
              data-placement="top"
              title="WorkSpace List"
              key="8"
            >
              <NavLink
                to={`${
                  store.getState().login.logInState.usertypeid === "4"
                    ? "Workspaces/ComingSoon"
                    : "WorkSpaceList"
                }`}
                state={{ path: "workspaces" }}
                className="workspace"
                activeclassname="active"
                onClick={() => {
                  if (isMobile()) {
                    props.setSidePaneStatus(false);
                  }
                }}
              >
                <span>WorkSpace List</span>
              </NavLink>
            </li>
          </>
        </ul>
      </nav>
      <div className="helpicon">
        <NavLink to="trial-help">
          <img src={themeMode === "light" ? helplight : help} alt="Help" />
          <span>Help</span>
        </NavLink>
      </div>
    </div>
  );
}
