import { Resizable } from "re-resizable";
import React, { Component, Fragment } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "../../css/CaseAnalysis.css";
import followup_prompts_ddl from "../../dropDowns/CaseAnalysisTypes.json";
import docTypes_ddl from "../../dropDowns/docTypes.json";
import favlistlogo from "../../images/FavoriteList.svg";
import { setTitle } from "../../js/setTitle.js";
import { initialState } from "../../redux/Reducers/caseAnalysisReducer.js";
import {
  updateCaseAnalysisState,
  updateDashBoardState,
} from "../../redux/actions";
import store from "../../redux/store.js";
import getAxiosInstance from "../SupportiveComponents/AxiosRequest.jsx";
import ContentOperations from "../SupportiveComponents/ContentOperations";
import { ToastAlert } from "../SupportiveComponents/CustomAlerts/ToastAlert.jsx";
import { CaseAnalysisPopup } from "../SupportiveComponents/CustomPopup.jsx";
import { SvgIconButton } from "../SupportiveComponents/DocumentComponents/DocViewer.jsx";
import {
  CollapseAllIcon,
  ExpandIcon,
} from "../SupportiveComponents/FontAwesomeComponents/HelperIcons.jsx";
import {
  FilledHeart,
  RegularHeart,
} from "../SupportiveComponents/FontAwesomeComponents/heart/filledheart.jsx";
import LoadingSpinner from "../SupportiveComponents/LoadingSpinner";
import { HandleEngineComponent } from "../SupportiveComponents/ModuleHelpers.jsx";
import WorkSpaceListPopUp from "../SupportiveComponents/PopUps/WorkspaceListPopUp.jsx";
import { isMobile } from "../SupportiveComponents/PureHelpers.jsx";
import ReadMoreCollapse from "../SupportiveComponents/ReadMoreCollapsible";
import ShowAllWorkspacePopUp from "../SupportiveComponents/PopUps/ShowAllSelectedWorkspacePopUp.js";

// import { Resizable } from "react-resizable";
class CaseAnalysisComponent extends Component {
  state = {
    engine_type: 2,
    selectedDocType: docTypes_ddl.CaseAnalysis?.length>0 ? docTypes_ddl.CaseAnalysis[0].documentID:"",
    selectedLabel: "",
    isBottomPanelOpen: true,
    caseAnalysisResult: "",
    timetaken: "",
    isAnalysed: false,
loadingText:"",
    selectedFollowup: {},
    empty_case_Details: {
      caseName: "",
      description: "",
      plaintiff: "",
      defendant: "",
      jurisdiction: "",
      case_number: "",
      date: "",
      location: "",
      legal_issue: "",
      status: "",
      witness: "",
      relevant_documents: "",
      Statute_of_Limitations: "",
    },
    empty_FollowUp_Details: {
      Nature_of_Incident: "",
      Injury: "",
      Treatment_Received: "",
      Medical_Expense: "",
      Lost_Wages: "",
      Downtime: "",
      Pain_Suffering: "",
      Parties_Behavior: "",
      Laws_Regulations: "",
      Analysis_Questions: "",
      Liability: "",
      Insurance_Coverage: "",
      Plaintiff_Req: "",
      Defendent_Position: "",
      Claims: "",
      Evidence: "",
      Laws_Precedents: "",
      Legal_Team: "",
      Additional_Information: "",
    },
    Case_Details: {
      caseName: "",
      Statute_of_Limitations: "",
      description: "",
      plaintiff: "",
      defendant: "",
      jurisdiction: "",
      case_number: "",
      date: "",
      location: "",
      legal_issue: "",
      status: "",
      witness: "",
      relevant_documents: "",
    },
    validation_errors: {
      caseName: "Case Name",
      description: "Description",
      plaintiff: "Plaintiff",
      defendant: "Defendant",
      jurisdiction: "Jurisdiction",
      case_number: "Case Number",
      date: "Date",
      location: "Location",
      legal_issue: "Legal Issue",
      status: "Status",
      witness: "Witness",
      // relevant_documents: "Relevant Documents",
      Nature_of_Incident: "Nature of Incident",
      Injury: "Injury",
      Treatment_Received: "Medical Treatment",
      Medical_Expense: "Medical Expenses",
      Lost_Wages: "Lost Wages",
      Downtime: "Downtime",
      Pain_Suffering: "Pain Suffering",
      Parties_Behavior: "Parties Behavior",
      Laws_Regulations: "Laws Regulations",
      // Expert_Testimony: "Expert Testimony",
      Analysis_Questions: "Questions for Analysis",
      Liability: "Liability",
      Insurance_Coverage: "Insurance Coverage",
      Plaintiff_Req: "Plaintiff's Requirements",
      Defendent_Position: "Defense's Position",
      // Precedents: "Precedents",
      Claims: "Claims and Counterclaims",
      Evidence: "Evidence",
      Laws_Precedents: "Laws and Precedents",
      // Parties: "Parties",
      Legal_Team: "Legal Team",
      Statute_of_Limitations: "Statute of Limitations",
      // Evidentiary: "Evidentiary",
      // Legal_Precedents: "Legal Precedents",
      // Opposing_Party: "Opposing Party",
      // Plaintiff_Position: "Plaintiff's Position",
    },
    FollowUp_Details: {
      Nature_of_Incident: "",
      Injury: "",
      Treatment_Received: "",
      Medical_Expense: "",
      Lost_Wages: "",
      Downtime: "",
      Pain_Suffering: "",
      Parties_Behavior: "",
      Laws_Regulations: "",
      // Expert_Testimony: "",
      Analysis_Questions: "",
      Liability: "",
      Insurance_Coverage: "",
      Plaintiff_Req: "",
      Defendent_Position: "",
      // Precedents: "",
      Claims: "",
      Evidence: "",
      Laws_Precedents: "",
      // Parties: "",
      Legal_Team: "",
      Additional_Information: "",
      // Evidentiary: "",
      // Legal_Precedents: "",
      // Opposing_Party: "",
      // Plaintiff_Position: "",
    },
    FollowUpAnalysis: [],
    prevFollowUpAnalysis: [],
    activeTab: 0,
    isLoading: false,
    isFavoriteLoading: false,
    isRemoveFavoriteLoading: false,
    view: false,
    startTime: new Date(),
    totalTimetaken: "",
    sortedArray: [],
    isOpened: false,
    isFavorite: false,
    isFavoritePopUp: false,
    FavoritesData: [],
    selectedFavourite: { case_name: "" },
    openCreateWorkSpacePopUp: false,
    isMobile: false,
    element: { index: "", isOpened: false },
    selectedCase: "",
    workspace_name: "",
    workspace_id: 0,
    workspace_item_id: 0,
    disableAddToWorkspace: false,
    selectedWorkSpaces: [],
    showAllWorkspaces: false,
    workspace_created_by: 0,
    ws_shared_users: [],
    fromDashBoard:false
  };

  // onResizableDivResize = (e, { size }) => {
  //   this.setState({ resizableHeight: size.height });
  // };
  toggleTab = (tabId) => {
    this.setState({ activeTab: tabId }, () => {
      this.props.updateCaseAnalysisState(this.state);
    });
  };
  getFavorites = () => {
    // if(this.state.selectedDocType){
    const axiosInstance = getAxiosInstance(
      this.props.token,
      false,
      this.props.history
    );
    axiosInstance
      .get("/FetchCaseAnalysisFavorites", { timeout: 180000 })
      .then((res) => {
        this.setState({ ...this.state, FavoritesData: res?.data }, () =>
          this.props.updateCaseAnalysisState(this.state)
        );
        let dashBoardState = this.props.dashBoardState;
        if (dashBoardState && dashBoardState.source) {
          let data = res?.data?.find(
            (x) => x.json_favorite_id === dashBoardState.favorite_id
          );
          if (data) {
            this.handleCallback(data);
          }
          this.handleLinkFromDashBoard(dashBoardState);
        }
      });
    // }
  };

  componentDidMount() {
    setTitle("Case Analysis");
    this.scrollToBottom();

    var mobile = isMobile();
    this.setState({ ...this.state, isMobile: mobile });
    let dashBoardState = this.props.dashBoardState;

    if (dashBoardState && dashBoardState.source) {
      this.setState(
        {
          ...this.state,
          isFavoritePopUp:false,
          caseAnalysisResult:"",
          isLoading: true,
          workspace_id: 0,
          workspace_item_id: 0,
          workspace_name: "",
          loadingText:"Loading..."
        },
        () => this.props.updateCaseAnalysisState(this.state)
      );
      this.getFavorites();
    } else {
      this.setState({ fromDashBoard: false }, () => {
        this.props.updateCaseAnalysisState(this.state);
      });
      this.getFavorites();
      this.getMessages();
    }
    // this.getMessages();
  }
  componentDidUpdate() {
    if (this.state.prevFollowUpAnalysis !== this.state.FollowUpAnalysis)
      this.scrollToBottom();
  }

  constructor(props) {
    super(props);
    this.secondDivRef = React.createRef();
    this.state = this.props.caseAnalysisState;
  }

  handleLinkFromDashBoard(dashBoardState) {
    let stateObj = {};
    if (dashBoardState.linkType.length > 0) {
      stateObj = {
        fromDashBoardLinkType: dashBoardState.linkType,
        isLoading: false,
        isFavoritePopUp:true,
        
      };
    } else {
      stateObj = {
        workspace_id: 0,
        workspace_item_id: 0,
        caseAnalysisResult:"",
        workspace_name: "",
        fileName: dashBoardState.fileName,
        selectedDocType: dashBoardState?.selectedDocType.trim(),
        selectedEngine: dashBoardState?.selectedEngine
          ? dashBoardState.selectedEngine
          : 1,
        isLoading: false,
      };
    }
    switch (dashBoardState.source) {
      case "favorites":
        this.setState(
          {
            ...stateObj,
            fromDashBoard: true,
            favdisplayval:
              stateObj.hasOwnProperty("fromDashBoardLinkType") > 0
                ? stateObj.fromDashBoardLinkType.length > 0
                  ? true
                  : false
                : false,
          },
          () => {
            dashBoardState = {
              source: "",
              moduleId: "",
              fileName: "",
              activeTab: 0,
              selectedDocType: "",
              selectedEngine: 1,
            };
            this.props.updateDashBoardState(dashBoardState);
            this.props.updateCaseAnalysisState(this.state);
          }
        );
        break;

      default:
        this.setState(
          {
            ...stateObj,
            fromDashBoard: false,
          },
          () => {
            let dashBoardState = {
              source: "",
              moduleId: "",
              fileName: "",
              activeTab: 0,
              selectedDocType: "",
              selectedEngine: 1,
            };
            this.props.updateDashBoardState(dashBoardState);
            this.props.updateCaseAnalysisState(this.state);
          }
        );
        break;
    }
  }

  scrollToBottom() {
    const secondDivElement = this.secondDivRef.current;
    secondDivElement.scrollTop = secondDivElement.scrollHeight;
    this.setState(
      {
        prevFollowUpAnalysis: this.state.FollowUpAnalysis,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  }
  handleBottomPanel = (isOpen) => {
    this.setState(
      {
        isBottomPanelOpen: isOpen,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  };

  handleValuesChange = (e) => {
    // console.log(e.target.id);
    let casedetails = this.state.Case_Details;
    // let { validation_errors } = this.state;
    casedetails[e.target.id] = e.target.value;
    let disableAddToWorkspace = this.state.disableAddToWorkspace;
    let showWorkspaceButton = this.state.showWorkspaceButton;
    // validation_errors[e.target.id] = "";
    if (
      this.state.selectedCase !==
      this.state.Case_Details.plaintiff +
        "-" +
        this.state.Case_Details.defendant +
        "(" +
        this.state.Case_Details.case_number +
        ")"
    ) {
      showWorkspaceButton = false;
    } else {
      showWorkspaceButton = true;
    }
    this.setState(
      {
        disableAddToWorkspace: disableAddToWorkspace,
        Case_Details: casedetails,
        showWorkspaceButton: showWorkspaceButton,
        // validation_errors,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  };

  handleFollowupValuesChange = (e) => {
    // console.log(e.target.id);
    let FollowUp_Details = this.state.FollowUp_Details;
    // let { validation_errors } = this.state;
    FollowUp_Details[e.target.id] = e.target.value;
    // validation_errors[e.target.id] = "";

    this.setState(
      {
        FollowUp_Details,
        // validation_errors,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  };

  handleValidation = (details) => {
    let isValid = true;

    let empty_fields = "";

    // Object.keys(Case_Details).forEach((key) => {
    //   if (Case_Details[key] === "") {
    //     empty_fields = key;
    //     break;
    //   }
    // });
    for (const key in details) {
      if (details.hasOwnProperty(key)) {
        if (
          key !== "Statute_of_Limitations" &&
          key !== "additionalInformation" &&
          (details[key] === "" || details[key] === undefined)
        ) {
          empty_fields = key;
          break;
        }
      }
    }

    if (empty_fields !== "") {
      isValid = false;
    }
    return { isValid: isValid, key: empty_fields };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        FollowUpAnalysis: [],
        caseAnalysisResult: "",
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
    let { validation_errors } = this.state;
    const { Case_Details } = this.state;
    Case_Details.relevant_documents = " ";
    Case_Details.caseName = this.state.Case_Details.caseName;
    let res = this.handleValidation(Case_Details);
    //let startTime = new Date().getTime();
    this.setState({
      startTime: new Date().getTime(),
    });
    // If there are no errors, you can proceed with form submission
    if (res.isValid) {
      if (
        this.state.selectedCase !==
        this.state.Case_Details.plaintiff +
          "-" +
          this.state.Case_Details.defendant +
          "(" +
          this.state.Case_Details.case_number +
          ")"
      ) {
        this.setState(
          {
            ...this.state,
            FollowUpAnalysis: [],
            disableAddToWorkspace: false,
            workspace_id: 0,
            workspace_item_id: 0,
          },
          () => {
            this.props.updateCaseAnalysisState(this.state);
          }
        );
      }
      if (
        this.state.FavoritesData &&
        this.state.FavoritesData.filter(
          (x) =>
            x.case_name ===
            this.state.Case_Details.plaintiff +
              "-" +
              this.state.Case_Details.defendant +
              "(" +
              this.state.Case_Details.case_number +
              ")"
        ).length > 0
      ) {
        var jsonData = {
          Case_Details: this.state.Case_Details,
          FollowUp_Details: this.state.FollowUp_Details,
        };

        const axiosInstance = getAxiosInstance(
          this.props.token,
          false,
          this.props.history
        );
        let formData = new FormData();
        formData.append("favoritesstr", JSON.stringify(jsonData));
        formData.append(
          "fileName",
          JSON.stringify(
            `${this.state.Case_Details.plaintiff}-${this.state.Case_Details.defendant}(${this.state.Case_Details.case_number})`
          )
        );
        axiosInstance
          .post("/CaseAnalysisFavorite", formData, {
            timeout: 180000,
          })
          .then((response) => {});
      }

      this.handleCaseAnalysis();
      this.handleFollowupSubmit();
    } else {
      // let empty_temp = "";
      // empty_fields.forEach((item) => {
      //   empty_temp += " " + validation_errors[empty_fields] + ", ";
      // });
      this.setState({ activeTab: 0 }, () => {
        this.props.updateCaseAnalysisState(this.state);
      });

      Swal.fire({
        text:
          "Please make sure to fill all the fields. Field " +
          validation_errors[res.key] +
          " is empty",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

    //let endTime = ((this.state.endTime - this.state.startTime) / 1000).toFixed(2) +"s"
    // this.setState({
    //     timetaken:
    //         ((this.state.endTime - this.state.startTime) / 1000).toFixed(2) +
    //         "s",
    // })
  };

  handleFollowupSubmit = () => {
    let { validation_errors } = this.state;
    const { FollowUp_Details } = this.state;
    let inputs = this.buildFollowUpInputs(FollowUp_Details);
    for (let index = 0; index < inputs.length; index++) {
      const element = inputs[index];
      // inputs.forEach(element => {
      if (Object.keys(inputs).length !== 0) {
        let res = this.handleValidation(inputs);
        if (res.isValid) {
          this.handleFollowUpAnalysis(element);
        } else {
          this.setState({ activeTab: 1 }, () => {
            this.props.updateCaseAnalysisState(this.state);
          });

          Swal.fire({
            text:
              "Please make sure to fill all the fields in follow up analysis. Field " +
              validation_errors[res.key] +
              " is empty",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        this.setState({ activeTab: 1 }, () => {
          this.props.updateCaseAnalysisState(this.state);
        });

        Swal.fire({
          text: "Please make sure to fill all the fields in follow up analysis.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  buildFollowUpInputs = (details) => {
    try {
      let obj = {};
      let allDetails = [];
      obj = {
        Nature_of_Incident: details.Nature_of_Incident,
        Injury: details.Injury,
        Treatment_Received: details.Treatment_Received,
        Medical_Expense: details.Medical_Expense,
        Lost_Wages: details.Lost_Wages,
        Downtime: details.Downtime,
        Pain_Suffering: details.Pain_Suffering,
      };
      allDetails.push({ caseType: "Fact_Check", obj: obj });
      allDetails.push({ caseType: "Damage_Assessment", obj: obj });
      obj = {
        Nature_of_Incident: details.Nature_of_Incident,
        Injury: details.Injury,
        Treatment_Received: details.Treatment_Received,
        Parties_Behavior: details.Parties_Behavior,
        Laws_Regulations: details.Laws_Regulations,
        Pain_Suffering: details.Pain_Suffering,
      };
      allDetails.push({
        caseType: "Comparative_Fault_Analysis",
        obj: obj,
      });
      obj = {
        Nature_of_Incident: details.Nature_of_Incident,
        Injury: details.Injury,
        Treatment_Received: details.Treatment_Received,
        Pain_Suffering: details.Pain_Suffering,
        Expert_Testimony: details.Expert_Testimony
          ? details.Expert_Testimony
          : "",
      };
      allDetails.push({ caseType: "Medical_Record", obj: obj });

      obj = {
        Nature_of_Incident: details.Nature_of_Incident,
        Analysis_Questions: details.Analysis_Questions,
      };
      allDetails.push({ caseType: "Precedent_Research", obj: obj });

      obj = {
        Nature_of_Incident: details.Nature_of_Incident,
        Injury: details.Injury,
        Treatment_Received: details.Treatment_Received,
        Medical_Expense: details.Medical_Expense,
        Lost_Wages: details.Lost_Wages,
        Downtime: details.Downtime,
        Pain_Suffering: details.Pain_Suffering,
        Liability: details.Liability,
        Insurance_Coverage: details.Insurance_Coverage,
        Plaintiff_Req: details.Plaintiff_Req,
        Defendent_Position: details.Defendent_Position,
        Precedents: details.Precedents ? details.Precedents : "",
      };
      allDetails.push({ caseType: "Settlement_Analysis", obj: obj });

      obj = {
        Claims: details.Claims,
        Evidence: details.Evidence,
        Laws_Precedents: details.Laws_Precedents,
        Parties: details.Parties ? details.Parties : "",
        Legal_Team: details.Legal_Team,
      };
      allDetails.push({ caseType: "Litigation_Strategy", obj: obj });

      obj = {
        Liability: details.Liability,
        Evidentiary: details.Evidentiary ? details.Evidentiary : "",
        Legal_Precedents: details.Legal_Precedents
          ? details.Legal_Precedents
          : "",
        Opposing_Party: details.Opposing_Party ? details.Opposing_Party : "",
        Plaintiff_Position: details.Plaintiff_Position
          ? details.Plaintiff_Position
          : "",
      };
      allDetails.push({ caseType: "Risk_Assessment", obj: obj });

      return allDetails;
    } catch (error) {
      Swal.fire({
        text: "We are unable to analyze the give case, Please try some other case.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return {};
    }
  };

  handleFollowUpAnalysis = (inputs) => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );

      console.log("Inside follow up analysis");
      const formData = new FormData();
      // const question = this.state.inputValue;
      // const { FollowUp_Details } = this.state;
      const case_type = inputs.caseType;

      let case_details = {
        case_details: this.state.Case_Details,
        followup_details: inputs.obj,
      };

      formData.append("case_type", case_type);
      formData.append("case_details", JSON.stringify(case_details));
      formData.append("engine_type", this.state.engine_type);

      //let startTime = new Date().getTime();
      const axiosInstance = getAxiosInstance(
        this.props.token,
        false,
        this.props.history
      );
      axiosInstance
        .post("/performFollowUpCaseAnalysis", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 180000,
        })
        .then((response) => {
          //let endTime = new Date().getTime();
          console.log("Chat Response chatting", response.data);
          if (response.data.error_Msg) {
            this.setState(
              {
                isLoading: false,
                // caseAnalysisResult: "",
                //  endTime: endTime,
                timetaken:
                  ((new Date() - this.state.startTime) / 1000).toFixed(2) + "s",
                // isAnalysed: false,
              },
              () => {
                this.props.updateCaseAnalysisState(this.state);
              }
            );
            // toast.error(
            //   "We are unable to process the chat questions. Maybe try another file."
            // );
            Swal.fire({
              text: "We are unable to analyze the give case, Please try some other case.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            let obj = {
              // question: this.state.selectedFollowup.value,
              question: followup_prompts_ddl.personal_injury_Canned_Ques.filter(
                (x) => x.id === case_type
              )[0].value,
              response: response.data.result,
              time: new Date().toLocaleString(),
            };
            const temp_data = this.state.FollowUpAnalysis;
            temp_data.push(obj);
            var sortedData = [];
            var showWorkspaceButton = this.state.showWorkspaceButton;
            if (
              temp_data.length ===
              followup_prompts_ddl.personal_injury_Canned_Ques.length
            ) {
              followup_prompts_ddl.personal_injury_Canned_Ques.forEach(
                (element) => {
                  sortedData.push(
                    temp_data.filter((x) => x.question === element.value)[0]
                  );
                }
              );
              //if workspace, re anlayze content saved automatically

              if (
                this.state.workspace_id > 0 &&
                this.state.workspace_item_id > 0 &&
                this.state.selectedCase ===
                  this.state.Case_Details.plaintiff +
                    "-" +
                    this.state.Case_Details.defendant +
                    "(" +
                    this.state.Case_Details.case_number +
                    ")"
              ) {
                this.handleWorkspace();
              }
              showWorkspaceButton = true;
            }

            this.setState(
              {
                FollowUpAnalysis: temp_data,
                // isLoading: false,
                timetaken:
                  ((new Date() - this.state.startTime) / 1000).toFixed(2) + "s",
                totalTimetaken:
                  ((new Date() - this.state.startTime) / 1000).toFixed(2) + "s",
                sortedArray: sortedData,
                showWorkspaceButton: showWorkspaceButton,
              },
              () => {
                this.props.updateCaseAnalysisState(this.state);
              }
            );
          }
        })
        .catch((error) => {
          this.setState(
            {
              isLoading: false,
              // caseAnalysisResult: "",

              // isAnalysed: false,
            },
            () => {
              this.props.updateCaseAnalysisState(this.state);
              let status = error.response ? error.response.status : 200;
              if (status === 401 || status === 422) {
                this.props.removeToken();
              }
            }
          );
          console.log("API Error:", error);
          // toast.error(
          //   "We are unable to process this file at this time. Please try another file"
          // );

          Swal.fire({
            text: "We are unable to analyse the given case, Please try some other case.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } catch (error) {
      console.log("Error in handle Case Analysis", error);
      Swal.fire({
        text: "We are unable to analyse the given case, Please try some other case.",
        icon: "error",
        confirmButtonText: "OK",
      });

      this.setState(
        {
          isLoading: false,
          // caseAnalysisResult: "",

          // isAnalysed: false,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    }
  };

  handleCaseAnalysis = () => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
      const formData = new FormData();
      // const question = this.state.inputValue;
      const { Case_Details } = this.state;

      // formData.append("question", question);
      // formData.append("unique_id", this.state.unique_id);
      formData.append("case_type", this.state.selectedDocType + "_Base_Case");
      formData.append("case_details", JSON.stringify(Case_Details));
      formData.append("engine_type", this.state.engine_type);
      // let startTime = new Date().getTime();

      const axiosInstance = getAxiosInstance(
        this.props.token,
        false,
        this.props.history
      );
      axiosInstance
        .post("/performCaseAnalysis", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 180000,
        })
        .then((response) => {
          // let endTime = new Date().getTime();
          console.log("Chat Response chatting", response.data);
          if (response.data.error_Msg) {
            this.setState(
              {
                isLoading: false,
                // timetaken:
                // ((endTime - startTime) / 1000).toFixed(2) +
                // "s",
                caseAnalysisResult: "",

                isAnalysed: false,
              },
              () => {
                this.props.updateCaseAnalysisState(this.state);
              }
            );

            Swal.fire({
              text: "We are unable to analyze the give case, Please try some other case.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            this.setState(
              {
                isLoading: false,
                caseAnalysisResult: response.data.result,
                isAnalysed: true,
                view: false,
                selectedCase:
                  this.state.Case_Details.plaintiff +
                  "-" +
                  this.state.Case_Details.defendant +
                  "(" +
                  this.state.Case_Details.case_number +
                  ")",
              },
              () => {
                this.props.updateCaseAnalysisState(this.state);
              }
            );
          }
        })
        .catch((error) => {
          this.setState(
            {
              isLoading: false,
              caseAnalysisResult: "",
              isAnalysed: false,
            },
            () => {
              this.props.updateCaseAnalysisState(this.state);
              let status = error.response ? error.response.status : 200;
              if (status === 401 || status === 422) {
                this.props.removeToken();
              }
            }
          );
          console.log("API Error:", error);
          // toast.error(
          //   "We are unable to process this file at this time. Please try another file"
          // );
          Swal.fire({
            text: "We are unable to analyse the given case, Please try some other case.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } catch (error) {
      console.log("Error in handle Case Analysis", error);
      Swal.fire({
        text: "We are unable to analyse the given case, Please try some other case.",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState(
        {
          isLoading: false,
          caseAnalysisResult: "",
          isAnalysed: false,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    }
  };

  handleDocTypeChange = (event) => {
    const { value } = event.target;
    const selectedLabel = event.target.options[event.target.selectedIndex].text;
    this.setState(
      {
        selectedDocType: value,
        selectedLabel,
        isAnalysed: false,
        prevFollowUpAnalysis: [],
        FollowUpAnalysis: [],
        view: true,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  };

  handleNewDoc = () => {
    let initialstate = initialState.caseAnalysisState;
    var caseDetails = {};
    var followUpdetails = {};
    for (const [key] of Object.entries(this.state.Case_Details)) {
      caseDetails[key] = "";
    }
    for (const [key] of Object.entries(this.state.FollowUp_Details)) {
      followUpdetails[key] = "";
    }

    this.setState(
      {
        ...initialstate,
        Case_Details: caseDetails,
        FollowUp_Details: followUpdetails,
        selectedDocType: "",
        caseAnalysisResult: "",
        workspace_id: 0,
        workspace_item_id: 0,
        workspace_name: "",
        isAnalysed: false,
        startTime: new Date(),
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
        this.getFavorites();
      }
    );
  };

  handleFollowUpSelect = (question) => {
    try {
      this.setState(
        {
          activeTab: 1,
          selectedFollowup: question,
          isBottomPanelOpen: true,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  handleToggleDocumentViewer = (val) => {
    var mobile = isMobile();
    this.setState({
      ...this.state,
      view: !this.state.view,
      isMobile: mobile,
    });
  };
  handleCOllapse = () => {
    this.setState({ ...this.state, isOpened: !this.state.isOpened });
    followup_prompts_ddl.personal_injury_Canned_Ques.forEach(
      (element, index) => {
        if (this.state.isOpened) {
          document.getElementById(`handle2${index}`).checked = false;
        } else {
          document.getElementById(`handle2${index}`).checked = true;
        }
        // document.getElementById(`handle2${index}`).click()
      }
    );
  };
  RemoveFavourite = (casename) => {
    this.setState({ ...this.state, isRemoveFavoriteLoading: true });
    const axiosInstance = getAxiosInstance(
      this.props.token,
      false,
      this.props.history
    );
    let formData = new FormData();
    formData.append("case_name", casename);
    axiosInstance
      .post("/RemoveCaseAnalysisFavorite", formData, {
        timeout: 180000,
      })
      .then((response) => {
        if (response.data["status"] === 200) {
          ToastAlert("Favorite removed successfully");
          this.setState({
            ...this.state,
            isRemoveFavoriteLoading: false,
          });
          this.getFavorites();
        } else {
          this.setState({
            ...this.state,
            isRemoveFavoriteLoading: false,
          });
        }
      });
  };

  handleFavourite = () => {
    let res = this.handleValidation(this.state.Case_Details);
    if (res.isValid) {
      this.setState({ ...this.state, isFavoriteLoading: true });
      var jsonData = {
        Case_Details: this.state.Case_Details,
        FollowUp_Details: this.state.FollowUp_Details,
      };

      const axiosInstance = getAxiosInstance(
        this.props.token,
        false,
        this.props.history
      );
      let formData = new FormData();
      formData.append("favoritesstr", JSON.stringify(jsonData));
      formData.append(
        "fileName",
        JSON.stringify(
          `${this.state.Case_Details.plaintiff}-${this.state.Case_Details.defendant}(${this.state.Case_Details.case_number})`
        )
      );
      axiosInstance
        .post("/CaseAnalysisFavorite", formData, {
          timeout: 180000,
        })
        .then((response) => {
          if (response.data["status"] === 200) {
            ToastAlert("Favorited this case successfully");
            // Swal.fire({
            //     text:
            //         "Favorited this case successfully",
            //     icon: "success",
            //     confirmButtonText: "OK",
            // });
            this.setState({
              ...this.state,
              isFavoriteLoading: false,
            });
            this.getFavorites();
          } else {
            this.setState({
              ...this.state,
              isFavoriteLoading: false,
            });
          }
        });
    } else {
      Swal.fire({
        text: "Please make sure to fill all the fields in case assessment. ",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  FavouritesPopUp = () => {
    this.setState({ ...this.state, isFavoritePopUp: true });
  };
  closepopup = () => {
    this.setState({
      ...this.state,
      isFavoritePopUp: !this.state.isFavoritePopUp,
    },()=>this.props.updateCaseAnalysisState(this.state));
    if (this.state?.fromDashBoard) {
      this.props.history("/dashboard")
    }
  };
  handleCallback = async (data) => {
    this.setState(
      {
        ...this.state,
        workspace_id: 0,
        workspace_item_id: 0,
        workspace_name: "",
      },
      () => this.props.updateCaseAnalysisState(this.state)
    );
    const result =
      this.state.caseAnalysisResult !== "" &&
      (await Swal.fire({
        html: "Do you want to clear the follow ups?",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#2eaadb",
        cancelButtonTextColor: "#2eaadb",
        cancelButtonColor: "#2eaadb",
        confirmButtonText: "Yes",
        confirmButtonTextColor: "#2eaadb",
        focusDeny: true,
      }));

    var caseDetails = JSON.parse(data.json_data).Case_Details;
    var followUpDetails = JSON.parse(data.json_data).FollowUp_Details;
    caseDetails.caseName =
      caseDetails.caseName === undefined ? "" : caseDetails.caseName;
    caseDetails.Statute_of_Limitations =
      caseDetails.Statute_of_Limitations === undefined
        ? ""
        : caseDetails.Statute_of_Limitations;
    followUpDetails.Additional_Information =
      followUpDetails.Additional_Information === undefined
        ? ""
        : followUpDetails.Additional_Information;
    let dashBoardState = this.props.dashBoardState;
    if (result.isConfirmed || (dashBoardState && dashBoardState?.source)) {
      // this.setState({
      //     ...this.state,
      //     Case_Details: this.state.empty_case_Details,
      //     FollowUp_Details: this.state.empty_FollowUp_Details,
      // });

      this.setState(
        {
          ...this.state,
          selectedFavourite: data,
          caseAnalysisResult: "",
          Case_Details: caseDetails,
          FollowUp_Details: followUpDetails,
          isFavoritePopUp: false,
          selectedDocType: data?.document_type,
          view: true,
          isFavoriteLoading: false,
          fromDashBoard:false
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          selectedFavourite: data,
          caseAnalysisResult: this.state.caseAnalysisResult,
          Case_Details: caseDetails,
          FollowUp_Details: followUpDetails,
          isFavoritePopUp: false,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    }
  };

  sortByIdAsc = (items) => {
    const sortedItems = [...items].sort((a, b) => {
      return a.id - b.id;
    });
    return sortedItems;
  };
  getMessages = () => {
    if (
      this.state.module_id === 5 &&
      this.state.workspace_id > 0 &&
      this.state.workspace_item_id > 0
    ) {
      try {
        this.setState(
          {
            isLoading: true,
            disableAddToWorkspace: true,
            openCreateWorkSpacePopUp: false,
            selectedFile: {},
            reviewTimer: 0 + "s",
            selectedDocType: "",
          },
          () => {
            this.props.updateCaseAnalysisState(this.state);
          }
        );
        const axiosInstance = getAxiosInstance(
          this.props.token,
          false,
          this.props.history
        );
        axiosInstance
          .get(
            `/workspace/item?ws_id=${this.state.workspace_id}&ws_item_id=${this.state.workspace_item_id}`,
            { timeout: 180000 }
          )
          .then((res) => {
            if (res?.data?.messages?.length > 0) {
              let documentType =
                res?.data.documents.length > 0
                  ? res?.data.documents[0].documentType
                  : "";
              let { Case_Details, FollowUp_Details } = JSON.parse(
                res?.data.messages[0].query
              );
              let { caseAnalysisResult, FollowUpAnalysis } = JSON.parse(
                res?.data.messages[0].messages
              );

              this.setState(
                {
                  ...this.state,
                  ws_shared_users: res?.data?.shared_users,
                  workspace_created_by: res?.data?.shared_users?.find(
                    (x) => x.permissions === "owner"
                  )?.user_id,
                  engine_type: res?.data?.engine_type,
                  isLoading: false,
                  documentType: documentType,
                  Case_Details: Case_Details,
                  FollowUp_Details: FollowUp_Details,
                  caseAnalysisResult: caseAnalysisResult,
                  FollowUpAnalysis: FollowUpAnalysis,
                  selectedDocType: documentType,
                  showWorkspaceButton: true,
                  selectedCase:
                    Case_Details.plaintiff +
                    "-" +
                    Case_Details.defendant +
                    "(" +
                    Case_Details.case_number +
                    ")",
                  timetaken: res?.data.messages[0].timeTaken + "s",
                },
                () => this.props.updateCaseAnalysisState(this.state)
              );
            }
          });
      } catch (ex) {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.props.updateCaseAnalysisState(this.state);
          }
        );
      }
    }
  };

  closeShowAllWorkspacesPopup = (data) => {
    if (data?.length > 0) {
      this.setState(
        {
          ...this.state,
          showAllWorkspaces: false,
          workspace_name: data[0].name,
          workspace_id: data[0].id,
          workspace_item_id: data[0].workspace_item_id,
          openCreateWorkSpacePopUp: false,
          disableAddToWorkspace: true,
          workspace_created_by:parseInt(store?.getState()?.login?.logInState?.user_id),
          isSharedWorkspace: parseInt(data[0]?.shared_users) > 1 ? true : false,
        },
        () => {
          this.props.updateCaseAnalysisState(this.state);
        }
      );
    }
  };

  closeAllWorkspacesPopup = () => {
    this.setState(
      {
        ...this.state,
        showAllWorkspaces: false,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
      }
    );
  };

  CloseWorkspaceListPopUp = (data, res) => {
    this.setState(
      {
        openCreateWorkSpacePopUp: !this.state.openCreateWorkSpacePopUp,
        disableAddToWorkspace: false,
        selectedWorkSpaces: data,
        showAllWorkspaces: true,
      },
      () => {
        this.props.updateCaseAnalysisState(this.state);
        if (data?.length === 1 && res?.added?.length === 1) {
          this.closeShowAllWorkspacesPopup(data);
        }
      }
    );
  };


  RedirectToWorkspaceItems = () => {
    const { history } = this.props;
    let state = {
      workspace_id: this.state.workspace_id,
      workspace_name: this.state.workspace_name,
      data: {
        id: this.state.workspace_id,
        name: this.state.workspace_name,
        users: this.state.ws_shared_users,
        created_by: this.state.workspace_created_by,
      },
    };
    history("/workspaces", { state: state });
  };

  handleWorkspace = () => {
    const axiosInstance = getAxiosInstance(
      this.props.token,
      false,
      this.props.history
    );
    var ws_item = {
      module_id: 5,
      messages: [
        {
          response: JSON.stringify({
            caseAnalysisResult: this.state.caseAnalysisResult,
            FollowUpAnalysis: this.state.FollowUpAnalysis,
          }),
          query: JSON.stringify({
            Case_Details: this.state.Case_Details,
            FollowUp_Details: this.state.FollowUp_Details,
          }),
          response_build_time: this.state.timetaken?.replace("s", ""),
          chat_id: "",
        },
      ],
      documents: [
        {
          content: this.state.Case_Details.plaintiff,
          name:
            this.state.Case_Details.plaintiff +
            "-" +
            this.state.Case_Details.defendant +
            "(" +
            this.state.Case_Details.case_number +
            ")",
          fileType: "",
        },
      ],
      document_type: this.state.selectedDocType,
    };
    let obj = {
      workspace_id: this.state.workspace_id,
      workspace_item_id: this.state.workspace_item_id,
      ws_item: ws_item,
    };
    axiosInstance
      .put("/workspace/item", obj, { timeout: 180000 })
      .then((response) => {});
  };

  render() {
    const { FollowUp_Details, activeTab } = this.state;
    return (
        <Fragment>
            {this.state.showAllWorkspaces && (
                <ShowAllWorkspacePopUp
                    selectedWorkspaces={this.state.selectedWorkSpaces}
                    closeShowAllWorkspacesPopup={
                        this.closeShowAllWorkspacesPopup
                    }
                    closepopup={this.closeAllWorkspacesPopup}
                    document_name={this.state.selectedFile?.file?.name}
                />
            )}

            {this.state.openCreateWorkSpacePopUp && (
                <WorkSpaceListPopUp
                    workSpaceDisabled={false}
                    state={this.state}
                    query={JSON.stringify({
                        Case_Details: this.state.Case_Details,
                        FollowUp_Details: this.state.FollowUp_Details,
                    })}
                    content={JSON.stringify({
                        caseAnalysisResult: this.state.caseAnalysisResult,
                        FollowUpAnalysis: this.state.FollowUpAnalysis,
                    })}
                    baseContent={""}
                    docsContent={[
                        {
                            content: this.state.Case_Details.plaintiff,
                            fileName:
                                this.state.Case_Details.plaintiff +
                                "-" +
                                this.state.Case_Details.defendant +
                                "(" +
                                this.state.Case_Details.case_number +
                                ")",
                            fileType: "",
                        },
                    ]}
                    document_type={this.state.selectedDocType}
                    document_name={
                        this.state.Case_Details.plaintiff +
                        "-" +
                        this.state.Case_Details.defendant +
                        "(" +
                        this.state.Case_Details.case_number +
                        ")"
                    }
                    handwritten_text_flag={false}
                    timeTaken={this.state.timetaken?.replace("s", "")}
                    CannedQuestionsList={[]}
                    index_path={""}
                    closepopup={(data, res) =>
                        this.CloseWorkspaceListPopUp(data, res)
                    }
                    closeWsListpopup={() => {
                        this.setState({
                            ...this.state,
                            openCreateWorkSpacePopUp:
                                !this.state.openCreateWorkSpacePopUp,
                            disableAddToWorkspace: false,
                        });
                    }}
                    module_id={5}
                    engine_type={this.state.engine_type}
                />
            )}
            {this.state.isFavoritePopUp && (
                <CaseAnalysisPopup
                    title={"Case Analysis Favorites"}
                    content={this.state.FavoritesData}
                    closepopup={() => this.closepopup()}
                    callback={this.handleCallback}
                    removeFavoriteCallback={this.RemoveFavourite}
                />
            )}
            <div className="rytpaneltoppart m-b-0 p-b-2">
                <div className="reviewfirstdiv">
                    <div className="reviewfirstdiv">
                        <h2>Case Assessment</h2>
                    </div>
                    {this.state.selectedDocType !== "" && (
                        <div className="fullfieldwidth m-t-none">
                            <button
                                className="newDoc"
                                onClick={() => this.handleNewDoc()}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Review a new document"
                            >
                                New
                            </button>
                        </div>
                    )}
                </div>
                {/* <div className="d-flex"> */}
                <div className="reviewdescriptionsec">
                    {this.state.selectedDocType !== "" && (
                        <div className="fullfieldwidth m-t-none">
                            <HandleEngineComponent
                                selectedEngine={this.state.engine_type}
                                handleEngineChangeCallback={(e, l) => {
                                    this.setState({
                                        ...this.state,
                                        engine_type: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    )}

                    <div className="fullfieldwidth m-t-none">
                        <div
                            className="fieldsec"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Document type"
                        >
                            <select
                                className="selectfield"
                                onChange={this.handleDocTypeChange}
                                value={this.state.selectedDocType}
                            >
                                <option
                                    selected="selected"
                                    value={""}
                                    disabled
                                    hidden
                                >
                                    Choose Case Type
                                </option>
                                {docTypes_ddl.CaseAnalysis.sort((a, b) =>
                                    a.documentName.localeCompare(b.documentName)
                                ).map((item, i) => (
                                    <option value={item.documentID} key={i}>
                                        {item.documentName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="border-bottom" /> */}

            <div className=" full_screen midPanel_Container midPanel_Container_gap">
                <div className="midPanel_ca_Item1 width-0">
                    <div
                        ref={this.secondDivRef}
                        id="midPanel"
                        className={"rytpanelmidpart overflow-scroll"}
                    >
                        {/* !this.state.isBottomPanelOpen
                             ? "rytpanelmidpart"
                           : "rytpanelmidpartWithBottom_CA"*/}
                        {this.state.caseAnalysisResult !== "" ? (
                            <div className="reviewdatasec overflow-unset mb-100">
                                <div className="header-sticky">
                                    <div
                                        className={`rytpaneltoppart p-r-5  titlesec title-display`}
                                    >
                                        <div style={{ display: "block" }}>
                                            <h3 className="line-height-normal">
                                                Case Assessment
                                            </h3>
                                            <div className="contentMetadata">
                                                {this.state.timetaken}
                                            </div>
                                        </div>
                                        {store.getState().login.logInState
                                            .usertypeid !== "4" ? (
                                            <div className="reviewdescriptionsec">
                                                {" "}
                                                {this.state
                                                    .showWorkspaceButton &&
                                                    (this.state
                                                        .disableAddToWorkspace ? (
                                                        <div className="d-flex flex-wrap">
                                                            Document auto saved
                                                            in
                                                            <div
                                                                className="text-link m-10"
                                                                onClick={
                                                                    this
                                                                        .RedirectToWorkspaceItems
                                                                }
                                                            >
                                                                {
                                                                    this.state
                                                                        .workspace_name
                                                                }
                                                            </div>
                                                            Workspace{" "}
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className={`newDoc btn-review plus-square-icon ${
                                                                this.state
                                                                    .disableAddToWorkspace
                                                                    ? "disable"
                                                                    : ""
                                                            }`}
                                                            onClick={() => {
                                                                this.setState(
                                                                    {
                                                                        ...this
                                                                            .state,
                                                                        openCreateWorkSpacePopUp: true,
                                                                    },
                                                                    () => {
                                                                        this.props.updateCaseAnalysisState(
                                                                            this
                                                                                .state
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title={
                                                                "Add to workspace"
                                                            }
                                                        >
                                                            Add To Workspace
                                                        </button>
                                                    ))}
                                            </div>
                                        ) : (
                                            <>
                                                <div className=" btn-review  "></div>
                                            </>
                                        )}
                                        {followup_prompts_ddl
                                            .personal_injury_Canned_Ques
                                            .length ===
                                        this.state.FollowUpAnalysis.length ? (
                                            <div
                                                className={`${
                                                    followup_prompts_ddl
                                                        .personal_injury_Canned_Ques
                                                        .length ===
                                                    this.state.FollowUpAnalysis
                                                        .length
                                                        ? "display d-flex"
                                                        : ""
                                                }`}
                                            >
                                                <div className="d-flex flex-none">
                                                    <ContentOperations
                                                        Content={
                                                            this.state
                                                                .caseAnalysisResult
                                                        }
                                                        isSummary={false}
                                                        component="CaseAnalysis"
                                                        filename={undefined}
                                                        question={undefined}
                                                        isFromCaseAnalysis={
                                                            true
                                                        }
                                                        docType={
                                                            "Case Assessment"
                                                        }
                                                        isPrompts={
                                                            this.state
                                                                .sortedArray
                                                            // .length !== 0
                                                            // ? true
                                                            // : null
                                                        }
                                                        state={this.state}
                                                    />
                                                    <div
                                                        onClick={() =>
                                                            this.handleCOllapse()
                                                        }
                                                        className="elementwithcollapse flex-none"
                                                    >
                                                        <ul>
                                                            <li>
                                                                {" "}
                                                                {this.state
                                                                    .isOpened ? (
                                                                    <CollapseAllIcon />
                                                                ) : (
                                                                    <ExpandIcon />
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div className="sglereviewsec">
                                    <div className="reviewcontsec">
                                        <div className="readmoredata">
                                            <ReadMoreCollapse
                                                text={
                                                    this.state
                                                        .caseAnalysisResult
                                                }
                                                maxLength={500}
                                                isPrompts={
                                                    this.state.FollowUpAnalysis
                                                        .length !== 0
                                                        ? true
                                                        : null
                                                }
                                                engine_id={
                                                    this.state.selectedEngine
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                {followup_prompts_ddl.personal_injury_Canned_Ques.map(
                                    (i, j) => {
                                        return (
                                            <div
                                                className="sglereviewsec"
                                                key={i}
                                            >
                                                <div className="accordion_test">
                                                    <section className="accordion">
                                                        <input
                                                            type="checkbox"
                                                            name="collapse2"
                                                            id={`handle2${j}`}
                                                            hidden
                                                        />
                                                        <h2 className="handle">
                                                            <div className="cannedtitlesec">
                                                                <div className="namenmetadiv">
                                                                    {this.state.FollowUpAnalysis.filter(
                                                                        (x) =>
                                                                            x.question ===
                                                                            i.value
                                                                    ).length >
                                                                    0 ? (
                                                                        <label
                                                                            for={`handle2${j}`}
                                                                        >
                                                                            <h4>
                                                                                {
                                                                                    i.value
                                                                                }{" "}
                                                                            </h4>
                                                                        </label>
                                                                    ) : (
                                                                        <h4>
                                                                            {
                                                                                i.value
                                                                            }
                                                                        </h4>
                                                                    )}
                                                                    <div className="metadata"></div>
                                                                </div>
                                                                {this.state.FollowUpAnalysis.filter(
                                                                    (x) =>
                                                                        x.question ===
                                                                        i.value
                                                                ).length ===
                                                                0 ? (
                                                                    <span className="loader"></span>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                    >
                                                                        <ContentOperations
                                                                            Content={
                                                                                this.state.FollowUpAnalysis.filter(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.question ===
                                                                                        i.value
                                                                                )[0]
                                                                                    .response
                                                                            }
                                                                            isSummary={
                                                                                false
                                                                            }
                                                                            component={
                                                                                this.state.FollowUpAnalysis.filter(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.question ===
                                                                                        i.value
                                                                                )[0]
                                                                                    .question
                                                                            }
                                                                            // component={followup_prompts_ddl.personal_injury_Canned_Ques.filter(x => x.id === item.question)[0].value}
                                                                            filename={
                                                                                undefined
                                                                            }
                                                                            question={
                                                                                undefined
                                                                            }
                                                                            docType={
                                                                                docTypes_ddl.CaseAnalysis.filter(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.documentID ===
                                                                                        this
                                                                                            .state
                                                                                            .selectedDocType
                                                                                )[0]
                                                                                    ?.documentName
                                                                            }
                                                                            isFromCaseAnalysis={
                                                                                false
                                                                            }
                                                                            enableCollpase={
                                                                                true
                                                                            }
                                                                            isPrompts={
                                                                                this
                                                                                    .state
                                                                                    .FollowUpAnalysis
                                                                                // .length !== 0
                                                                                // ? true
                                                                                // : null
                                                                            }
                                                                            index={
                                                                                j
                                                                            }
                                                                            collapseAll={
                                                                                this
                                                                                    .state
                                                                                    .isOpened
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </h2>
                                                        {this.state.FollowUpAnalysis.filter(
                                                            (x) =>
                                                                x.question ===
                                                                i.value
                                                        ).length > 0 && (
                                                            <div className="content">
                                                                <div className="reviewcontsec">
                                                                    <div className="readmoredata">
                                                                        <p
                                                                            style={{
                                                                                whiteSpace:
                                                                                    "pre-wrap",
                                                                            }}
                                                                            className="note"
                                                                        >
                                                                            {
                                                                                this.state.FollowUpAnalysis.filter(
                                                                                    (
                                                                                        x
                                                                                    ) =>
                                                                                        x.question ===
                                                                                        i.value
                                                                                )[0]
                                                                                    .response
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </section>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        ) : (
                            this.state.selectedDocType !== "" && (
                                <div className="parent">
                                    <div className="rectangle-box_CID">
                                        <div className="content">
                                            Please provide details to perform
                                            Case Assessment
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <Resizable
                    className={`midPanel_Item2 flex-grow-0 ${
                        this.state.view
                            ? "expandable-border-left  expandable-bar-box-shadow"
                            : "collapse"
                    }`}
                    handleClasses={{
                        top: "pointer-events-none",
                        bottom: "pointer-events-none",
                        left: this.state.view ? "" : "pointer-events-none",
                        topRight: "pointer-events-none",
                        bottomRight: "pointer-events-none",
                        bottomLeft: "pointer-events-none",
                        topLeft: "pointer-events-none",
                    }}
                    defaultSize={{ width: "47%", height: "auto" }}
                    key={"test1"}
                >
                    {this.state.selectedDocType && (
                        <SvgIconButton
                            isexpanded={this.state.view}
                            togglecallback={this.handleToggleDocumentViewer}
                        />
                    )}

                    {this.state.selectedDocType !== "" && (
                        <div id="botbar" className="rightpaneloverflow mb-6">
                            <div className="tabarea tabarea-sticky">
                                <div className="">
                                    <section className="accordion-tabs fav-tabs">
                                        <div>
                                            <button
                                                className={`accordion-tab ${
                                                    activeTab === 0
                                                        ? "accordion-active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    this.toggleTab(0)
                                                }
                                            >
                                                User Inputs
                                            </button>
                                            {this.state.FavoritesData &&
                                            this.state.FavoritesData.filter(
                                                (x) =>
                                                    x.case_name ===
                                                    this.state.Case_Details
                                                        .plaintiff +
                                                        "-" +
                                                        this.state.Case_Details
                                                            .defendant +
                                                        "(" +
                                                        this.state.Case_Details
                                                            .case_number +
                                                        ")"
                                            ).length > 0 ? (
                                                <button
                                                    title="Favorite"
                                                    className="accordion-tab fav-icon-size"
                                                    onClick={() =>
                                                        this.handleFavourite()
                                                    }
                                                >
                                                    {" "}
                                                    <FilledHeart></FilledHeart>
                                                </button>
                                            ) : (
                                                <button
                                                    title="Favorite"
                                                    className="accordion-tab fav-icon-size"
                                                    onClick={() =>
                                                        this.handleFavourite()
                                                    }
                                                >
                                                    {" "}
                                                    <RegularHeart />
                                                </button>
                                            )}
                                        </div>
                                        <div>
                                            {this.state.selectedCase ===
                                                this.state.Case_Details
                                                    .plaintiff +
                                                    "-" +
                                                    this.state.Case_Details
                                                        .defendant +
                                                    "(" +
                                                    this.state.Case_Details
                                                        .case_number +
                                                    ")" ||
                                            (!this.state.FavoritesData &&
                                                this.state.FavoritesData.filter(
                                                    (x) =>
                                                        x.case_name ===
                                                        this.state.Case_Details
                                                            .plaintiff +
                                                            "-" +
                                                            this.state
                                                                .Case_Details
                                                                .defendant +
                                                            "(" +
                                                            this.state
                                                                .Case_Details
                                                                .case_number +
                                                            ")"
                                                ).length > 0) ? (
                                                <button
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(46, 170, 219)",
                                                        // marginLeft: "58%",
                                                    }}
                                                    className="generatebut btn-align"
                                                    onClick={this.handleSubmit}
                                                >
                                                    re-analyze
                                                </button>
                                            ) : (
                                                <button
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(46, 170, 219)",
                                                        // marginLeft: "61%",
                                                    }}
                                                    className="generatebut btn-align"
                                                    onClick={this.handleSubmit}
                                                >
                                                    analyze
                                                </button>
                                            )}

                                            <div className="rytpanellinksec">
                                                {this.state.selectedDocType && (
                                                    <button
                                                        className="favorites_button"
                                                        onClick={
                                                            this.FavouritesPopUp
                                                        }
                                                        title="Favorites"
                                                    >
                                                        <img
                                                            src={favlistlogo}
                                                            alt="Favorites"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <section className="accordion-content">
                                <article
                                    className={`accordion-item ${
                                        activeTab === 0
                                            ? "accordion-active"
                                            : ""
                                    }`}
                                    data-actab-group="0"
                                    data-actab-id="0"
                                >
                                    <div className="min-width-scroll">
                                        <div className="accordion-item__container  ">
                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div className="demandtitle max-width-0">
                                                    Case Details
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_caseDesc">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        <label className="color-red">
                                                            *
                                                        </label>
                                                        Case Name
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <input
                                                                type="text"
                                                                id="caseName"
                                                                className="textareafield h-none"
                                                                placeholder="Case Name"
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .caseName
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_caseDesc">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        <label className="color-red">
                                                            *
                                                        </label>
                                                        Case Description
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="description"
                                                                className="textareafield"
                                                                placeholder="Briefly describe the case"
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .description
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_caseDesc m-0">
                                                <div
                                                    className={`sglecaseanalysis ${
                                                        this.state.isMobile
                                                            ? ""
                                                            : "inline-block"
                                                    }`}
                                                >
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Parties Involved
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="plaintiff"
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Plaintiff"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .plaintiff
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="defendant"
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Defendant"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .defendant
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div
                                                    className={`sglecaseanalysis ${
                                                        this.state.isMobile
                                                            ? ""
                                                            : "inline-block"
                                                    }`}
                                                >
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Jurisdiction & Case
                                                            Number
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="jurisdiction"
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Name of the Jurisdiction"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .jurisdiction
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="case_number"
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Case Number"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .case_number
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div
                                                    className={`sglecaseanalysis ${
                                                        this.state.isMobile
                                                            ? ""
                                                            : "inline-block"
                                                    }`}
                                                >
                                                    <div className="fulldrapdropsec">
                                                        <h4>
                                                            <label className="color-red">
                                                                *
                                                            </label>
                                                            Date & Location
                                                        </h4>
                                                        <form action="#">
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="date"
                                                                    type="date"
                                                                    name=""
                                                                    // placeholder="Plaintiff"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .date
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />

                                                                {/* {validation_errors.date && (
                                       <p className="error-message">
                                       {validation_errors.date}
                                       </p>
                                       )} */}
                                                            </div>
                                                            <div className="formfullwidth">
                                                                <input
                                                                    id="location"
                                                                    type="text"
                                                                    name=""
                                                                    placeholder="Location"
                                                                    className="inputfield"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Case_Details
                                                                            .location
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleValuesChange
                                                                    }
                                                                    autoComplete="off"
                                                                    required
                                                                />

                                                                {/* {validation_errors.location && (
                                       <p className="error-message">
                                       {validation_errors.location}
                                       </p>
                                       )} */}
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_LegaIssue">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        <label className="color-red">
                                                            *
                                                        </label>
                                                        Legal Issue
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="legal_issue"
                                                                className="textareafield"
                                                                placeholder="Briefly describe the legal issues to investigate"
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .legal_issue
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_LegaIssue">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        <label className="color-red">
                                                            *
                                                        </label>
                                                        Status
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="status"
                                                                className="textareafield"
                                                                placeholder="Briefly describe the current status or stage of the case, including any significant developments or milestones reached."
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .status
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_caseDesc">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Statute of Limitations
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <input
                                                                type="text"
                                                                id="Statute_of_Limitations"
                                                                className="textareafield h-none"
                                                                placeholder="Statute of Limitations"
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .Statute_of_Limitations
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_caseDesc">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        <label className="color-red">
                                                            *
                                                        </label>
                                                        Witnesses
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="witness"
                                                                className="textareafield"
                                                                placeholder="Were there any witnesses?"
                                                                value={
                                                                    this.state
                                                                        .Case_Details
                                                                        .witness
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Nature of Incident</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Nature_of_Incident"
                                                                className="textareafield"
                                                                placeholder="Nature of Incident"
                                                                value={
                                                                    FollowUp_Details.Nature_of_Incident
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Injury</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Injury"
                                                                className="textareafield"
                                                                placeholder="Describe in detail the nature and extent of the physical injuries sustained by the plaintiff"
                                                                value={
                                                                    FollowUp_Details.Injury
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Medical Treatment
                                                        received, prognosis,
                                                        permanent disabilities
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Treatment_Received"
                                                                className="textareafield"
                                                                placeholder="Describe in detail the medical treatment received, prognosis, permanent disabilities"
                                                                value={
                                                                    FollowUp_Details.Treatment_Received
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Medical Expenses</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Medical_Expense"
                                                                className="textareafield"
                                                                placeholder="Medical expenses amount"
                                                                value={
                                                                    FollowUp_Details.Medical_Expense
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Lost Wages</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Lost_Wages"
                                                                placeholder="Amount"
                                                                className="textareafield"
                                                                value={
                                                                    FollowUp_Details.Lost_Wages
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Downtime</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Downtime"
                                                                placeholder="Downtime"
                                                                className="textareafield"
                                                                value={
                                                                    FollowUp_Details.Downtime
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Pain and Suffering</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Pain_Suffering"
                                                                className="textareafield"
                                                                placeholder="Describe the plaintiff's pain and suffering, emotional distress, loss of enjoyment of life, disfigurement, or other non-economic impacts of the injury"
                                                                value={
                                                                    FollowUp_Details.Pain_Suffering
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Behavior of Parties</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Parties_Behavior"
                                                                className="textareafield"
                                                                placeholder="Any information about the behavior or decisions of each party that could have contributed to the accident"
                                                                value={
                                                                    FollowUp_Details.Parties_Behavior
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Laws and Regulations
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Laws_Regulations"
                                                                className="textareafield"
                                                                placeholder="If possible, provide information about the specific laws or regulations that may apply to the behavior of each party"
                                                                value={
                                                                    FollowUp_Details.Laws_Regulations
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            {/* <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Expert
                                                        Testimony:
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Expert_Testimony"
                                                                className="textareafield"
                                                                placeholder="Testimony from medical experts, accident reconstruction experts, vocational experts"
                                                                value={
                                                                    FollowUp_Details.Expert_Testimony
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> */}

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Questions for Analysis
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Analysis_Questions"
                                                                className="textareafield"
                                                                placeholder="Specify questions or areas that you want the analysis to cover. This could include how past cases have dealt with similar injuries, similar circumstances"
                                                                value={
                                                                    FollowUp_Details.Analysis_Questions
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Claims and Counterclaims
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Claims"
                                                                className="textareafield"
                                                                placeholder="What claims are being made by both sides, and any defenses that have been raised"
                                                                value={
                                                                    FollowUp_Details.Claims
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Evidence</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Evidence"
                                                                className="textareafield"
                                                                placeholder="Details about the evidence available, including medical records, accident reports, witness testimonies, photographs, etc"
                                                                value={
                                                                    FollowUp_Details.Evidence
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Laws and Precedents</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Laws_Precedents"
                                                                className="textareafield"
                                                                placeholder="Information about the jurisdiction the case falls under, and any relevant laws and precedents"
                                                                value={
                                                                    FollowUp_Details.Laws_Precedents
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Legal Team</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Legal_Team"
                                                                className="textareafield"
                                                                placeholder="If known, information about the legal team for the opposing side, including their typical strategies, strengths, and weaknesses"
                                                                value={
                                                                    FollowUp_Details.Legal_Team
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Liability</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Liability"
                                                                className="textareafield"
                                                                placeholder="Information about who is at fault and the degree of fault"
                                                                value={
                                                                    FollowUp_Details.Liability
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Insurance Coverage</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Insurance_Coverage"
                                                                className="textareafield"
                                                                placeholder="If available, details about the defendant's insurance coverage since insurance often determines the maximum payout"
                                                                value={
                                                                    FollowUp_Details.Insurance_Coverage
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Plaintiff's Requirements
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Plaintiff_Req"
                                                                className="textareafield"
                                                                placeholder="What the plaintiff seeks as a settlement, including any non-monetary terms"
                                                                value={
                                                                    FollowUp_Details.Plaintiff_Req
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_fup">
                                                <div className="fulldrapdropsec">
                                                    <h4>Defense's Position</h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Defendent_Position"
                                                                className="textareafield"
                                                                placeholder="The stance of the defense - are they open to settlement? Do they have any conditions?"
                                                                value={
                                                                    FollowUp_Details.Defendent_Position
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="sgledragdropsec_caseDesc">
                                                <div className="fulldrapdropsec">
                                                    <h4>
                                                        Additional Information
                                                    </h4>
                                                    <form action="#">
                                                        <div className="formfullwidth">
                                                            <textarea
                                                                id="Additional_Information"
                                                                className="textareafield"
                                                                placeholder="Additional Information"
                                                                value={
                                                                    this.state
                                                                        .FollowUp_Details
                                                                        .Additional_Information
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleFollowupValuesChange
                                                                }
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                {/*Code removed from here.*/}
                            </section>
                        </div>
                    )}
                </Resizable>
                {this.state.isLoading ? (
                    <LoadingSpinner
                        text={
                            this.state?.loadingText
                                ? this.state?.loadingText
                                : "Analysis in progress..."
                        }
                    />
                ) : (
                    ""
                )}
                {this.state.isFavoriteLoading ? (
                    <LoadingSpinner text={"Favorite is in progress..."} />
                ) : (
                    ""
                )}
                {this.state.isRemoveFavoriteLoading ? (
                    <LoadingSpinner
                        text={"Removing Favorite is in progress..."}
                    />
                ) : (
                    ""
                )}
            </div>
            <Toaster />
        </Fragment>
    );
  }
}
// CaseAnalysisComponent.contextType = WorkspaceContext
const mapStateToProps = (state) => ({
  caseAnalysisState: state.caseAnalysis.caseAnalysisState, // Use the appropriate reducer key
  dashBoardState: state.dashBoard.dashBoardState,
});

const mapDispatchToProps = {
  updateCaseAnalysisState, // This maps your action directly to props
  updateDashBoardState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseAnalysisComponent);
