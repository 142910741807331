import docTypes_ddl from "../../dropDowns/docTypes.json";
export const initialState = {
    caseAnalysisState: {
        selectedDocType: docTypes_ddl.CaseAnalysis?.length>0 ? docTypes_ddl.CaseAnalysis[0].documentID:"",
        selectedLabel: "",
        isBottomPanelOpen: true,
        caseAnalysisResult: "",
        timetaken: "",
        isAnalysed: false,
        selectedFollowup: {},
        loadingText:"",
        empty_case_Details: {
            caseName: "",
            description: "",
            plaintiff: "",
            defendant: "",
            jurisdiction: "",
            case_number: "",
            date: "",
            location: "",
            legal_issue: "",
            status: "",
            witness: "",
            relevant_documents: "",
            Statute_of_Limitations: "",
        },
        empty_FollowUp_Details: {
            Nature_of_Incident: "",
            Injury: "",
            Treatment_Received: "",
            Medical_Expense: "",
            Lost_Wages: "",
            Downtime: "",
            Pain_Suffering: "",
            Parties_Behavior: "",
            Laws_Regulations: "",
            Analysis_Questions: "",
            Liability: "",
            Insurance_Coverage: "",
            Plaintiff_Req: "",
            Defendent_Position: "",
            Claims: "",
            Evidence: "",
            Laws_Precedents: "",
            Legal_Team: "",
            Additional_Information: "",
        },
        Case_Details: {
            caseName: "",
            Statute_of_Limitations: "",
            description: "",
            plaintiff: "",
            defendant: "",
            jurisdiction: "",
            case_number: "",
            date: "",
            location: "",
            legal_issue: "",
            status: "",
            witness: "",
            relevant_documents: "",
        },
        validation_errors: {
            caseName: "Case Name",
            description: "Description",
            plaintiff: "Plaintiff",
            defendant: "Defendant",
            jurisdiction: "Jurisdiction",
            case_number: "Case Number",
            date: "Date",
            location: "Location",
            legal_issue: "Legal Issue",
            status: "Status",
            witness: "Witness",
            relevant_documents: "Relevant Documents",
            Nature_of_Incident: "Nature of Incident",
            Injury: "Injury",
            Treatment_Received: "Medical Treatment",
            Medical_Expense: "Medical Expenses",
            Lost_Wages: "Lost Wages",
            Downtime: "Downtime",
            Pain_Suffering: "Pain Suffering",
            Parties_Behavior: "Parties Behavior",
            Laws_Regulations: "Laws Regulations",
            Expert_Testimony: "Expert Testimony",
            Analysis_Questions: "Questions for Analysis",
            Liability: "Liability",
            Insurance_Coverage: "Insurance Coverage",
            Plaintiff_Req: "Plaintiff's Requirements",
            Defendent_Position: "Defense's Position",
            Precedents: "Precedents",
            Claims: "Claims and Counterclaims",
            Evidence: "Evidence",
            Laws_Precedents: "Laws and Precedents",
            Parties: "Parties",
            Legal_Team: "Legal Team",
            Evidentiary: "Evidentiary",
            Legal_Precedents: "Legal Precedents",
            Opposing_Party: "Opposing Party",
            Plaintiff_Position: "Plaintiff's Position",
            Statute_of_Limitations: "Statute of Limitations",
        },
        FollowUp_Details: {
            Nature_of_Incident: "",
            Injury: "",
            Treatment_Received: "",
            Medical_Expense: "",
            Lost_Wages: "",
            Downtime: "",
            Pain_Suffering: "",
            Parties_Behavior: "",
            Laws_Regulations: "",
            Expert_Testimony: "",
            Analysis_Questions: "",
            Liability: "",
            Insurance_Coverage: "",
            Plaintiff_Req: "",
            Defendent_Position: "",
            Precedents: "",
            Claims: "",
            Evidence: "",
            Laws_Precedents: "",
            Parties: "",
            Legal_Team: "",
            Evidentiary: "",
            Legal_Precedents: "",
            Opposing_Party: "",
            Plaintiff_Position: "",
            Additional_Information: "",
        },
        FollowUpAnalysis: [],
        prevFollowUpAnalysis: [],
        activeTab: 0,
        isLoading: false,
        engine_type: 2,
        isFavoriteLoading: false,
        isRemoveFavoriteLoading: false,
        isFavorite: false,
        isFavoritePopUp: false,
        FavoritesData: [],
        openCreateWorkSpacePopUp: false,
        selectedCase: "",
        workspace_name: "",
        workspace_id: 0,
        workspace_item_id: 0,
        disableAddToWorkspace: false,
        showWorkspaceButton:false, 
         selectedWorkSpaces: [],
        showAllWorkspaces: false,
        workspace_created_by:0,
        ws_shared_users:[],
        fromDashBoard:false
    },
};

export const caseAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'UPDATE_CASEANALYSIS_STATE':
        // console.log("Redux payload",action.payload.data)
        return { ...state, caseAnalysisState: action.payload.data };
        // Add other cases for different components' states
    case "RESET_CASEANALYSIS_STATE":
            return initialState;
    default:
        return state;
    }
};
