    import moment from "moment";
    import getInstance from "../Components/SupportiveComponents/AxiosRequest";
    export function convertToLocalTime(dateString) {
        let currentdateString = new Date(dateString).toLocaleString("en-us", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        const localDate = new Date(currentdateString);
        const displayDate = moment(
            [
                localDate.getFullYear(),
                localDate.getMonth(),
                localDate.getDate(),
                localDate.getHours(),
                localDate.getMinutes(),
                localDate.getSeconds(),
            ],
            false
        ).fromNow(true);
        // console.log("display Date: " + displayDate);
        return displayDate;
    }

    export function makefileNameShort(fileName) {
        let maxLength = 30;
        if (fileName.length <= 30) {
            return fileName;
        }
        const startLength = Math.floor((maxLength - 3) / 2);
        const endLength = maxLength - startLength;

        const truncatedText = `${fileName.substring(
            0,
            startLength
        )}...${fileName.substring(fileName.length - endLength)}`;

        return truncatedText;
    }

    // A debounce function that takes a function and a delay as parameters
    export function debounce(func, delay) {
        // A timer variable to track the delay period
        let timer;
        // Return a function that takes arguments
        return function (...args) {
            // Clear the previous timer if any
            clearTimeout(timer);
            // Set a new timer that will execute the function after the delay period
            timer = setTimeout(() => {
                // Apply the function with arguments
                func.apply(this, args);
            }, delay);
        };
    }

    export function usernamesearchHandler(query) {
        // Make an API call with search query
        if (query?.trim()?.length <= 0) {
            console.log(query);
            return;
        }
        const customAxios = getInstance();
        customAxios
            .get("/user/" + query, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                timeout: 300000,
            })
            .then((response) => {
                console.log(response?.data?.length, "test data", response.data);

                return response?.data?.length <= 0;
            })
            .catch((error) => {
                console.log("Error occurred ", error);
                return false;
            });
        //getUser(query);
    }

    // A debounced version of the search handler with 500ms delay
export const debouncedSearchHandler = debounce(usernamesearchHandler, 500);
    
export const gmtToDateString = (gmtstring) => {

    // Parse the GMT time string to a Date object
    const gmtDate = new Date(gmtstring);

    // Extract components of the date
    const month = gmtDate.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const day = gmtDate.getUTCDate();
    const year = gmtDate.getUTCFullYear();

    // Format the date in MMDDYYYY format
    const formattedDate = `${("0" + month).slice(-2)}/${("0" + day).slice(
        -2
    )}/${year}`;
    return formattedDate;
}